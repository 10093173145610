import './DesktopPersonalityTest.scss';
import Loader from 'components/atoms/Loader/Loader';
import Notification from 'components/atoms/Notification/Notification';
import { appSwitchRoutes } from 'constants/routes';
import { PersonalityTestsAnswers } from 'enums/tests';
import {
  PersonalityItemModel,
  useQCalcGetNextQuestionMutation,
} from 'generated/graphql';
import { IStageTestPath } from 'interfaces/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import TestHeader from 'views/Test/Tests/components/TestHeader/TestHeader';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { useBlockTestSubmit } from '../../helpers/blockTestSubmit';
import DesktopPersonalityTestQuestion from './DesktopPersonalityTestQuestion/DesktopPersonalityTestQuestion';
import DesktopPersonalityTestSliderLegend from './DesktopPersonalityTestSliderLegend/DesktopPersonalityTestSliderLegend';

interface IDesktopPersonalityTestPage {
  assessmentId: number;
  hideTpCallback?: boolean;
  isPracticeTest?: boolean;
  onFinishTest: (hideTpCallback?: boolean) => void;
}

interface IQuestionAnswerPair {
  questionSubId: string;
  optionSubId: string;
}

const NUMBER_OF_QUESTIONS_IN_PAGE = 10;

const DesktopPersonalityTestPage: FC<IDesktopPersonalityTestPage> = ({
  assessmentId,
  hideTpCallback,
  onFinishTest,
}) => {
  const { handleMsgType } = useNotification();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { blockedSubmit, activateBlockingSubmit } = useBlockTestSubmit();

  const { stageCandidateId } = useParams() as IStageTestPath;
  let answerArr: IQuestionAnswerPair[] = [];

  const [candidateAnswers, setCandidateAnswers] = useState<
    IQuestionAnswerPair[]
  >([]);

  const [qCalcGetNextQuestionMutation, qCalcGetNextQuestionResponse] =
    useQCalcGetNextQuestionMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      onCompleted: data => {
        if (data.QCalcGetNextQuestion.isCompleted) {
          onFinishTest(hideTpCallback);
        }
      },
    });

  useEffect(() => {
    qCalcGetNextQuestionMutation({
      variables: {
        assessmentId,
        nQuestions: NUMBER_OF_QUESTIONS_IN_PAGE,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnswer = (
    value: PersonalityTestsAnswers,
    questionSubId: string,
    optionSubId: string
  ) => {
    answerArr.push({
      questionSubId,
      optionSubId,
    });
    setCandidateAnswers(answerArr);
    activateBlockingSubmit();
  };

  const onAnswerChange = (
    value: PersonalityTestsAnswers,
    questionSubId: string,
    optionSubId: string
  ) => {
    const temp = [...candidateAnswers];
    temp[value] = {
      questionSubId,
      optionSubId,
    };
    setCandidateAnswers(temp);
  };

  const handleNextQuestion = (event: React.MouseEvent<HTMLButtonElement>) => {
    qCalcGetNextQuestionMutation({
      variables: {
        assessmentId,
        nQuestions: NUMBER_OF_QUESTIONS_IN_PAGE,
        answers: candidateAnswers,
      },
    });
    event.currentTarget.blur();
  };

  if (qCalcGetNextQuestionResponse.loading) {
    return <Loader isOverlay />;
  }

  if (!qCalcGetNextQuestionResponse.data) {
    return null;
  }

  const qCalcQuestion = qCalcGetNextQuestionResponse.data
    .QCalcGetNextQuestion as PersonalityItemModel;

  if (
    qCalcGetNextQuestionResponse.data?.QCalcGetNextQuestion
      .remainingQuestions === 0
  ) {
    return (
      <div className="calibration__NotificationWrapper">
        <Notification
          notification={{
            icon: 'Warning',
            color: 'Purple',
            message: t('test.instructions.personality.alreadyCompleted'),
          }}
        />
        <div className="notification-action">
          <Button
            onClick={() =>
              navigate(appSwitchRoutes.stage.getUrl(stageCandidateId))
            }
          >
            {'Continue'}
          </Button>
        </div>
      </div>
    );
  }

  const currentPage =
    qCalcQuestion.totalQuestions - qCalcQuestion.remainingQuestions;

  return (
    <div className="DesktopPersonalityTest">
      <TestHeader
        assessmentId={assessmentId}
        progressBarProps={{
          value: currentPage,
          maxValue: qCalcQuestion?.totalQuestions,
        }}
        className="DesktopPersonalityTest__Header"
        pageIndicatorProps={{
          numberOfQuestionsPerPage: NUMBER_OF_QUESTIONS_IN_PAGE,
          remainingQuestions: qCalcQuestion.remainingQuestions,
          totalQuestions: qCalcQuestion?.totalQuestions,
          prefixWord: t('common.page'),
          connectorWord: t('common.of'),
        }}
      />
      <div className="DesktopPersonalityTestPage">
        <div className="DesktopPersonalityTestPage__Content">
          <DesktopPersonalityTestSliderLegend
            options={qCalcQuestion.questions[0].options}
          />
          {qCalcQuestion.questions.map((question, index) => (
            <DesktopPersonalityTestQuestion
              key={question.subId}
              index={index}
              question={question}
              onAnswer={handleAnswer}
              onAnswerChange={onAnswerChange}
              currentPage={currentPage}
            />
          ))}
          <div className="DesktopPersonalityTestPage__Content__Footer">
            <Button
              onClick={handleNextQuestion}
              size="large"
              disabled={blockedSubmit}
            >
              {t('test.submitAndProceed')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopPersonalityTestPage;
