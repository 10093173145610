import { FC } from 'react';
import Icon from 'components/atoms/Icon';
import {
  BarChart,
  TBarChartValue,
  Tooltip,
} from '@spotted-zebra-uk/ui-components';

interface ISkillsSearchOptionIcons {
  isCompanySpecific?: boolean;
  alreadyExist?: boolean;
  proficiency?: TBarChartValue;
}

const SkillsSearchOptionIcons: FC<ISkillsSearchOptionIcons> = ({
  isCompanySpecific,
  alreadyExist,
  proficiency,
}) => {
  return (
    <div className="personal-skills-profile-header__search-option-icons">
      {isCompanySpecific && (
        <Tooltip
          content="Company-specific skill"
          events={['hover']}
          positionStrategy="fixed"
          disableAriaLabel
        >
          <div>
            <Icon icon="tag" />
          </div>
        </Tooltip>
      )}
      {alreadyExist && proficiency && (
        <BarChart
          value={proficiency}
          className="personal-skills-profile-header__search-option-bar-chart"
        />
      )}
    </div>
  );
};

export default SkillsSearchOptionIcons;
