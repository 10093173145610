import { getAccessToken } from 'helpers/auth';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { Loader, useAuthAppRedirect } from '@spotted-zebra-uk/ui-components';

type LocationState = {
  from: Location;
};

export const Authentication = () => {
  const authToken = getAccessToken();

  if (authToken) {
    return <Navigate to="/" replace />;
  }

  return <AuthRedirectHandler />;
};

const AuthRedirectHandler = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const state = (location.state as LocationState) || undefined;

  const authRedirectUrl = state?.from?.pathname
    ? `${state.from.pathname}${state.from.search || ''}`
    : '/';

  const redirectToAuthApp = useAuthAppRedirect({
    authAppBaseUrl: process.env.REACT_APP_AUTH_URL || '',
    pathname: location.pathname,
    search:
      authRedirectUrl?.includes('/inv') && state
        ? state.from.search.replace('?', '')
        : location.search,
    clientType: 'candidate',
  });

  useEffect(() => {
    redirectToAuthApp(i18n.language, authRedirectUrl);
    //eslint-disable-next-line
  }, []);

  return <Loader isPageLoader variant="bubbles" />;
};
