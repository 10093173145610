import './InductiveTestQuestion.scss';
import useContainerSize from 'hooks/containerSize';
import { FC } from 'react';
import {
  ITEMS_IN_ROW,
  MARGIN_SIZE,
  MAX_ITEM_SIZE,
  MOBILE_VIEW_BRAKEPOINT,
} from './InductiveTestQuestion.constants';
import InductiveTestQuestionAnswerPreview from './InductiveTestQuestionAnswerPreview/InductiveTestQuestionAnswerPreview';
import InductiveTestQuestionItem from './InductiveTestQuestionItem/InductiveTestQuestionItem';

interface IInductiveTestQuestion {
  questionImages: string[];
  previewImage: string | undefined;
}

const InductiveTestQuestion: FC<IInductiveTestQuestion> = ({
  questionImages,
  previewImage,
}) => {
  const [size, containerRef] = useContainerSize();

  const itemSize =
    size.width < MOBILE_VIEW_BRAKEPOINT
      ? size.width / ITEMS_IN_ROW - MARGIN_SIZE
      : MAX_ITEM_SIZE;

  return (
    <div
      ref={containerRef}
      className={`InductiveTestQuestion${
        size.width < MOBILE_VIEW_BRAKEPOINT
          ? ' InductiveTestQuestion--Mobile'
          : ''
      }`}
    >
      {questionImages.map((questionImage: string, index: number) => {
        return !questionImage ? (
          <InductiveTestQuestionAnswerPreview
            key={questionImage}
            previewImage={previewImage}
            index={index}
            width={itemSize}
            height={itemSize}
          />
        ) : (
          <InductiveTestQuestionItem
            width={itemSize}
            height={itemSize}
            key={questionImage}
            image={questionImage}
            index={index}
          />
        );
      })}
    </div>
  );
};

export default InductiveTestQuestion;
