import './styles.scss';
import { RssAssessmentType } from 'generated/graphql';
import { IRoleSpecificSkillAssessmentPath } from 'interfaces/routes';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import RoleSpecificSoftSkillsAssessment from './RoleSpecificSoftSkillsAssessment';
import RoleSpecificTechnicalSkillsAssessment from './RoleSpecificTechnicalSkillsAssessment';

const RoleSpecificSkillsAssessment: FC = () => {
  const { rssAssessmentType } = useParams() as IRoleSpecificSkillAssessmentPath;

  return rssAssessmentType === RssAssessmentType.SoftSkill ? (
    <RoleSpecificSoftSkillsAssessment />
  ) : (
    <RoleSpecificTechnicalSkillsAssessment />
  );
};

export default RoleSpecificSkillsAssessment;
