import Container from 'components/molecules/Container/Container';
import {
  useTestCandidateStartSzPracticeTestMutation,
  useTestCandidateStartSzTestMutation,
} from 'generated/graphql';
import { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import DesktopPersonalityTest from './DesktopPersonalityTest/DesktopPersonalityTest';
import MobilePersonalityTest from './MobilePersonalityTest/MobilePersonalityTest';
import { useTranslation } from 'react-i18next';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import { useDisableCopyAndRightClick } from 'hooks/useDisableCopyAndRightClick';

interface IPersonalityTest {
  testCandidateId: number;
  isPracticeTest: boolean;
  onFinishTest: () => void;
}

const PersonalityTest: FC<IPersonalityTest> = ({
  testCandidateId,
  isPracticeTest,
  onFinishTest,
}) => {
  const { handleMsgType } = useNotification();
  const { t } = useTranslation();
  useDisableCopyAndRightClick();

  const [testCandidateStartSzTestMutation, testCandidateStartSzTestResponse] =
    useTestCandidateStartSzTestMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      variables: {
        testCandidateId,
      },
    });

  const [
    testCandidateStartSzPracticeTestMutation,
    testCandidateStartSzPracticeTestMutationResponse,
  ] = useTestCandidateStartSzPracticeTestMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
    variables: {
      id: testCandidateId,
    },
  });

  useEffect(() => {
    if (!isPracticeTest) {
      testCandidateStartSzTestMutation();
    } else {
      testCandidateStartSzPracticeTestMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading =
    testCandidateStartSzTestResponse.loading ||
    testCandidateStartSzPracticeTestMutationResponse.loading;
  const assessmentId = !isPracticeTest
    ? testCandidateStartSzTestResponse.data?.TestCandidateStartSzTest
        ?.assessmentId
    : testCandidateStartSzPracticeTestMutationResponse.data
        ?.TestCandidateStartSzPracticeTest.assessmentId;

  const getContent = () => {
    if (loading) {
      return (
        <Container className="page-loader-wrapper">
          <Loader variant="bubbles" />
        </Container>
      );
    }
    if (assessmentId) {
      return isMobile ? (
        <MobilePersonalityTest
          assessmentId={assessmentId}
          isPracticeTest={isPracticeTest}
          onFinishTest={onFinishTest}
        />
      ) : (
        <DesktopPersonalityTest
          assessmentId={assessmentId}
          onFinishTest={onFinishTest}
        />
      );
    }
    return null;
  };

  return (
    <>
      <HelmetAndPageAnnouncer
        pageTitle={t('test.instructions.personality.name')}
      />

      {getContent()}
    </>
  );
};

export default PersonalityTest;
