import { useSzSkillsMergeFreeTextSearchPaginatedQuery } from 'generated/graphql';
import { useOuterClickAndEscape } from 'hooks/useOnOutsideClick';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mapSearchSkills } from 'views/PersonalSkillsProfile/helpers/helpers';
import { Skill } from '../../types';
import {
  TNotification,
  useNotification,
  Search,
  SearchOption,
  SearchRef,
} from '@spotted-zebra-uk/ui-components';

export type SearchValue = Skill & { alreadyExist: boolean };
export type SearchVariables = { text: string; take?: number; skip?: number };
export const OPTIONS_PER_PAGE = 20;

interface ISkillsSearch {
  onSearchExit?: () => void;
  onChange?: (value: SearchOption) => void;
}

const SkillsSearch = ({ onSearchExit, onChange }: ISkillsSearch) => {
  const { t } = useTranslation();
  const searchWrapperRef = useOuterClickAndEscape<HTMLDivElement>(() => {
    onSearchExit?.();
  });
  const searchRef = useRef<SearchRef>(null);

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  const timeout = useRef<NodeJS.Timeout>();
  const firstLoad = useRef(true);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SearchOption<SearchValue>[]>([]);
  const [searchText, setSearchText] = useState('');

  const [pageNumber, setPageNumber] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);
  const loadMoreTriggered = useRef(false);

  const onInputChange = useCallback((text: string) => setInput(text), []);
  const filterOption = useCallback(() => true, []);
  const onLoadMore = useCallback(() => {
    setLoadMoreDisabled(true);
    setPageNumber(num => num + 1);
    loadMoreTriggered.current = true;
  }, []);

  const { handleMsgType } = useNotification();

  const { data } = useSzSkillsMergeFreeTextSearchPaginatedQuery({
    variables: {
      text: searchText,
      take: OPTIONS_PER_PAGE,
      skip: pageNumber * OPTIONS_PER_PAGE,
    },
    fetchPolicy: 'network-only',
    onError: err =>
      handleMsgType({
        type: TNotification.error,
        message: errorMessage,
      }),
  });

  useEffect(() => {
    setLoading(true);
    setLoadMore(false);
    setPageNumber(0);
    setOptions([]);

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(
      () => {
        setSearchText(input);
        firstLoad.current = false;
      },
      firstLoad.current ? 0 : 500
    );

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [input]);

  useEffect(() => {
    if (data) {
      const newOptions: SearchOption<SearchValue>[] = mapSearchSkills(data);

      if (loadMoreTriggered.current) {
        setOptions(oldOptions => [...oldOptions, ...newOptions]);
        loadMoreTriggered.current = false;
      } else setOptions(newOptions);

      setLoading(false);
      setLoadMoreDisabled(false);

      newOptions.length < OPTIONS_PER_PAGE
        ? setLoadMore(false)
        : setLoadMore(true);
    }
  }, [data]);

  const errorMessage = t('skills.skillSearchError');
  const searchPlaceholder = t('skills.skillSearchPlaceholder');

  return (
    <div
      className="personal-skills-profile-header__search-wrapper"
      ref={searchWrapperRef}
      data-testid="skills-search"
    >
      <Search
        options={options}
        isLoading={loading}
        loadMore={loadMore}
        loadMoreDisabled={loadMoreDisabled}
        placeholder={searchPlaceholder}
        filterOption={filterOption}
        ref={searchRef}
        onInputChange={onInputChange}
        onLoadMore={onLoadMore}
        onChange={onChange}
        ariaDisabled={false}
        ariaLabel={t('skills.searchForSkills')}
        internalSearch
        hasSearchButton={false}
      />
    </div>
  );
};

export default memo(SkillsSearch);
