import clsx from 'clsx';
import { FC } from 'react';
import { BarChart, TBarChartValue } from '@spotted-zebra-uk/ui-components';

interface ISkillsListItem {
  title: string;
  chartValue?: TBarChartValue;
  highlighted: boolean;
  onClick: () => void;
}

export const SkillsListItem: FC<ISkillsListItem> = ({
  title,
  chartValue,
  highlighted,
  onClick,
}) => (
  <div
    className="skills-list-item"
    data-testid="skills-list-item"
    aria-labelledby={`skills-list-item-title-${title}`}
  >
    <div
      onClick={onClick}
      className={clsx('skills-list-item__title', {
        'skills-list-item__title_highlighted': highlighted,
      })}
      data-testid="skills-list-item-title"
      id={`skills-list-item-title-${title}`}
    >
      {title}
    </div>
    {chartValue && (
      <BarChart
        value={chartValue}
        className="skills-list-item__bar-chart"
        dataTestId={`skills-list-item-bar-chart-${title}`}
      />
    )}
  </div>
);
