import './EqualityAndDiversityForm.scss';
import FormBuilderField from 'components/molecules/FormBuilderField/FormBuilderField';
import { getRespondantFormValidationShema } from 'helpers/respondantFormValidation';
import { useForm } from 'hooks/useForm';
import { TFormFieldValue } from 'interfaces/forms/formGQL';
import { TRespondantFormField } from 'interfaces/forms/respondantForm';
import _ from 'lodash';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotted-zebra-uk/ui-components';
import EqualityStatementButton from './EqualityStatementButton/EqualityStatementButton';

interface IEqualityAndDiversityForm {
  fields: TRespondantFormField[];
  isSkipButtonVisible?: boolean;
  onSubmit: (
    values: {
      [key in string]: TFormFieldValue;
    },
    fields: TRespondantFormField[]
  ) => void;
  onSkipButtonClick?: () => void;
  onEqualityStatementButtonClick: () => void;
}

const EqualityAndDiversityForm: FC<IEqualityAndDiversityForm> = ({
  fields,
  isSkipButtonVisible,
  onSubmit,
  onSkipButtonClick,
  onEqualityStatementButtonClick,
}) => {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () =>
      fields.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr.initialValue,
        }),
        {}
      ),
    [fields]
  );

  const validationShema = useMemo(
    () => getRespondantFormValidationShema(fields),
    [fields]
  );

  const [values, errors, handleChange, handleSubmit] = useForm({
    options: {
      initialValues,
      validationShema,
    },
    onSubmit: values => {
      onSubmit(values, fields);
    },
  });

  const handleSkipButtonClick = () => {
    onSkipButtonClick && onSkipButtonClick();
  };

  const formKey = 'edf';
  return (
    <form onSubmit={handleSubmit} className="EqualityAndDiversityForm">
      <div className="EqualityAndDiversityForm__Fields">
        {fields.map(field => (
          <FormBuilderField
            key={field.id}
            id={`${formKey}-${field.id}`}
            name={field.id}
            type={field.type}
            label={field.label}
            options={field.options}
            value={values[field.id]}
            error={errors && errors[field.id]}
            autoComplete="off"
            onChange={handleChange}
          />
        ))}
      </div>
      <div className="EqualityAndDiversityForm__Actions">
        <EqualityStatementButton onClick={onEqualityStatementButtonClick} />
        <Button
          type="submit"
          className="EqualityAndDiversityForm__SubmitButton"
        >
          {_.capitalize(t('common.confirm'))}
        </Button>
        {isSkipButtonVisible && (
          <Button
            variant="secondary"
            onClick={handleSkipButtonClick}
            className="EqualityAndDiversityForm__Actions__SkipButton"
            fullWidth
          >
            {t('common.preferNotToSay')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default EqualityAndDiversityForm;
