import { FC } from 'react';
import { Button } from '@spotted-zebra-uk/ui-components';
import { ReactComponent as BrainIcon } from './ic_brain.svg';
import { ReactComponent as HatIcon } from './ic_hat.svg';
import { ReactComponent as IdeaIcon } from './ic_idea.svg';

interface IOnboardingContent2 {
  getPageCount: (count: number) => void;
}

const OnboardingContent2: FC<IOnboardingContent2> = ({ getPageCount }) => {
  const handleNextPage = () => {
    getPageCount(3);
  };
  return (
    <div>
      <p className="intro">
        What does the Talent Review solution enable you to achieve?
      </p>
      <div className="listGroup">
        <div className="reviewList">
          <div className="iconContainer">
            <BrainIcon className="icon" />
          </div>
          <span>
            To get a clear understanding of the skills and capabilities of an
            individual, team or function
          </span>
        </div>
        <div className="reviewList">
          <div className="iconContainer">
            <HatIcon className="icon" />
          </div>
          <span>
            To get an objective view of the areas that need focus in order to
            improve performance
          </span>
        </div>
        <div className="reviewList">
          <div className="iconContainer">
            <IdeaIcon className="icon" />
          </div>
          <span>
            To develop a talent map that will indicate possible successors to
            key roles
          </span>
        </div>
      </div>
      <div className="btn-container">
        <Button size="large" onClick={handleNextPage}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default OnboardingContent2;
