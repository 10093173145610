import './TestInfoNote.scss';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { Tag, TagColor, TagSize } from '@spotted-zebra-uk/ui-components';

interface ITestInfoNote {
  icon: FC<{ alt: string }>;
  text: string;
  additionalItem?: ReactNode;
  className?: string;
  alt: string;
  dataTestid?: string;
  accessibilityAdjustment?: number | null;
}

const TestInfoNote: FC<ITestInfoNote> = ({
  icon,
  text,
  additionalItem,
  className,
  alt,
  dataTestid,
  accessibilityAdjustment,
}) => {
  const NoteIcon = icon;
  return (
    <div className={clsx('TestInfoNote', className)}>
      <div className="TestInfoNote__Icon">
        <NoteIcon alt={alt} />
      </div>
      <div className="TestInfoNote__TextItem" data-testid={dataTestid}>
        <div
          className="TestInfoNote__Text"
          data-testid={`${dataTestid || 'test'}-text`}
        >
          {text}
          {!!accessibilityAdjustment && (
            <Tag
              size={TagSize.SMALL}
              isDismissible={false}
              isPresentational={true}
              tagColor={TagColor.BLUE}
            >
              {accessibilityAdjustment}
            </Tag>
          )}
        </div>
        {additionalItem}
      </div>
    </div>
  );
};

export default TestInfoNote;
