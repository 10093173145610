import { FieldType } from 'generated/graphql';
import { TRespondantFormField } from 'interfaces/forms/respondantForm';

const requiredFieldMessage = '^This field is required.';

export const getRespondantFormValidationShema = (
  fields: TRespondantFormField[]
) =>
  fields.reduce((acc, curr) => {
    if (curr.isOptional) {
      return acc;
    } else {
      if (curr.type === FieldType.SingleSelectField) {
        return {
          ...acc,
          [curr.id]: {
            presence: {
              allowEmpty: false,
              message: requiredFieldMessage,
            },
          },
        };
      }

      if (curr.type === FieldType.DateField) {
        return {
          ...acc,
          [curr.id]: {
            length: { minimum: 1, message: requiredFieldMessage },
          },
        };
      }

      return {
        ...acc,
        [curr.id]: {
          length: { minimum: 1, message: requiredFieldMessage },
        },
      };
    }
  }, {});
