import { formatDateToText, formatDistanceToNow } from 'helpers/dates';
import i18n from 'helpers/i18n';
import { differenceInHours, isBefore } from 'helpers/time';
import useInterval from 'hooks/useInterval';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, TagColor, Tooltip } from '@spotted-zebra-uk/ui-components';

interface IExpirationNotification {
  endTime: Date;
}

const getExpiryTagColor = (endTime: Date) => {
  const hoursLeft = differenceInHours(endTime, new Date());
  if (hoursLeft < 24) {
    return TagColor.RED;
  }

  return TagColor.BLUE;
};

const ExpirationNotification: FC<IExpirationNotification> = ({ endTime }) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState<string>(
    formatDistanceToNow(endTime, i18n.language)
  );
  const endDateText = t('stage.endDate', {
    val: formatDateToText(endTime, i18n.language),
  });
  const isDeadlineExpired = endTime && isBefore(endTime, new Date());

  useInterval(
    () => {
      const newTimeLeft = formatDistanceToNow(endTime, i18n.language);
      if (newTimeLeft !== timeLeft) {
        setTimeLeft(newTimeLeft);
      }
    },
    60 * 1000,
    true
  );

  return (
    <div
      className="expiration-notification"
      role="alert"
      aria-label={`${t('stage.assessmentDueIn')} ${
        isDeadlineExpired ? t('stage.expiredTime') : timeLeft
      }. ${endDateText}`}
      data-testid="candidate-stage__expiration-notification"
    >
      <span className="expiration-notification__note" aria-hidden="true">
        {t('stage.assessmentDueIn')}
      </span>
      <span aria-hidden="true">
        <Tooltip
          id="expiration-notification-tooltip-trigger"
          content={endDateText}
          place="bottom"
          ariaHiddenTooltip={true}
          dataTestid="candidate-stage__expiration-notification-tooltip"
        >
          <Tag
            isPresentational
            isDismissible={false}
            tagColor={getExpiryTagColor(endTime)}
            containerClass="expiration-notification__tag"
            customId={`expiration-notification-tooltip-trigger`}
            dataTestId="candidate-stage__expiration-notification-time-tag"
          >
            {isDeadlineExpired ? t('stage.expiredTime') : timeLeft}
          </Tag>
        </Tooltip>
      </span>
    </div>
  );
};

export default ExpirationNotification;
