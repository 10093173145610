import './EqualityAndDiversityFormContainerTitle.scss';
import BubblesBackground from 'components/atoms/BubblesBackground/BubblesBackground';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IEqualityAndDiversityFormContainerTitle {}

const EqualityAndDiversityFormContainerTitle: FC<IEqualityAndDiversityFormContainerTitle> =
  () => {
    const { t } = useTranslation();
    return (
      <BubblesBackground
        index={4}
        className="EqualityAndDiversityFormContainerTitle"
      >
        <p className="EqualityAndDiversityFormContainerTitle__Text">
          {t('equalityAndDiversity.helpUsEncourage')}
        </p>
        <p className="EqualityAndDiversityFormContainerTitle__Text">
          {t('equalityAndDiversity.equalityAndDiversity')}
        </p>
      </BubblesBackground>
    );
  };

export default EqualityAndDiversityFormContainerTitle;
