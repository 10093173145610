import './SkillProficiencyModal.scss';
import Icon from 'components/atoms/Icon';
import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import {
  SkillType,
  TechnicalSkillSelfAssessmentProficiencyLevel,
  useResultCreateOnePspSoftSkillMutation,
  useResultCreateOnePspTechnicalSkillMutation,
} from 'generated/graphql';
import {
  barChartValuesToSoftSkillProficiencyLevelMap,
  barChartValuesToTechnicalSkillProficiencyLevelMap,
  BEGINNER,
  EXPERT,
  INTERMEDIATE,
  NO_KNOWLEDGE,
  SKILL_ANSWER_STRINGS,
  technicalSkillProficiencyLevelToBarChartValuesMap,
} from 'helpers/skillsProficiency';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalSize,
  TNotification,
  useNotification,
  Tooltip,
  RadioButtonGroup,
  RadioButtonOption,
  BarChartWithBorder,
} from '@spotted-zebra-uk/ui-components';
import { updateCache } from '../helpers/helpers';
import { Skill } from '../types';

export type SkillModalMode = 'add' | 'edit';

interface ISkillProficiencyModal {
  isOpen: boolean;
  skill: Skill;
  mode?: SkillModalMode;
  onClose: () => void;
}

const SkillProficiencyModal: FC<ISkillProficiencyModal> = ({
  isOpen,
  skill,
  mode = 'edit',
  onClose,
}) => {
  const { t } = useTranslation();
  const [proficiencySelected, setProficiencySelected] = useState<
    TBarChartValue | undefined
  >(skill.proficiency);

  const levelNoKnowledge =
    technicalSkillProficiencyLevelToBarChartValuesMap[
      TechnicalSkillSelfAssessmentProficiencyLevel.NoKnowledge
    ];
  const levelBeginner =
    technicalSkillProficiencyLevelToBarChartValuesMap[
      TechnicalSkillSelfAssessmentProficiencyLevel.Beginner
    ];
  const levelIntermediate =
    technicalSkillProficiencyLevelToBarChartValuesMap[
      TechnicalSkillSelfAssessmentProficiencyLevel.Intermediate
    ];
  const levelExpert =
    technicalSkillProficiencyLevelToBarChartValuesMap[
      TechnicalSkillSelfAssessmentProficiencyLevel.Expert
    ];

  const { handleMsgType } = useNotification();
  const onError = () => {
    handleMsgType({
      type: TNotification.error,
      title: `Oops, some error occurred during the skill ${
        mode === 'add' ? 'adding' : 'update'
      }!`,
    });
  };
  const refetch =
    mode === 'add'
      ? {
          refetchQueries: ['SkillsProfileOpenOne'],
          awaitRefetchQueries: true,
        }
      : {};

  const [updateSoftSkill] = useResultCreateOnePspSoftSkillMutation({
    variables: {
      id: skill.id,
      score: {
        proficiency:
          barChartValuesToSoftSkillProficiencyLevelMap[
            proficiencySelected as TBarChartValue
          ],
      },
    },
    ...refetch,
    update: (cache, { data }) => {
      if (data && mode === 'edit') updateCache(cache, data, skill.type);
    },
    onCompleted: onClose,
    onError: onError,
  });

  const [updateTechnicalSkill] = useResultCreateOnePspTechnicalSkillMutation({
    variables: {
      id: skill.id,
      score: {
        technicalProficiency:
          barChartValuesToTechnicalSkillProficiencyLevelMap[
            proficiencySelected as TBarChartValue
          ],
      },
    },
    ...refetch,
    update: (cache, { data }) => {
      if (data && mode === 'edit') updateCache(cache, data, skill.type);
    },
    onCompleted: onClose,
    onError: onError,
  });

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = () => {
    setSubmitting(true);

    if (skill.type === SkillType.SoftSkill) updateSoftSkill();
    if (skill.type === SkillType.TechnicalSkill) updateTechnicalSkill();
  };

  const proficiencyOptions: RadioButtonOption[] = useMemo(() => {
    const options = [
      {
        id: `skill-proficiency-option-beginner`,
        label: t(SKILL_ANSWER_STRINGS[BEGINNER]),
        value: String(levelBeginner),
        image: <BarChartWithBorder value={BEGINNER} />,
      },
      {
        id: `skill-proficiency-option-intermediate`,
        label: t(SKILL_ANSWER_STRINGS[INTERMEDIATE]),
        value: String(levelIntermediate),
        image: <BarChartWithBorder value={INTERMEDIATE} />,
      },
      {
        id: `skill-proficiency-option-expert`,
        label: t(SKILL_ANSWER_STRINGS[EXPERT]),
        value: String(levelExpert),
        image: <BarChartWithBorder value={EXPERT} />,
      },
    ];

    if (skill.type === SkillType.TechnicalSkill) {
      return [
        {
          id: `skill-proficiency-option-no-knowledge`,
          label: t(SKILL_ANSWER_STRINGS[NO_KNOWLEDGE]),
          value: String(levelNoKnowledge),
          image: <BarChartWithBorder value={NO_KNOWLEDGE} />,
        },
        ...options,
      ];
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skill.type]);

  const handleProficiencyChange = (newValue: string) => {
    setProficiencySelected(newValue as unknown as TBarChartValue);
  };

  return (
    //@ts-ignore
    <Modal
      isOpen={isOpen}
      className="skill-proficiency-modal"
      onClose={onClose}
      showCloseIcon={false}
      modalSize={
        skill.type === SkillType.TechnicalSkill
          ? ModalSize.MEDIUM
          : ModalSize.SMALL
      }
    >
      <h3
        className="skill-proficiency-modal__title-container"
        data-testid="skill-proficiency-modal-title"
      >
        {skill.isCompanySpecific && (
          <Tooltip
            content="Company-specific skill"
            events={['hover', 'click']}
            place="bottom"
            positionStrategy="fixed"
            className="skill-button__icon-tooltip"
          >
            <div>
              <Icon
                icon="tag"
                className="skill-proficiency-modal__title-icon"
                data-testid="company-specific-icon"
              />
            </div>
          </Tooltip>
        )}
        <div className="skill-proficiency-modal__title"> {skill.name}</div>
      </h3>
      {skill.description && (
        <p
          className="skill-proficiency-modal__desc"
          data-testid="skill-proficiency-modal-description"
        >
          {skill.description}
        </p>
      )}
      <div className="skill-proficiency-modal__skills-levels">
        {!skill.proficiency && (
          <div
            className="skill-proficiency-modal__skills-level-not-set"
            data-testid="skill-level-not-set"
          >
            {t('common.skillLevelNotSet')}
          </div>
        )}
        <RadioButtonGroup
          id="skill-proficiency-modal"
          name="skillProficiency"
          value={String(proficiencySelected)}
          options={proficiencyOptions}
          ariaLabel={t('skills.selectYourSkillLevelFor', {
            skillName: skill.name,
          })}
          layout="horizontal"
          radioButtonClassName="skill-proficiency-modal__option"
          onChange={handleProficiencyChange}
        />
      </div>
      <div className="skill-proficiency-modal__buttons">
        <Button
          variant="secondary"
          size="medium"
          data-testid="cancel-button"
          onClick={onClose}
        >
          {t('common.cancel')}
        </Button>
        <Button
          size="medium"
          disabled={
            skill.proficiency === proficiencySelected ||
            !proficiencySelected ||
            submitting
          }
          data-testid="skill-proficiency-modal-submit-btn"
          onClick={onSubmit}
        >
          {t('common.submit')}
        </Button>
      </div>
    </Modal>
  );
};

export default SkillProficiencyModal;
