import { appSwitchRoutes } from 'constants/routes';
import { AdjustmentQuestionnaireResponse } from 'generated/graphql';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  CheckboxGroup,
  CheckboxGroupItem,
  Link,
} from '@spotted-zebra-uk/ui-components';
import {
  adjustmentsResponsesType,
  shouldRedirectToIntro,
  steps,
} from '../AccessibilityAdjustments.helpers';
import styles from './AccessibilityNeedsList.module.scss';

const AccessibilityNeedsList = ({
  stageCandidateId,
  completedStep,
  onSubmit,
}: {
  stageCandidateId: number;
  completedStep: steps;
  onSubmit: (values: adjustmentsResponsesType, step: steps) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [adjustments, setAdjustments] = useState<adjustmentsResponsesType>([]);
  const [error, setError] = useState('');

  const accessibilityNeedsCheckboxOptions = useMemo(() => {
    return [
      {
        label: t('accessibilityAdjustments.needsList.assistiveTechnology'),
        value: AdjustmentQuestionnaireResponse.AssistiveTechnology,
      },
      {
        label: t('accessibilityAdjustments.needsList.physicalChallenges'),
        value: AdjustmentQuestionnaireResponse.PhysicalChallenges,
      },
      {
        label: t(
          'accessibilityAdjustments.needsList.difficultyProcessingInformation'
        ),
        value: AdjustmentQuestionnaireResponse.DifficultyProcessingInformation,
      },
      {
        label: t(
          'accessibilityAdjustments.needsList.difficultyMaintainingFocus'
        ),
        value: AdjustmentQuestionnaireResponse.DifficultyMaintainingFocus,
      },
      {
        label: t(
          'accessibilityAdjustments.needsList.difficultyManagingAnxiety'
        ),
        value: AdjustmentQuestionnaireResponse.DifficultyManagingAnxiety,
      },
      {
        label: t(
          'accessibilityAdjustments.needsList.difficultyManagingFatigue'
        ),
        value: AdjustmentQuestionnaireResponse.DifficultyManagingFatigue,
      },
      {
        label: t('accessibilityAdjustments.needsList.auditoryProcessing'),
        value: AdjustmentQuestionnaireResponse.AuditoryProcessing,
      },
    ];
  }, [t]);

  useEffect(() => {
    // Redirect to flow intro page on page refresh
    shouldRedirectToIntro(completedStep) &&
      navigate(
        appSwitchRoutes.accessibilityAdjustments.getUrl(
          stageCandidateId,
          'intro'
        )
      );
  }, [completedStep, stageCandidateId, navigate]);

  const handleChange = (value: string[]) => {
    if (error) {
      setError('');
    }
    setAdjustments(value as adjustmentsResponsesType);
  };

  const handleGoBack = () => {
    navigate(
      appSwitchRoutes.accessibilityAdjustments.getUrl(stageCandidateId, 'intro')
    );
  };

  const handleSubmit = () => {
    if (!adjustments.length) {
      setError(t(`accessibilityAdjustments.needsList.error`));
      return;
    }
    onSubmit(adjustments, 'options');
    navigate(
      appSwitchRoutes.accessibilityAdjustments.getUrl(
        stageCandidateId,
        'summary'
      )
    );
  };

  return (
    <section
      className={styles.container}
      data-testid="accessibility-needs-list"
    >
      <h3 className={styles.title}>
        {t(`accessibilityAdjustments.needsList.title`)}
      </h3>
      <div className={styles.checkboxContainer}>
        <CheckboxGroup
          id="accessibility-needs-checkbox-group"
          value={adjustments}
          onChange={handleChange}
          hasError={Boolean(error)}
          error={error}
        >
          {accessibilityNeedsCheckboxOptions.map(({ label, value }) => {
            return (
              <CheckboxGroupItem
                key={value}
                data-testid={`accessibility-needs__need-option-${value}`}
                id={value}
                label={label}
                name={value}
              />
            );
          })}
        </CheckboxGroup>
      </div>
      <p className={styles.disclaimer}>
        {t('accessibilityAdjustments.needsList.disclaimer')}
      </p>
      <Link
        to="mailto:candidate@spottedzebra.co.uk"
        target="_blank"
        className="need-more-time-modal__link"
        aria-label={t('common.sendEmailTo', {
          email: 'candidate@spottedzebra.co.uk.',
        })}
      >
        candidate@spottedzebra.co.uk
      </Link>
      <div className={styles.actions}>
        <Button
          variant="secondary"
          onClick={handleGoBack}
          aria-label={t('accessibilityAdjustments.goBack')}
          data-testid="accessibility-needs-list__go-back-button"
        >
          {t('accessibilityAdjustments.goBack')}
        </Button>
        <Button
          onClick={handleSubmit}
          className={styles.cta}
          type="submit"
          aria-label={t('common.continue')}
          data-testid="accessibility-needs-list__submit-button"
        >
          {t('common.continue')}
        </Button>
      </div>
    </section>
  );
};

export default AccessibilityNeedsList;
