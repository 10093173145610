import Loader from 'components/atoms/Loader/Loader';
import { appSwitchRoutes } from 'constants/routes';
import { StageCardStatus } from 'enums/stageCardStatus.enum';
import {
  GetStageActionsQuery,
  ProjectModuleType,
  StageActionAvailabilityStatus,
  StageCandidateStatus,
  useGetStageCardsQuery,
  User,
} from 'generated/graphql';
import { getFullNameFromLanguage } from 'helpers/locales';
import { parseStageStatus } from 'helpers/parseStageStatus';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import FinishedPracticeStageContent from './FinishedPracticeStageContent/FinishedPracticeStageContent';
import FinishedStageWithoutReportContent from './FinishedStageWithoutReportContent/FinishedStageWithoutReportContent';
import FinishedTalentReviewStage from './FinishedStageWithoutReportContent/FinishedTalentReviewStage';
import NextTalentReviewTestContent from './NextTestContent/NextTalentReviewTestContent';
import NextTestContent from './NextTestContent/NextTestContent';
import ReportIsReadyContent from './ReportIsReadyContent/ReportIsReadyContent';

interface IThankYouContent {
  stageCandidateId: number;
  testCandidateId?: string;
  isPractice: boolean;
  stageActionsQuery: GetStageActionsQuery;
}

const ThankYouContent: FC<IThankYouContent> = ({
  stageCandidateId,
  testCandidateId,
  isPractice,
  stageActionsQuery,
}) => {
  const navigate = useNavigate();
  const stageUrl = appSwitchRoutes.stages.getUrl();
  const { handleMsgType } = useNotification();

  const getStageCardsQueryResponse = useGetStageCardsQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(stageUrl);
    },
  });

  const stageActionsData = stageActionsQuery.GetStageActions;

  if (getStageCardsQueryResponse.loading) {
    return <Loader isOverlay />;
  }

  if (getStageCardsQueryResponse.data && stageActionsData) {
    const stageCard = getStageCardsQueryResponse.data.GetStageCards.find(
      stageCard => stageCard.stageCandidate.id === stageCandidateId
    );
    const stageActions = stageActionsData.actions;
    const stageCandidate = stageActionsData.stageCandidate;

    // Calculates number of not completed tests
    const numberOfNotCompletedTests: number =
      stageActions?.filter(
        action =>
          action.availability.status !== StageActionAvailabilityStatus.Completed
      ).length || 0;

    const areAllTestsCompleted =
      stageActions?.filter(
        action =>
          (action.__typename === 'StageActionPersonalityTestModel' ||
            action.__typename === 'StageActionTalentReviewTestModel' ||
            action.__typename === 'StageActionCognitiveTestModel') &&
          action.availability.status !== StageActionAvailabilityStatus.Completed
      ).length === 0;

    if (stageCard) {
      const stageCardStatus = parseStageStatus(
        stageCard?.stage.status,
        stageCard?.stageCandidate.status
      );

      if (isPractice) {
        return (
          <FinishedPracticeStageContent
            stageCandidatId={stageCandidateId}
            testCandidateId={Number(testCandidateId)}
          />
        );
      }

      if (
        areAllTestsCompleted &&
        stageCard.candidateReportId &&
        stageCard.stage.renderCandidateReport
      ) {
        return (
          <ReportIsReadyContent
            stageCandidateId={stageCandidateId}
            candidateReportId={stageCard.candidateReportId}
          />
        );
      }

      if (
        stageCardStatus === StageCardStatus.COMPLETED ||
        areAllTestsCompleted
      ) {
        if (
          !stageCard.stage.renderCandidateReport ||
          !stageCard.candidateReportId
        ) {
          if (stageCard.project.moduleType === ProjectModuleType.TalentReview) {
            if (
              stageCandidate.status === StageCandidateStatus.ManagerCompleted ||
              stageCandidate.status === StageCandidateStatus.SignedOff
            ) {
              return (
                <FinishedTalentReviewStage
                  projectModuleType={stageCard.project.moduleType}
                  companyLogoUrl={stageCard.company.logoUrl as string}
                  doneFor={getFullNameFromLanguage(
                    stageCard.doneForUser as User
                  )}
                  stageCandidateId={stageCandidateId}
                />
              );
            } else if (stageCard.inspectable) {
              return (
                <NextTalentReviewTestContent
                  projectModuleType={stageCard.project.moduleType}
                  stageCandidateId={stageCandidateId}
                />
              );
            }
          }

          return (
            <FinishedStageWithoutReportContent
              stageCandidateId={stageCandidateId}
              companyName={stageCard.company.name}
            />
          );
        }
      }

      return (
        <NextTestContent
          stageCandidateId={stageCandidateId}
          projectModuleType={stageCard.project.moduleType}
          numberOfNotCompletedTests={numberOfNotCompletedTests}
          renderCandidateReport={stageCard.stage.renderCandidateReport}
        />
      );
    }
  }

  return null;
};

export default ThankYouContent;
