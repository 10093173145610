import './EqualityStatementButton.scss';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotted-zebra-uk/ui-components';

interface IEqualityStatementButton {
  onClick?: () => void;
}

const EqualityStatementButton: FC<IEqualityStatementButton> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="EqualityStatementButton">
      <Button onClick={onClick} variant="text" fullWidth>
        {_.capitalize(t('equalityAndDiversity.equalityDiversityMonitoring'))}
        <span className="EqualityStatementButton__TextQuestionMark">?</span>
      </Button>
    </div>
  );
};

export default EqualityStatementButton;
