import Loader from 'components/atoms/Loader/Loader';
import Notification from 'components/atoms/Notification/Notification';
import { candidateEmail } from 'constants/candidateEmail';
import { useGetStageCardsQuery } from 'generated/graphql';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import StageCardsList from './StageCardsList/StageCardsList';

const StageCardsListContainer: FC = () => {
  const { t } = useTranslation();
  const { handleMsgType } = useNotification();

  const getStageCardsQueryResponse = useGetStageCardsQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
    fetchPolicy: 'network-only',
  });

  if (getStageCardsQueryResponse.loading) {
    return <Loader isOverlay />;
  }

  if (getStageCardsQueryResponse.error) {
    return (
      <div className="StageCardsListContainer__NotificationWrapper">
        <Notification
          notification={{
            icon: 'Warning',
            color: 'Purple',
            message: t(
              'stages.unfortunatelyThereHasBeenProblemProcessingOneOrMoreOfYourTests',
              {
                candidateEmail,
              }
            ),
          }}
        />
      </div>
    );
  }

  if (getStageCardsQueryResponse.data) {
    return (
      <StageCardsList
        stageCards={getStageCardsQueryResponse.data.GetStageCards}
      />
    );
  }

  return null;
};

export default StageCardsListContainer;
