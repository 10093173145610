import './CandidateInformationDesktop.scss';
import React, { FC } from 'react';
import { Modal, ModalSize } from '@spotted-zebra-uk/ui-components';
import CandidateInformationContainer from '../../CandidateInformationContainer/CandidateInformationContainer';

interface ICandidateInformationDesktop {
  onCloseButtonClick: () => void;
  stageCandidateId: number;
  onCandidateInformationProvided: () => void;
  onCandidateInformationFormPreviouslyCompleted: () => void;
}

const CandidateInformationDesktop: FC<ICandidateInformationDesktop> = ({
  onCloseButtonClick,
  stageCandidateId,
  onCandidateInformationProvided,
  onCandidateInformationFormPreviouslyCompleted,
}) => {
  return (
    //@ts-ignore
    <Modal
      className="CandidateInformationDesktop"
      onClose={onCloseButtonClick}
      isOpen
      modalSize={ModalSize.SMALL}
    >
      <CandidateInformationContainer
        stageCandidateId={stageCandidateId}
        onCandidateInformationProvided={onCandidateInformationProvided}
        onCandidateInformationFormPreviouslyCompleted={
          onCandidateInformationFormPreviouslyCompleted
        }
      />
    </Modal>
  );
};

export default CandidateInformationDesktop;
