import './TalentReviewForm.scss';
import Navbar from 'components/organisms/Navbar/Navbar';
import StageNavbar from 'components/organisms/StageNavbar';
import { ITalentReviewFormPath } from 'interfaces/routes';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TalentReviewFormView from './components/TalentReviewForm/TalentReviewForm';

interface ITalentReviewForm {}

const TalentReviewForm: FC<ITalentReviewForm> = () => {
  const location = useLocation();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const navigate = useNavigate();
  const { ownerId, stageCandidateId, stageId } =
    useParams() as ITalentReviewFormPath;
  useEffect(() => {
    let qs = new URLSearchParams(location.search);
    if (qs.get('readonly')?.toLowerCase() === 'true') {
      setIsReadOnly(true);
    }
  }, [location.search]);
  const onCloseHandler = () => {
    navigate(`/stages/${stageCandidateId}`);
  };
  return (
    <React.Fragment>
      <Navbar />
      <StageNavbar isForm displayLogout={false} />
      <TalentReviewFormView
        ownerId={parseInt(ownerId)}
        stageCandidateId={parseInt(stageCandidateId)}
        stageId={parseInt(stageId)}
        isReadOnly={isReadOnly}
        onCloseHandler={onCloseHandler}
      />
    </React.Fragment>
  );
};

export default TalentReviewForm;
