import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavLink } from '@spotted-zebra-uk/ui-components';

interface IBackArrow {
  to: string;
  isMainScreen?: boolean;
  label?: string;
}

const BackArrow: FC<IBackArrow> = ({ to, isMainScreen, label, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleBackClicked = () => {
    let redirectFrom = localStorage.getItem('redirectFrom');
    let onCompleted = localStorage.getItem('onCompleted');
    if (redirectFrom && onCompleted && location.pathname) {
      // localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
      // localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
      localStorage.removeItem('redirectFrom');
      localStorage.removeItem('onCompleted');
    }
  };

  return (
    <NavLink
      to={to}
      onClick={e => {
        if (isMainScreen) {
          handleBackClicked();
        } else {
          e.preventDefault();
          navigate(to);
        }
      }}
      direction="backward"
      tabIndex={1}
      {...rest}
    >
      {label}
    </NavLink>
  );
};

export default BackArrow;
