import CandidateInformationMobile from '../CandidateInformationMobile/CandidateInformationMobile';
import { closeModal } from 'graphql/modals';
import { ModalTypes } from 'enums/modalTypes';
import { useNavigate, useParams } from 'react-router-dom';

export const CandidateInformationPageWrapper = () => {
  const { stageCandidateId } = useParams();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    closeModal(ModalTypes.CANDIDATE_INFORMATION);
    navigate('/');
  };

  return (
    <CandidateInformationMobile
      onCloseModal={handleCloseModal}
      stageCandidateId={Number(stageCandidateId)}
    />
  );
};
