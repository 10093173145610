import './NeedExtraTimeModal.scss';
import Icon from 'components/atoms/Icon';
import { useTranslation } from 'react-i18next';
import { Button, Link, Modal } from '@spotted-zebra-uk/ui-components';

interface INeedExtraTimeModal {
  isOpen: boolean;
  onClose: () => void;
}

const NeedExtraTimeModal = ({ isOpen, onClose }: INeedExtraTimeModal) => {
  const { t } = useTranslation();

  return (
   //@ts-ignore
    <Modal className="need-more-time-modal" isOpen={isOpen} onClose={onClose}>
      <h3 className="need-more-time-modal__title">
        <Icon icon="info_i" className="need-more-time-modal__icon" />
        {t('common.extraTime')}
      </h3>
      <p>
        No problem. You can contact your recruiter or the
        <br />
        assessment team at Spotted Zebra by sending an email
        <br /> to:{' '}
        <Link
          to="mailto:candidate@spottedzebra.co.uk"
          target="_blank"
          className="need-more-time-modal__link"
          aria-label={t('common.sendEmailTo', {
            email: 'candidate@spottedzebra.co.uk.',
          })}
        >
          candidate@spottedzebra.co.uk
        </Link>
      </p>
      <p>
        Please provide the reason for the extra time, and what
        <br /> percentage increase you typically receive.
      </p>
      <Button
        className="need-more-time-modal__button"
        size="medium"
        onClick={onClose}
        aria-label={t('dialogue.closeDialogue')}
        data-testid="OK"
      >
        OK
      </Button>
    </Modal>
  );
};

export default NeedExtraTimeModal;
