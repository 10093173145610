import { getAccessToken } from 'helpers/auth';
import { FunctionComponent } from 'react';
import { Navigate, Outlet, RouteProps, useLocation } from 'react-router-dom';

interface IPrivateRoute extends RouteProps {
  redirectUrl?: string;
  allowed?: boolean;
}

export interface IPrivateRouteRedirectLocationState {
  from: Location;
}

const PrivateRoute: FunctionComponent<IPrivateRoute> = ({
  redirectUrl = '/auth',
  allowed = true,
}) => {
  const accessToken = getAccessToken();
  const location = useLocation();

  if (!accessToken || !allowed) {
    return <Navigate to={redirectUrl} state={{ from: location }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
