import './FormField.scss';
import { FunctionComponent, ReactNode } from 'react';

interface IFormField {
  error?: string | undefined;
  fieldName: string;
  label?: string;
  isLabelVisible?: boolean;
  isLabelErrorVisible?: boolean;
  children: ReactNode;
}

const FormField: FunctionComponent<IFormField> = ({
  children,
  error,
  label,
  fieldName,
  isLabelVisible = true,
  isLabelErrorVisible = true,
}) => {
  return (
    <div className={`FormField FormField--${fieldName}`}>
      <label className="FormField__Label" htmlFor={fieldName}>
        {label && isLabelVisible ? (
          <div
            className={`FormField__Label__Text${
              error ? ' FormField__Label__Text--HasError' : ''
            }`}
          >
            {label}
          </div>
        ) : (
          <></>
        )}

        {children}
        {isLabelErrorVisible && <div className="FormField__Error">{error}</div>}
      </label>
    </div>
  );
};

export default FormField;
