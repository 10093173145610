import { IconType } from 'components/atoms/Icon';
import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import Container from 'components/molecules/Container/Container';
import { HeadingWithIcon } from 'components/molecules/HeadingWithIcon';
import NavbarWithBackLink from 'components/molecules/NavbarWithBackLink/NavbarWithBackLink';
import { stageSwitchRoutes } from 'constants/routes';
import { IRoleSpecificSkillAssessmentPath } from 'interfaces/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TSkillWithAnswer } from '../types';
import Assessment from './Assessment';

interface IRoleSpecificSoftSkillsAssessmentMobile {
  headingTitle: string;
  headingIcon: IconType;
  headingDescription: string;
  loading: boolean;
  initialSkillsWithAnswers: TSkillWithAnswer[];
  gradeBands: {
    proficiency: TBarChartValue;
  }[];
  skillName: string;
  onSubmit: (skillsWithAnswers: TSkillWithAnswer[]) => void;
  hasMarkAllButton?: boolean;
}

const RoleSpecificSoftSkillsAssessmentMobile: FC<
  IRoleSpecificSoftSkillsAssessmentMobile
> = ({
  headingDescription,
  headingIcon,
  headingTitle,
  initialSkillsWithAnswers,
  gradeBands,
  loading,
  hasMarkAllButton,
  skillName,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { stageCandidateId, rssAssessmentCandidateId, rssAssessmentType } =
    useParams() as IRoleSpecificSkillAssessmentPath;
  const parsedStageCandidateId = Number(stageCandidateId);
  const parsedRssAssessmentCandidateId = Number(rssAssessmentCandidateId);
  return (
    <Container className="role-specific-soft-skills-assessment-mobile">
      <NavbarWithBackLink
        to={stageSwitchRoutes.skillsSelfAssessmentIntro.getUrl(
          parsedStageCandidateId,
          parsedRssAssessmentCandidateId,
          rssAssessmentType as string
        )}
        ariaLabel={t('assessment.returnToAllAssessmentActions')}
        label={t('assessment.actions')}
      />
      <HeadingWithIcon
        title={headingTitle}
        icon={headingIcon}
        description={headingDescription}
        alt=""
      />
      <Assessment
        initialSkillsWithAnswers={initialSkillsWithAnswers}
        gradeBands={gradeBands}
        loading={loading}
        skillName={skillName}
        onSubmit={onSubmit}
        hasMarkAllButton={hasMarkAllButton}
      />
    </Container>
  );
};
export default RoleSpecificSoftSkillsAssessmentMobile;
