import { useEffect, useRef } from 'react';

const useInterval = (
  callback: () => void,
  delay: number = 1000,
  isActive: boolean
) => {
  const savedCallback = useRef<() => void | null>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (typeof savedCallback?.current !== 'undefined' && isActive) {
        savedCallback?.current();
      }
    }

    const id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay, isActive]);
};

export default useInterval;
