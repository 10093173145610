import React from 'react';
import { LiveMessage } from 'react-aria-live';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

type HelmetAndPageAnnouncerProps = {
  pageTitle: string;
};

export const HelmetAndPageAnnouncer = ({
  pageTitle,
}: HelmetAndPageAnnouncerProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <LiveMessage
        message={t('common.navigatedToPage', { pageName: pageTitle })}
        aria-live="assertive"
        clearOnUnmount
      />
    </>
  );
};
