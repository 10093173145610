import SpottedZebraLogo from 'assets/logos/SpottedZebraLogo.svg';
import { FC } from 'react';

const logoNameToSrcMap = {
  sz_logo: SpottedZebraLogo,
};

export type TLogoName = keyof typeof logoNameToSrcMap;

interface ILogo {
  name: TLogoName;
  alt: string;
  className?: string;
}

const Logo: FC<ILogo> = ({ name, alt, className }) => {
  const src = logoNameToSrcMap[name];

  return <img src={src} alt={alt} className={className} />;
};

export default Logo;
