import { ThankYouTemplate } from 'components/organisms/ThankYouTemplate/ThankYouTemplate';
import { ThankYouPageContentType } from 'components/organisms/ThankYouTemplate/ThankYouTemplate.enums';
import { stageSwitchRoutes } from 'constants/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface INextActionContent {
  numberOfNotCompletedActions: number;
  stageCandidateId: number;
}

const NextActionContent: FC<INextActionContent> = ({
  numberOfNotCompletedActions,
  stageCandidateId,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNextActionClick = () => {
    navigate(stageSwitchRoutes.stage.getUrl(stageCandidateId));
  };

  const mainText = t(
    'stage.testCallback.goodProgressYouHaveNumberOfTestRemaining',
    { testCount: numberOfNotCompletedActions }
  );

  return (
    <ThankYouTemplate
      mainText={mainText}
      onClick={handleNextActionClick}
      contentType={ThankYouPageContentType.NEXT_TEST_CONTENT}
    />
  );
};

export default NextActionContent;
