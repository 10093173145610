import './PersonalityTestAnswerPreview.scss';
import { PersonalityTestsAnswers } from 'enums/tests';
import { PersonalityOptionModel } from 'generated/graphql';
import { FC } from 'react';
import ColoredText from '../../../components/ColoredText/ColoredText';

interface IPersonalityTestAnswerPreview {
  answerValue: PersonalityTestsAnswers;
  options: PersonalityOptionModel[];
}

const answerColors: {
  [key in PersonalityTestsAnswers]: 'Purple' | 'Gray' | 'Blue';
} = {
  [PersonalityTestsAnswers.STRONGLY_DISAGREE]: 'Purple',
  [PersonalityTestsAnswers.DISAGREE]: 'Purple',
  [PersonalityTestsAnswers.SLIGHTELY_DISAGREE]: 'Purple',
  [PersonalityTestsAnswers.NEUTRAL]: 'Gray',
  [PersonalityTestsAnswers.SLIGHTELY_AGREE]: 'Blue',
  [PersonalityTestsAnswers.AGREE]: 'Blue',
  [PersonalityTestsAnswers.STRONGLY_AGREE]: 'Blue',
};

const PersonalityTestAnswerPreview: FC<IPersonalityTestAnswerPreview> = ({
  answerValue,
  options,
}) => {
  const answerStrings: { [key in PersonalityTestsAnswers]: string } = {
    [PersonalityTestsAnswers.STRONGLY_DISAGREE]: options[0]?.text,
    [PersonalityTestsAnswers.DISAGREE]: options[1]?.text,
    [PersonalityTestsAnswers.SLIGHTELY_DISAGREE]: options[2]?.text,
    [PersonalityTestsAnswers.NEUTRAL]: options[3]?.text,
    [PersonalityTestsAnswers.SLIGHTELY_AGREE]: options[4]?.text,
    [PersonalityTestsAnswers.AGREE]: options[5]?.text,
    [PersonalityTestsAnswers.STRONGLY_AGREE]: options[6]?.text,
  };

  const text = answerStrings[answerValue];
  const color = answerColors[answerValue];

  return (
    <ColoredText
      className="PersonalityTestAnswerPreview"
      color={color}
      text={text}
    />
  );
};

export default PersonalityTestAnswerPreview;
