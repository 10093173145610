import Icon from 'components/atoms/Icon';
import { FormCandidateStatus } from 'generated/graphql';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionCard } from '@spotted-zebra-uk/ui-components';

interface IFormCard {
  testStatus?: FormCandidateStatus;
  stageId: number;
  companyId: number;
  stageCandidateId: number;
}

const FormCard: FC<IFormCard> = ({
  testStatus,
  stageId,
  companyId,
  stageCandidateId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const url = `${stageId}/form/TRForm/${companyId}/${stageCandidateId}`;
  const handleFormTest = () => {
    if (testStatus === FormCandidateStatus.NotStarted) {
      navigate(url);
    }
  };

  // TODO: Fix localization [EN-1930]

  return (
    //@ts-ignore
    <ActionCard
      ariaLabel={t('common.successionRiskForm')}
      onClick={handleFormTest}
      completed={testStatus === FormCandidateStatus.Completed}
      label="Succession & Risk"
      icon={<Icon icon="microphone" aria-hidden focusable="false" />}
      aria-disabled={testStatus === FormCandidateStatus.Completed}
    />
  );
};

export default FormCard;
