import Loader from 'components/atoms/Loader/Loader';
import { ThankYouPageContentType } from 'components/organisms/ThankYouTemplate/ThankYouTemplate.enums';
import { FormType } from 'enums/form';
import { ModalTypes } from 'enums/modalTypes';
import { openModal } from 'graphql/modals';
import { useGetStageCompanyRespondantFormEffect } from 'hooks/useRespondantForm';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ThankYouContentDefaultTemplate from '../ThankYouDefaultTemplate/ThankYouDefaultTemplate';

export interface IReportIsReadyContent {
  candidateReportId: number;
  stageCandidateId: number;
}

const ReportIsReadyContent: FC<IReportIsReadyContent> = ({
  candidateReportId,
  stageCandidateId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [
    // eslint-disable-next-line no-unused-vars
    _getStageCompanyRespondantFormResult,
    isLoading,
  ] = useGetStageCompanyRespondantFormEffect(
    stageCandidateId,
    FormType.CD_FORM,
    getRespondantFormResult => {
      if (
        getRespondantFormResult?.data?.respondantForm &&
        getRespondantFormResult.data.respondantForm.respondantFieldWithAnswers
          .length &&
        !getRespondantFormResult.data.respondantForm.isComplete
      ) {
        openModal({
          modalType: ModalTypes.EQUALITY_AND_DIVERSITY_MODAL,
          modalProps: {
            stageCandidateId,
          },
        });
      }
    }
  );
  const handleClick = () => {
    navigate(`/candidate-report/${candidateReportId}`);
  };

  if (isLoading) {
    return <Loader isOverlay />;
  }

  const mainText = t('stage.testCallback.reportIsReady');
  const buttonText = t('stage.testCallback.openReport');

  return (
    <ThankYouContentDefaultTemplate
      onClick={handleClick}
      mainText={mainText}
      buttonText={buttonText}
      contentType={ThankYouPageContentType.REPORT_IS_READY_CONTENT}
    />
  );
};

export default ReportIsReadyContent;
