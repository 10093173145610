import {
  GetStageActionsQuery,
  StageCandidateActionType,
} from 'generated/graphql';
import {
  adjustmentsResponsesType,
  getTimeAmountAdded,
  steps,
} from './AccessibilityAdjustments.helpers';
import AccessibilityDetails from './AccessibilityDetails/AccessibilityDetails';
import AccessibilityIntro from './AccessibilityIntro/AccessibilityIntro';
import AccessibilityNeedsList from './AccessibilityNeedsList/AccessibilityNeedsList';
import AccessibilitySummary from './AccessibilitySummary/AccessibilitySummary';

const AccessibilityAdjustmentStep = ({
  step,
  stageData,
  stageCandidateId,
  responses,
  handleProgress,
  handleSubmitResponses,
  tests,
  loading,
  completedStep,
  handleResponses,
}: {
  step?: string;
  stageData?: GetStageActionsQuery;
  stageCandidateId: number;
  responses: adjustmentsResponsesType;
  handleProgress: (step: steps) => void;
  handleResponses: (values: adjustmentsResponsesType, step: steps) => void;
  handleSubmitResponses: (redirectUrl: string, step?: steps) => void;
  tests: StageCandidateActionType[];
  loading: boolean;
  completedStep: steps;
}) => {
  if (step === 'options') {
    return (
      <AccessibilityNeedsList
        stageCandidateId={stageCandidateId}
        completedStep={completedStep}
        onSubmit={handleResponses}
      />
    );
  }
  if (step === 'summary') {
    return (
      <AccessibilitySummary
        stageCandidateId={stageCandidateId}
        responses={responses}
        tests={tests}
        completedStep={completedStep}
        onSubmit={handleSubmitResponses}
        loading={loading}
      />
    );
  }
  if (step === 'details') {
    return (
      <AccessibilityDetails
        tests={tests}
        extraTime={getTimeAmountAdded(responses)}
        stageCandidateId={stageCandidateId}
        handleProgress={handleProgress}
        stageData={stageData}
      />
    );
  }
  // Default to intro page
  return (
    <AccessibilityIntro
      companyName={stageData?.GetStageActions?.company?.name || ''}
      stageCandidateId={stageCandidateId}
      onContinue={handleProgress}
      onCancelFlow={handleSubmitResponses}
      loading={loading}
    />
  );
};

export default AccessibilityAdjustmentStep;
