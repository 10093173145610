import Error from 'enums/error';
import { getAccessToken, logoutCleanup } from 'helpers/auth';
import { TModal } from 'interfaces/modal';
import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
  makeVar,
  ReactiveVar,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
// generated by Fragment Matcher plugin
import generatedIntrospection from '../introspection-result.json';
import { requestTokenRefresh } from './refreshToken';

enum Theme {
  GREEN = 'Theme--Green',
  BLUE = 'Theme--Blue',
}

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_BASE_URL as string,
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getAccessToken();
  operation.setContext({
    headers: {
      Authorization: token && `Bearer ${token}`,
      'X-Client': 'candidate',
    },
  });

  return forward(operation);
});

export const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    // TODO: Add proper error handling.
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
        );
        if (
          err.extensions?.response?.statusCode === 401 &&
          operation.operationName !== 'RequestTokenRefresh'
        ) {
          return fromPromise(requestTokenRefresh(client))
            .filter(value => Boolean(value))
            .flatMap(() => {
              return forward(operation);
            });
        }

        if (
          operation.operationName === 'User' &&
          err.extensions?.exception?.response?.code ===
            Error.FIND_MANY_NOT_FOUND
        ) {
          logoutCleanup();
        }
      }
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }

    return undefined;
  }
);

export const modalsVar: ReactiveVar<TModal[]> = makeVar<TModal[]>([]);
// Initialize theme var and sets initial theme to be "GEEN" theme.
export const themeVar: ReactiveVar<Theme> = makeVar<Theme>(Theme.GREEN);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        modals: {
          read() {
            return modalsVar();
          },
        },
        theme: {
          read() {
            return themeVar();
          },
        },
        SkillsProfileOpenOne: {
          merge: false,
        },
      },
    },
  },
  possibleTypes: generatedIntrospection.possibleTypes,
});

const client = new ApolloClient({
  cache,
  link: from([errorLink, authLink, httpLink]),
});

export default client;
