import clsx from 'clsx';
import useMatchMedia from 'hooks/useMatchMedia';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DESKTOP_BREAKPOINT } from '../../constants';
import capitalize from 'lodash/capitalize';
import { BarChart, Tooltip, Switch } from '@spotted-zebra-uk/ui-components';

interface INoKnowledgeToggle {
  value: boolean;
  disabled?: boolean;
  onToggle: (value: boolean) => void;
}

const NoKnowledgeToggle: FC<INoKnowledgeToggle> = ({
  value,
  disabled,
  onToggle,
}) => {
  const { t } = useTranslation();
  const isDesktop = useMatchMedia(`(min-width: ${DESKTOP_BREAKPOINT})`);
  const tooltipText = t('skills.includeSkillsNoKnowledge');

  return (
    <div
      className={clsx(
        'personal-skills-profile-header__no-knowledge-toggle',
        disabled &&
          'personal-skills-profile-header__no-knowledge-toggle--disabled'
      )}
      data-testid="no-knowledge-toggle"
    >
      <Tooltip
        content={tooltipText}
        place="top"
        positionStrategy="fixed"
        disabled={disabled || !isDesktop}
        disableAriaLabel
      >
        <Switch
          id="show-no-knowledge-skills"
          label={
            isDesktop
              ? capitalize(t('common.show'))
              : t('skills.showSkillsNoKnowledge')
          }
          value={value}
          disabled={disabled}
          onToggle={onToggle}
          ariaLabel={tooltipText}
        />
      </Tooltip>
      <BarChart
        value={1}
        className="personal-skills-profile-header__no-knowledge-toggle-chart"
      />
    </div>
  );
};

export default NoKnowledgeToggle;
