import { themeVar } from 'graphql/client';
import { FC, ReactNode, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

export const GET_THEME_QUERY = gql`
  query GetTheme {
    theme @client
  }
`;

export const setTheme = (newTheme: Theme): void => {
  themeVar(newTheme);
};

export enum Theme {
  GREEN = 'Theme--Green',
  BLUE = 'Theme--Blue',
}

interface IThemeQuery {
  theme: Theme;
}

interface IThemeProvider {
  children?: ReactNode;
}

const ThemeProvider: FC<IThemeProvider> = ({ children }) => {
  const currentTheme = useQuery<IThemeQuery>(GET_THEME_QUERY);

  useEffect(() => {
    if (currentTheme.data) {
      document.body.classList.add(currentTheme.data.theme);
    }

    return () => {
      if (currentTheme.data) {
        document.body.classList.remove(currentTheme.data.theme);
      }
    };
  }, [currentTheme]);
  return null;
};

export default ThemeProvider;
