import { useEffect, useState } from 'react';

const useMatchMedia = (match: string) => {
  const [matchMedia, setMatchMedia] = useState<boolean>(
    window.matchMedia(match).matches
  );

  useEffect(() => {
    const handler = () => setMatchMedia(window.matchMedia(match).matches);

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [match]);

  return matchMedia;
};

export default useMatchMedia;
