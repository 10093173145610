import './FocusOnRouteChange.scss';
import { ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

let prevPathName: string | null = null;

// Creates a wrapper around the Routes which enables focus reset when route is changed.
// This solves issues with screen reader focus not being at the top of the page when route is changed.
export const FocusOnRouteChange = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Don't refocus if only the query params/hash have changed
    if (pathname !== prevPathName) {
      ref.current?.focus();

      // Prevent jank if focusing causes page to scroll
      window.scrollTo(0, 0);

      prevPathName = pathname;
    }
  }, [pathname]);

  return (
    <div ref={ref} tabIndex={-1} className="focus-on-route-change">
      {children}
    </div>
  );
};
