export const GREEN_100 = '#00D3AD';
export const GREEN_80 = '#33DCBE';
export const GREEN_10 = '#E5FBF7';
export const GREEN_5 = '#F2FDFB';
export const BLUE_100 = '#10B7FF';
export const BLUE_80 = '#40C6FF';
export const BLUE_10 = '#E7F8FF';
export const BLUE_5 = '#F3FCFF';
export const PURPLE_100 = '#B75BFF';
export const PURPLE_80 = '#C57CFF';
export const PURPLE_10 = '#F8EFFF';
export const PURPLE_5 = '#FCF7FF';
export const RED_100 = '#E54D42';
export const RED_80 = '#EA7168';
export const RED_10 = '#FCEDEC';
export const RED_5 = '#FEF6F6';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const GRAY_54 = '#757575';
export const GRAY_38 = '#9E9E9E';
export const GRAY_16 = '#D6D6D6';
export const GRAY_4 = '#F5F5F5';
export const YELLOW = '#FFA32D';
export const NAVY = '#006AFF';
