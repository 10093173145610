import './EqualityStatement.scss';
import { ReactComponent as BackArrow } from 'assets/icons/LeftArrow.svg';
import BubblesBackground from 'components/atoms/BubblesBackground/BubblesBackground';
import Container from 'components/molecules/Container/Container';
import { isMobile } from 'helpers/deviceDetect';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotted-zebra-uk/ui-components';

interface IEqualityStatement {
  onGotItButtonClick: () => void;
  onBackArrowClick: () => void;
}

const EqualityStatement: FC<IEqualityStatement> = ({
  onGotItButtonClick,
  onBackArrowClick,
}) => {
  const { t } = useTranslation();
  const renderEqualityStatementContent = () => {
    return (
      <>
        <div className="EqualityStatement__Nav">
          <Button onClick={onBackArrowClick} variant="text">
            <BackArrow />
          </Button>
        </div>
        <div className="EqualityStatement__Content">
          <div className="EqualityStatement__Content__Main">
            <BubblesBackground index={4} className="EqualityStatement__Title">
              <p className="EqualityStatement__Title__Text">
                {_.capitalize(
                  t('equalityAndDiversity.equalityDiversityMonitoring')
                )}
              </p>
            </BubblesBackground>
            <p className="EqualityStatement__Content__Main__Text">
              {t('equalityAndDiversity.capturingThisInformationHelps')}
            </p>
            <p className="EqualityStatement__Content__Main__Text">
              {t('equalityAndDiversity.weNeedYourHelp')}
            </p>
          </div>
          <Button
            onClick={onGotItButtonClick}
            className="EqualityStatement__GotItButton"
          >
            {_.capitalize(t('common.gotIt'))}
          </Button>
        </div>
      </>
    );
  };

  if (isMobile()) {
    return (
      <Container className="EqualityStatement EqualityStatement--Mobile">
        {renderEqualityStatementContent()}
      </Container>
    );
  }

  return (
    <div className="EqualityStatement EqualityStatement--Desktop">
      {renderEqualityStatementContent()}
    </div>
  );
};

export default EqualityStatement;
