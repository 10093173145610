import './SingleLegendSlider.scss';
import Icon from 'components/atoms/Icon';
import { FC } from 'react';
import { Slider, SliderProps } from '@spotted-zebra-uk/ui-components';

interface ISingleLegendSlider extends SliderProps {
  legendItemFromString: string;
  legendItemToString: string;
}

const SingleLegendSlider: FC<ISingleLegendSlider> = ({
  legendItemFromString,
  legendItemToString,
  ...sliderProps
}) => {
  return (
    <div className="SingleLegendSlider">
      <Slider {...sliderProps} />
      <div className="SingleLegendSlider__Legend">
        <p className="SingleLegendSlider__Legend__Item">
          {legendItemFromString}
        </p>
        <Icon icon="two_side_arrow" />
        <p className="SingleLegendSlider__Legend__Item">{legendItemToString}</p>
      </div>
    </div>
  );
};

export default SingleLegendSlider;
