import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  filterNoKnowledgeSkills,
  sortSkillsByUpdate,
} from '../../helpers/helpers';
import { ILayout } from '../../types';
import { SkillCard } from '@spotted-zebra-uk/ui-components';
import capitalize from 'lodash/capitalize';

const LastUpdatedLayout: FC<ILayout> = ({
  skills,
  showNoKnowledgeSkills,
  onSkillClick,
}) => {
  const filteredSkills = filterNoKnowledgeSkills(
    skills,
    !!showNoKnowledgeSkills
  );

  const sortedSkills = sortSkillsByUpdate(filteredSkills);

  const { t } = useTranslation();

  return (
    <>
      {!!filteredSkills.length && (
        <div
          className="personal-skills-profile__skills-group"
          data-testid="last-updated-skills"
        >
          <h3 className="personal-skills-profile__skills-group-title">
            {t('common.oldestNewest')}
          </h3>
          <div className="personal-skills-profile__skills-items">
            {sortedSkills.map(skill => (
              <SkillCard
                testId="skill-button"
                id={skill.id}
                key={`${skill.name}-${skill.id}`}
                label={capitalize(skill.name)}
                companyOwned={skill.isCompanySpecific}
                proficiency={skill.proficiency}
                onClick={() => onSkillClick && onSkillClick(skill)}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LastUpdatedLayout;
