import './DesktopVerbalTest.scss';
import Loader from 'components/atoms/Loader/Loader';
import Container from 'components/molecules/Container/Container';
import ProgressBar from 'components/molecules/ProgressBar/ProgressBar';
import {
  useQCalcGetNextQuestionMutation,
  VerbalTestletModel,
} from 'generated/graphql';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SliderTestQuestion from 'views/Test/Tests/components/SliderTestQuestion/SliderTestQuestion';
import TestHeader from 'views/Test/Tests/components/TestHeader/TestHeader';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { useBlockTestSubmit } from '../../helpers/blockTestSubmit';
import { QUESTIONS_IN_TESTLET } from '../VerbalTest.constants';

interface IDesktopVerbalTest {
  assessmentId: number;
  onFinishTest: () => void;
}

interface IVerbalTestAnswer {
  optionSubId: string | null;
  questionSubId: string;
}

const sliderMarkStyle: React.CSSProperties = {
  width: 80,
};

const DesktopVerbalTest: FC<IDesktopVerbalTest> = ({
  assessmentId,
  onFinishTest,
}) => {
  const { t } = useTranslation();
  const { handleMsgType } = useNotification();
  const questionWrapperRef = useRef<HTMLDivElement | null>(null);
  const [verbalQuestion, setVerbalQuestion] = useState<VerbalTestletModel>();
  const [verbalAnswer, setVerbalAnswer] = useState<IVerbalTestAnswer[]>([]);
  const { blockedSubmit, activateBlockingSubmit } = useBlockTestSubmit();

  const [qCalcGetNextQuestionMutation, qCalcGetNextQuestionResponse] =
    useQCalcGetNextQuestionMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      onCompleted: data => {
        if (data?.QCalcGetNextQuestion.isCompleted) {
          onFinishTest();
        }
        if (data.QCalcGetNextQuestion) {
          setVerbalQuestion(data.QCalcGetNextQuestion as VerbalTestletModel);
          setDefaultAnswer(data.QCalcGetNextQuestion as VerbalTestletModel);
        }
      },
    });

  const setDefaultAnswer = (data: VerbalTestletModel) => {
    const isDefaultAnswer = data.questions.map(item => ({
      optionSubId: item.options[2].subId,
      questionSubId: item.subId,
    }));
    setVerbalAnswer(isDefaultAnswer);
  };

  useEffect(() => {
    qCalcGetNextQuestionMutation({
      variables: {
        assessmentId,
        nQuestions: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAnswer = (
    questionIndex: number,
    optionSubId: string,
    questionSubId: string
  ) => {
    const newAnswer = {
      optionSubId,
      questionSubId,
    };
    const temp = [...verbalAnswer];
    temp[questionIndex] = newAnswer;
    setVerbalAnswer(temp);
  };

  const handleAnswer = (event: React.MouseEvent<HTMLButtonElement>) => {
    qCalcGetNextQuestionMutation({
      variables: {
        answers: verbalAnswer,
        assessmentId,
        nQuestions: 1,
      },
    });
    event.currentTarget.blur();
    activateBlockingSubmit();
  };

  const handleTimeout = () => {
    qCalcGetNextQuestionMutation({
      variables: {
        answers: verbalAnswer,
        assessmentId,
        nQuestions: 1,
      },
    });
    activateBlockingSubmit();
  };

  if (qCalcGetNextQuestionResponse.loading) {
    return <Loader isOverlay />;
  }

  if (!verbalQuestion) {
    return null;
  }

  const { totalQuestions, remainingQuestions } = verbalQuestion;
  const currentTestlet =
    totalQuestions / QUESTIONS_IN_TESTLET -
    remainingQuestions / QUESTIONS_IN_TESTLET +
    1;
  const testletsInTest = totalQuestions / QUESTIONS_IN_TESTLET;

  return (
    <Container className="DesktopVerbalTest">
      <ProgressBar value={currentTestlet} maxValue={testletsInTest} />
      <TestHeader
        assessmentId={assessmentId}
        progressBarProps={{
          value: currentTestlet,
          maxValue: testletsInTest,
        }}
        hasTimer
        timerInterval={verbalQuestion.remainingTime}
        timeLimit={verbalQuestion.timeLimit}
        onTimeout={handleTimeout}
        hasProgressBar={false}
        pageIndicatorProps={{
          currentPage: currentTestlet,
          existingPages: testletsInTest,
          connectorWord: 'of',
          prefixWord: 'Page',
        }}
      />
      <div className="DesktopVerbalTestContainer">
        <div className="VerbalTextContainer">
          <p className="VerbalTextContainer__TestInstruction">
            {t('test.readAndAnswer')}
          </p>
          <p className="VerbalTextContainer__TesletText">
            {verbalQuestion.text}
          </p>
        </div>
        <div
          ref={questionWrapperRef}
          className="DesktopVerbalTestContainer__QuestionWrapper"
        >
          {verbalQuestion.questions.map((item, index) => (
            <SliderTestQuestion
              key={item.subId}
              questions={item}
              questionIndex={index}
              onAnswer={getAnswer}
              startPoint={2}
              questionNumberingFormat={`${index + 1}.`}
              sliderMarkStyle={sliderMarkStyle}
            />
          ))}
          <div className="DesktopVerbalTestContainer__QuestionWrapper__Actions">
            <Button
              onClick={handleAnswer}
              className="DesktopVerbalTestContainer__QuestionWrapper__Actions__Button"
              disabled={blockedSubmit}
            >
              {t('test.submitAndProceed')}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DesktopVerbalTest;
