import './styles.scss';
import clsx from 'clsx';
import Icon from 'components/atoms/Icon';
import { FC } from 'react';

interface INextArrow {
  onClick?: () => void;
  currentSlide?: number;
  slideCount?: number;
}

const NextArrow: FC<INextArrow> = ({ onClick, slideCount, currentSlide }) => {
  const isFaded = slideCount && (slideCount as number) - 1 === currentSlide;
  return (
    <div
      onClick={onClick}
      className={clsx('sz-carousel-arrow-icon', 'sz-carousel-arrow-icon_next', {
        'sz-carousel-arrow-icon_faded': isFaded,
      })}
    >
      <Icon icon="chevron" className="sz-carousel-arrow-icon__icon" />
    </div>
  );
};

export default NextArrow;
