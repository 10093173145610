import './EqualityAndDiversityFormContainer.scss';
import Loader from 'components/atoms/Loader/Loader';
import { FormType } from 'enums/form';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import useRespondantForm from 'hooks/useRespondantForm';
import { Maybe } from 'interfaces/maybe';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotted-zebra-uk/ui-components';
import EqualityAndDiversityForm from './EqualityAndDiversityForm/EqualityAndDiversityForm';
import EqualityAndDiversityFormContainerLogos from './EqualityAndDiversityFormContainerLogos/EqualityAndDiversityFormContainerLogos';
import EqualityAndDiversityFormContainerTitle from './EqualityAndDiversityFormContainerTitle/EqualityAndDiversityFormContainerTitle';

interface IEqualityAndDiversityFormContainer {
  stageProjectCompanyId: number;
  projectId: number;
  stageProjectCompanyLogoUrl?: Maybe<string>;
  onEqualityStatementButtonClick: () => void;
}

const EqualityAndDiversityFormContainer: FC<
  IEqualityAndDiversityFormContainer
> = ({
  stageProjectCompanyId,
  stageProjectCompanyLogoUrl,
  projectId,
  onEqualityStatementButtonClick,
}) => {
  const { t } = useTranslation();
  const closeEqualityAndDiversityModal = () => {
    closeModal(ModalTypes.EQUALITY_AND_DIVERSITY_MODAL);
  };

  const [
    getEqualityAndDiversityFormQueryResponse,
    formFields,
    handleSaveCandidateInformation,
    handleSkip,
  ] = useRespondantForm({
    associatedId: stageProjectCompanyId,
    formOwnerId: stageProjectCompanyId,
    formType: FormType.CD_FORM,
    projectId: projectId,
    onGetRespondantFormPreviouslyCompleted: closeEqualityAndDiversityModal,
    onSaveRespondantFormCompleted: closeEqualityAndDiversityModal,
  });

  const isSkipButtonVisible = useMemo(() => {
    if (getEqualityAndDiversityFormQueryResponse.data?.respondantForm) {
      return !getEqualityAndDiversityFormQueryResponse.data.respondantForm.respondantFieldWithAnswers.some(
        fieldWithAnswer => !fieldWithAnswer.isOptional
      );
    }

    return false;
  }, [getEqualityAndDiversityFormQueryResponse.data]);

  if (getEqualityAndDiversityFormQueryResponse.loading) {
    return (
      <div className="EqualityAndDiversityFormContainer__LoaderWrapper">
        <Loader />
      </div>
    );
  }

  if (getEqualityAndDiversityFormQueryResponse.data && formFields) {
    return (
      <div className="EqualityAndDiversityFormContainer">
        <EqualityAndDiversityFormContainerLogos
          logoUrl={stageProjectCompanyLogoUrl}
        />
        <div className="EqualityAndDiversityFormContainer__TitleWrapper">
          <EqualityAndDiversityFormContainerTitle />
          <p className="EqualityAndDiversityFormContainer__Subtitle">
            {t(
              'equalityAndDiversity.pleaseHelpUsEncourageEqualityAndDiversity'
            )}{' '}
            <Button
              className="EqualityAndDiversityFormContainer__Subtitle__Button"
              onClick={onEqualityStatementButtonClick}
              variant="text"
            >
              {t('equalityAndDiversity.optional')}
            </Button>{' '}
            {t('equalityAndDiversity.andWillNotFormAnyPartOfYourAssessment')}{' '}
            <Button
              className="EqualityAndDiversityFormContainer__Subtitle__Button"
              onClick={onEqualityStatementButtonClick}
              variant="text"
            >
              {t('common.here')}
            </Button>
            .
          </p>
        </div>
        <EqualityAndDiversityForm
          onSubmit={handleSaveCandidateInformation}
          fields={formFields}
          isSkipButtonVisible={isSkipButtonVisible}
          onSkipButtonClick={handleSkip}
          onEqualityStatementButtonClick={onEqualityStatementButtonClick}
        />
      </div>
    );
  }

  return null;
};

export default EqualityAndDiversityFormContainer;
