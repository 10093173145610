import { BLUE_100, GRAY_16 } from 'constants/colors';
import { FC } from 'react';

export type TBarChartValue = 1 | 2 | 3 | 4;

interface ISimpleBarChart {
  value: TBarChartValue;
  className?: string;
  dataTestId?: string;
}

const SimpleBarChart: FC<ISimpleBarChart> = ({
  value,
  className,
  dataTestId,
}) => {
  const thirdBarFill = value === 4 ? BLUE_100 : GRAY_16;
  const secondBarFill = [4, 3].includes(value) ? BLUE_100 : GRAY_16;
  const firstBarFill = value !== 1 ? BLUE_100 : GRAY_16;

  return (
    <div data-testid={dataTestId || 'simple-bar-chart'}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <rect x="4" y="26" width="8" height="12" rx="1" fill={firstBarFill} />
        <rect x="16" y="14" width="8" height="24" rx="1" fill={secondBarFill} />
        <rect x="28" y="2" width="8" height="36" rx="1" fill={thirdBarFill} />
      </svg>
    </div>
  );
};

export default SimpleBarChart;
