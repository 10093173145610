import './styles.scss';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import StageNavbar from 'components/organisms/StageNavbar';
import { IStageActionFormPath } from 'interfaces/routes';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import StageActionFromView from './StageActionFormView';

const StageActionForm = () => {
  const { t } = useTranslation();
  const { ownerId, stageCandidateId, formType } =
    useParams() as IStageActionFormPath;

  return (
    <Fragment>
      <HelmetAndPageAnnouncer pageTitle={t('assessment.generalQuestions')} />
      <StageNavbar isForm displayLogout={false} />
      <StageActionFromView
        ownerId={parseInt(ownerId)}
        stageCandidateId={parseInt(stageCandidateId)}
        formType={formType}
      />
    </Fragment>
  );
};

export default StageActionForm;
