import { useTimeout } from 'hooks/useTimeout';
import { useState } from 'react';

const BLOCKING_TIME = 1000; // one second

export const useBlockTestSubmit = () => {
  const [blockSubmitButton, setBlockSubmitButton] = useState(true);
  const [blockSubmitTimeOutDelay, setBlockSubmitTimeOutDelay] = useState(BLOCKING_TIME);

  useTimeout(() => {
    setBlockSubmitButton(false);
    setBlockSubmitTimeOutDelay(0);
  }, blockSubmitTimeOutDelay);

  const activateBlockingSubmit = () => {
    setBlockSubmitButton(true);
    setBlockSubmitTimeOutDelay(BLOCKING_TIME);
  };

  return { blockedSubmit: blockSubmitButton, activateBlockingSubmit };
};