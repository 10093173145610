import {
  GetStageActionsDocument,
  GetStageActionsQuery,
  GetStageActionsQueryVariables,
  useStageCandidatePledgeAnticheatingMutation,
} from 'generated/graphql';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './AnticheatingContract.module.scss';

const AnticheatingContract = ({
  stageCandidateId,
  onPledgeSubmit,
}: {
  stageCandidateId: number;
  onPledgeSubmit: () => void;
}) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [hasCheckboxError, setHasCheckboxError] = useState(false);
  const [updateStatus] = useStageCandidatePledgeAnticheatingMutation({
    variables: {
      id: stageCandidateId,
    },
    onCompleted: () => {
      onPledgeSubmit();
    },
    update(cache, { data }) {
      if (data?.StageCandidatePledgeAnticheating) {
        const existingStageActions = cache.readQuery<
          GetStageActionsQuery,
          GetStageActionsQueryVariables
        >({
          query: GetStageActionsDocument,
          variables: { stageCandidateId },
        });

        if (existingStageActions) {
          const updatedStageActions = cloneDeep(existingStageActions);

          updatedStageActions.GetStageActions.stageCandidate = {
            ...updatedStageActions.GetStageActions.stageCandidate,
            hasPledgedAnticheating: true,
          };

          cache.writeQuery({
            query: GetStageActionsDocument,
            variables: { stageCandidateId },
            data: updatedStageActions,
          });
        }
      }
    },
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        title: 'Oops, some error occurred!',
      });
    },
  });
  const { handleMsgType } = useNotification();

  const handleCheckboxChange = () => {
    if (hasCheckboxError) {
      setHasCheckboxError(false);
    }

    setIsChecked(prev => !prev);
  };

  const handleSubmit = () => {
    if (!isChecked) {
      setHasCheckboxError(true);
      return;
    }

    updateStatus();
  };

  return (
    <section
      className={styles.container}
      data-testid="stage__assessment-anticheating-contract"
    >
      <h2 className={styles.title}>
        {t(`stage.assessmentPledge.assessmentCommitment`)}
      </h2>
      <div className={styles.pledge}>
        <p className={styles.description}>
          {t(`stage.assessmentPledge.pledge`)}
        </p>
        <Checkbox
          label={t(`stage.assessmentPledge.checkboxCommitment`)}
          checked={isChecked}
          hasError={hasCheckboxError}
          bottomText={
            hasCheckboxError ? t(`stage.assessmentPledge.checkboxError`) : ''
          }
          onChange={handleCheckboxChange}
        />
      </div>
      <Button className={styles.cta} onClick={handleSubmit}>
        {t(`stage.assessmentPledge.pledgeCta`)}
      </Button>
    </section>
  );
};

export default AnticheatingContract;
