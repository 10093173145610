import CompanyLogo from 'components/molecules/CompanyLogo/CompanyLogo';
import Container from 'components/molecules/Container/Container';
import { appSwitchRoutes } from 'constants/routes';
import {
  StageCandidateStatus,
  StageStatus,
  useGetStageActionsQuery,
  useStageCandidateSubmitAdjustmentQuestionnaireMutation,
} from 'generated/graphql';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Navbar from '../Stage/StageView/Navbar';
import {
  adjustmentsResponsesType,
  steps,
  stepsOrder,
} from './AccessibilityAdjustments.helpers';
import styles from './AccessibilityAdjustments.module.scss';
import AccessibilityAdjustmentStep from './AccessibilityAdjustmentStep';

const AccessibilityAdjustments = () => {
  const navigate = useNavigate();
  const stagesUrl = appSwitchRoutes.stages.getUrl();
  const { stageCandidateId, step } = useParams();
  const stageCandidateIdParsed = Number(stageCandidateId);
  const [responses, setResponses] = useState<adjustmentsResponsesType>([]);
  const [completedStep, setCompletedStep] = useState<steps>('');
  const { t } = useTranslation();
  const { handleMsgType } = useNotification();

  useEffect(() => {
    if (isNaN(stageCandidateIdParsed)) {
      navigate(stagesUrl);
    }
  }, [navigate, stageCandidateIdParsed, stagesUrl]);

  const { data: stageData, loading } = useGetStageActionsQuery({
    variables: { stageCandidateId: stageCandidateIdParsed },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(stagesUrl);
    },
    onCompleted: data => {
      const stageStatus = data.GetStageActions.stage.status;
      const stageCandidateStatus = data.GetStageActions.stageCandidate.status;
      const hasAdjustmentsQuestionnaire =
        data.GetStageActions.stage.hasAdjustmentQuestionnaire;
      const hasCompletedAdjustmentsQuestionnaire =
        data.GetStageActions.stageCandidate.hasCompletedAdjustmentQuestionnaire;

      // Redirect to assessment if user doesn't have the questionnaire available
      if (!hasAdjustmentsQuestionnaire) {
        navigate(appSwitchRoutes.stage.getUrl(Number(stageCandidateId)));
      }

      if (
        stageStatus === StageStatus.Expired ||
        stageStatus === StageStatus.Scheduled ||
        stageCandidateStatus === StageCandidateStatus.Completed ||
        stageCandidateStatus === StageCandidateStatus.SignedOff ||
        stageCandidateStatus === StageCandidateStatus.ManagerCompleted ||
        hasCompletedAdjustmentsQuestionnaire
      ) {
        navigate(stagesUrl);
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const completedStepIndex = stepsOrder.indexOf(completedStep);
    const currentStepIndex = stepsOrder.indexOf(step || '');
    // Block user from navigating backwards with browser button after submitting the form
    if (completedStep === 'details' && currentStepIndex < completedStepIndex) {
      navigate(stagesUrl);
    }
  }, [step, navigate, completedStep, stagesUrl]);

  const [submitQuestionnaire, { loading: submitLoading }] =
    useStageCandidateSubmitAdjustmentQuestionnaireMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
    });

  const handleResponses = (values: adjustmentsResponsesType, step: steps) => {
    setResponses(values);
    handleProgress(step);
  };

  const handleProgress = (step: steps) => {
    setCompletedStep(step);
  };

  const handleSubmitResponses = (redirectUrl: string, step?: steps) => {
    if (step) {
      handleProgress(step);
    }
    submitQuestionnaire({
      variables: {
        responses: responses,
        stageCandidateId: stageCandidateIdParsed,
      },
      onCompleted: () => {
        navigate(redirectUrl);
      },
    });
    return;
  };

  const tests = useMemo(() => {
    return stageData?.GetStageActions.actions.map(action => action.type) || [];
  }, [stageData]);

  if (loading) {
    return (
      <Container className="page-loader-wrapper">
        <Loader variant="bubbles" />
      </Container>
    );
  }
  if (stageData) {
    const stageActions = stageData?.GetStageActions;

    return (
      <Container
        className={styles.container}
        dataTestId="accessibility-adjustment"
      >
        <header>
          <Navbar />
        </header>
        <main className={styles.content} aria-live="polite">
          <div className={styles.details}>
            <CompanyLogo
              companyLogoUrl={stageActions.company.logoUrl}
              companyName={stageActions.company.name}
            />
            <h1 className={styles.title} tabIndex={-1}>
              {stageActions.project.name}
            </h1>
          </div>
          <h2 className={styles.sectionTitle}>
            {t('accessibilityAdjustments.title')}
          </h2>
          <AccessibilityAdjustmentStep
            completedStep={completedStep}
            step={step}
            stageData={stageData}
            handleProgress={handleProgress}
            handleResponses={handleResponses}
            handleSubmitResponses={handleSubmitResponses}
            responses={responses}
            loading={submitLoading}
            tests={tests}
            stageCandidateId={stageCandidateIdParsed}
          />
        </main>
      </Container>
    );
  }
};

export default AccessibilityAdjustments;
