import { localeToLanguageCode } from './locales';
import { formatDistanceToNowStrict as formatDistanceToNowLocale } from 'date-fns';
import * as locale from 'date-fns/locale';
import { SupportedLocale } from '@spotted-zebra-uk/ui-components';

const dateLocaleFromLanguage: { [key: string]: Locale } = {
  [SupportedLocale.GERMAN]: locale.de,
  [SupportedLocale.SPANISH_LATIN_AMERICAN]: locale.es,
  [SupportedLocale.INDONESIAN]: locale.id,
  [SupportedLocale.TURKISH]: locale.tr,
  [SupportedLocale.FRENCH]: locale.fr,
  [SupportedLocale.DANISH]: locale.da,
  [SupportedLocale.ITALIAN]: locale.it,
  [SupportedLocale.DUTCH]: locale.nl,
  [SupportedLocale.KOREAN]: locale.ko,
  [SupportedLocale.MALAY]: locale.ms,
  [SupportedLocale.CHINESE]: locale.zhCN,
  [SupportedLocale.CHINESE_TRADITIONAL]: locale.zhTW,
};

export const getDateLocaleFromLanguageString = (language: string): Locale => {
  const dateLocale = dateLocaleFromLanguage[language];

  return dateLocale ?? locale.enGB;
};

export const formatDistanceToNow = (endTime: Date, language: string) => {
  return formatDistanceToNowLocale(endTime, {
    locale: getDateLocaleFromLanguageString(language),
  });
};

export const getLocaleFromLanguageString = (language: string) => {
  return language === 'en' ? 'en-GB' : localeToLanguageCode[language];
};

export const formatDateToText = (date: Date, language: string) => {
  return Intl.DateTimeFormat(language, {
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZoneName: 'short',
  }).format(date);
};
