import { TechnicalSkillSelfAssessmentProficiencyLevel } from 'generated/graphql';
import capitalize from 'lodash/capitalize';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SkillCard } from '@spotted-zebra-uk/ui-components';
import {
  filterSkillsByProficiency,
  sortSkillsByName,
} from '../../helpers/helpers';
import { ILayout, Skill } from '../../types';

const LevelLayout: FC<ILayout> = ({
  skills,
  showNoKnowledgeSkills,
  onSkillClick,
}) => {
  const { t } = useTranslation();

  const expertSkills = filterSkillsByProficiency(
    skills,
    TechnicalSkillSelfAssessmentProficiencyLevel.Expert
  );
  const intermediateSkills = filterSkillsByProficiency(
    skills,
    TechnicalSkillSelfAssessmentProficiencyLevel.Intermediate
  );
  const beginnerSkills = filterSkillsByProficiency(
    skills,
    TechnicalSkillSelfAssessmentProficiencyLevel.Beginner
  );
  const noKnowledgeSkills = filterSkillsByProficiency(
    skills,
    TechnicalSkillSelfAssessmentProficiencyLevel.NoKnowledge
  );

  const sortedExpertSkills = sortSkillsByName(expertSkills);
  const sortedIntermediateSkills = sortSkillsByName(intermediateSkills);
  const sortedBeginnerSkills = sortSkillsByName(beginnerSkills);
  const sortedNoKnowledgeSkills = sortSkillsByName(noKnowledgeSkills);

  const getSkillCardComponent = (skill: Skill) => {
    return (
      <SkillCard
        testId="skill-button"
        id={skill.id}
        key={`${skill.name}-${skill.id}`}
        label={capitalize(skill.name)}
        companyOwned={skill.isCompanySpecific}
        proficiency={skill.proficiency}
        onClick={() => onSkillClick && onSkillClick(skill)}
      />
    );
  };

  return (
    <>
      {Boolean(expertSkills.length) && (
        <div
          className="personal-skills-profile__skills-group"
          data-testid="expert-level-skills"
        >
          <h3 className="personal-skills-profile__skills-group-title">
            {t('skills.expert')}
          </h3>
          <div className="personal-skills-profile__skills-items">
            {sortedExpertSkills.map(getSkillCardComponent)}
          </div>
        </div>
      )}
      {Boolean(intermediateSkills.length) && (
        <div
          className="personal-skills-profile__skills-group"
          data-testid="intermediate-level-skills"
        >
          <h3 className="personal-skills-profile__skills-group-title">
            {t('skills.intermediate')}
          </h3>
          <div className="personal-skills-profile__skills-items">
            {sortedIntermediateSkills.map(getSkillCardComponent)}
          </div>
        </div>
      )}
      {Boolean(beginnerSkills.length) && (
        <div
          className="personal-skills-profile__skills-group"
          data-testid="beginner-level-skills"
        >
          <h3 className="personal-skills-profile__skills-group-title">
            {t('skills.beginner')}
          </h3>
          <div className="personal-skills-profile__skills-items">
            {sortedBeginnerSkills.map(getSkillCardComponent)}
          </div>
        </div>
      )}
      {Boolean(noKnowledgeSkills.length) && showNoKnowledgeSkills && (
        <div
          className="personal-skills-profile__skills-group"
          data-testid="no-knowledge-level-skills"
        >
          <h3 className="personal-skills-profile__skills-group-title">
            {t('skills.noSkill')}
          </h3>
          <div className="personal-skills-profile__skills-items">
            {sortedNoKnowledgeSkills.map(getSkillCardComponent)}
          </div>
        </div>
      )}
    </>
  );
};

export default memo(LevelLayout);
