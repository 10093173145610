import './styles.scss';
import FormBuilderField from 'components/molecules/FormBuilderField/FormBuilderField';
import {
  setInitialValues,
  setValidationSchema,
} from 'components/molecules/FormBuilderField/formBuilderHelper';
import { FormType } from 'generated/graphql';
import { useForm } from 'hooks/useForm';
import { TFormFieldValue } from 'interfaces/forms/formGQL';
import {
  IRespondantFormUpdateMutationResponse,
  TRespondantFormField,
} from 'interfaces/talentReview';
import capitalize from 'lodash/capitalize';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MutationResult } from '@apollo/client';
import { Button } from '@spotted-zebra-uk/ui-components';

interface IStageActionFromContainer {
  fields: TRespondantFormField[];
  onSubmit: (
    values: {
      [key in string]: TFormFieldValue;
    },
    fields: TRespondantFormField[]
  ) => void;
  saveRespondantFormResponse?: MutationResult<IRespondantFormUpdateMutationResponse>;
  formType: FormType;
}

const StageActionFromContainer: FC<IStageActionFromContainer> = ({
  fields,
  onSubmit,
  saveRespondantFormResponse,
  formType,
}) => {
  const { t } = useTranslation();
  const initialValues = useMemo(() => setInitialValues(fields), [fields]);
  const validationSchema = useMemo(() => setValidationSchema(fields), [fields]);
  const [values, errors, handleChange, handleSubmit] = useForm({
    options: {
      initialValues: initialValues,
      validationShema: validationSchema,
    },
    onSubmit: values => {
      onSubmit(values, fields);
    },
  });

  const formKey = 'rs';
  return (
    <div className="form-container">
      {fields.map(field => {
        return (
          <div className={'form-container_field'} key={field.id}>
            <FormBuilderField
              placeholder="N/A"
              id={`${formKey}-${field.id}`}
              name={field.id.toString()}
              type={field.type}
              label={field.label}
              options={field.options}
              value={values[field.id]}
              error={errors && errors[field.id]}
              onChange={handleChange}
              hint={field.hint}
              settings={field.settings}
              formType={formType}
              className={'rs'}
              autoComplete="off"
            />
          </div>
        );
      })}
      <div className="form-container__actions">
        <Button
          disabled={saveRespondantFormResponse?.loading}
          onClick={handleSubmit}
        >
          {saveRespondantFormResponse?.loading
            ? `${t('common.submitting')}...`
            : capitalize(t('assessment.completeQuestionnaire'))}
        </Button>
      </div>
    </div>
  );
};

export default StageActionFromContainer;
