import './InductiveTest.scss';
import Container from 'components/molecules/Container/Container';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import {
  InductiveItemModel,
  useQCalcGetNextQuestionMutation,
  useTestCandidateStartSzPracticeTestMutation,
  useTestCandidateStartSzTestMutation,
} from 'generated/graphql';
import { useWindowSize } from 'hooks/containerSize';
import { useDisableCopyAndRightClick } from 'hooks/useDisableCopyAndRightClick';
import useHubspotDisable from 'hooks/useHubspotDisable';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import TestHeader from '../components/TestHeader/TestHeader';
import InductiveTestQuestion from './InductiveTestQuestionView/InductiveTestQuestionView';

interface IInductiveTest {
  testCandidateId: number;
  isPracticeTest: boolean;
  onFinishTest: () => void;
}
const MOBILE_BREAKPOINT = 970;

const InductiveTest: FC<IInductiveTest> = ({
  testCandidateId,
  isPracticeTest,
  onFinishTest,
}) => {
  const [containerSize] = useWindowSize();
  const isMobile = containerSize.width < MOBILE_BREAKPOINT;
  useHubspotDisable(isMobile);
  const { t } = useTranslation();
  useDisableCopyAndRightClick();
  const { handleMsgType } = useNotification();

  const [answerImage, setAnswerImage] = useState<undefined | string>();
  const [answerId, setAnswerId] = useState<undefined | string>();

  const [testCandidateStartSzTestMutation, testCandidateStartSzTestResponse] =
    useTestCandidateStartSzTestMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      variables: {
        testCandidateId: +testCandidateId,
      },
      onCompleted: data => {
        const assessmentId = data.TestCandidateStartSzTest.assessmentId;
        if (assessmentId) {
          qCalcGetNextQuestionMutation({
            variables: {
              assessmentId,
              nQuestions: 1,
            },
          });
        }
      },
    });

  const [
    testCandidateStartSzPracticeTestMutation,
    testCandidateStartSzPracticeTestMutationResponse,
  ] = useTestCandidateStartSzPracticeTestMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
    variables: {
      id: testCandidateId,
    },
    onCompleted: data => {
      const assessmentId = data.TestCandidateStartSzPracticeTest.assessmentId;
      if (assessmentId) {
        qCalcGetNextQuestionMutation({
          variables: {
            assessmentId,
            nQuestions: 1,
          },
        });
      }
    },
  });

  const assessmentId = isPracticeTest
    ? testCandidateStartSzPracticeTestMutationResponse.data
        ?.TestCandidateStartSzPracticeTest.assessmentId
    : testCandidateStartSzTestResponse.data?.TestCandidateStartSzTest
        .assessmentId;

  const [qCalcGetNextQuestionMutation, qCalcGetNextQuestionResponse] =
    useQCalcGetNextQuestionMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      onCompleted: data => {
        const res = data.QCalcGetNextQuestion as InductiveItemModel;

        if (res.isCompleted) {
          onFinishTest();
        } else if (res.question) {
          setAnswerId(undefined);
          setAnswerImage(undefined);
        }
      },
    });

  useEffect(() => {
    if (isPracticeTest) {
      testCandidateStartSzPracticeTestMutation();
    } else {
      testCandidateStartSzTestMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const qCalcGetNextInductiveQuestion = qCalcGetNextQuestionResponse.data
    ?.QCalcGetNextQuestion as InductiveItemModel | undefined;

  const submitAnswer = useCallback(() => {
    if (assessmentId && qCalcGetNextInductiveQuestion?.question) {
      const questionSubId = qCalcGetNextInductiveQuestion.question.subId;
      qCalcGetNextQuestionMutation({
        variables: {
          assessmentId,
          nQuestions: 1,
          answers: [
            {
              optionSubId: answerId,
              questionSubId: questionSubId,
            },
          ],
        },
      });
    }
  }, [
    answerId,
    assessmentId,
    qCalcGetNextInductiveQuestion?.question,
    qCalcGetNextQuestionMutation,
  ]);

  const handleSetAnswer = useCallback((answerId: string, answerImg: string) => {
    setAnswerImage(answerImg);
    setAnswerId(answerId);
  }, []);

  if (!qCalcGetNextInductiveQuestion?.question?.imageUrls) {
    return null;
  }

  const currentQuestion =
    qCalcGetNextInductiveQuestion?.totalQuestions -
    (qCalcGetNextInductiveQuestion?.remainingQuestions - 1);
  const totalQuestions = qCalcGetNextInductiveQuestion?.totalQuestions;

  return (
    <>
      <HelmetAndPageAnnouncer
        pageTitle={
          isPracticeTest
            ? t('test.instructions.inductive.practiceName')
            : t('test.instructions.inductive.name')
        }
      />

      {qCalcGetNextQuestionResponse.loading ? (
        <Container className="page-loader-wrapper">
          <Loader variant="bubbles" />
        </Container>
      ) : (
        <Container className="InductiveTest">
          <TestHeader
            assessmentId={Number(assessmentId)}
            progressBarProps={{
              value: currentQuestion,
              maxValue: totalQuestions,
            }}
            hasTimer
            timerInterval={qCalcGetNextInductiveQuestion.remainingTime}
            timeLimit={qCalcGetNextInductiveQuestion.question.timeLimit}
            onTimeout={submitAnswer}
            pageIndicatorProps={{
              prefixWord: isMobile ? t('common.question') : t('common.page'),
              connectorWord: t('common.of'),
              currentPage: currentQuestion,
              existingPages: totalQuestions,
            }}
          />
          <div className="InductiveTest__QuestionViewWrapper">
            <InductiveTestQuestion
              questionImages={
                qCalcGetNextInductiveQuestion.question.imageUrls as string[]
              }
              options={qCalcGetNextInductiveQuestion.question.options}
              questionText={qCalcGetNextInductiveQuestion.question.text}
              onAnswerSubmit={submitAnswer}
              onAnswer={handleSetAnswer}
              answerId={answerId}
              answerImage={answerImage}
            />
          </div>
        </Container>
      )}
    </>
  );
};

export default InductiveTest;
