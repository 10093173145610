import Icon from 'components/atoms/Icon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from '@spotted-zebra-uk/ui-components';

interface IAssessmentNavigation {
  loading: boolean;
  focusedSkillOrderNumber: number;
  numberOfSkillsInAssessment: number;
  onSeeAllSkillsButtonClick: () => void;
}

const AssessmentNavigation: FC<IAssessmentNavigation> = ({
  loading,
  focusedSkillOrderNumber,
  numberOfSkillsInAssessment,
  onSeeAllSkillsButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className="assessment-navigation">
      <div className="assessment-navigation__left">
        {!loading ? (
          <div className="assessment-navigation__progress-indicator">
            <div className="assessment-navigation__progress-indicator-focused">
              {focusedSkillOrderNumber}
            </div>
            <div className="assessment-navigation__skills-number">
              of {numberOfSkillsInAssessment}
            </div>
          </div>
        ) : (
          <Loader size="small" variant="bubbles" />
        )}
      </div>
      <div className="assessment-navigation__right">
        <Button
          onClick={onSeeAllSkillsButtonClick}
          variant="tertiary"
          className="assessment-navigation__mode-navigation-button"
          size="small"
          disabled={loading}
        >
          <Icon icon="list" />
          {t('common.seeAllSkills')}
        </Button>
      </div>
    </div>
  );
};

export default AssessmentNavigation;
