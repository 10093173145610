import { FC } from 'react';

interface IStageCompanyLogo {
  companyLogoUrl?: string | null;
  companyName?: string;
}

const StageCompanyLogo: FC<IStageCompanyLogo> = ({
  companyLogoUrl,
  companyName,
}) => {
  return (
    <>
      {companyLogoUrl ? (
        <div className="stage-company-logo">
          <img
            className="stage-company-logo__image"
            src={companyLogoUrl}
            alt=""
          />
        </div>
      ) : (
        <p className="stage-company-logo">{companyName || ''}</p>
      )}
    </>
  );
};

export default StageCompanyLogo;
