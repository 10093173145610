import './MobileNumericalTest.scss';
import Loader from 'components/atoms/Loader/Loader';
import ProgressBar from 'components/molecules/ProgressBar/ProgressBar';
import SVGRenderer from 'components/molecules/SVGRenderer/SVGRenderer';
import {
  NumericalTestletModel,
  useQCalcGetNextQuestionMutation,
} from 'generated/graphql';
import { useWindowSize } from 'hooks/containerSize';
import useHubspotDisable from 'hooks/useHubspotDisable';
import { TSize } from 'interfaces/size';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SliderTestQuestion from 'views/Test/Tests/components/SliderTestQuestion/SliderTestQuestion';
import TestHeader from 'views/Test/Tests/components/TestHeader/TestHeader';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { useBlockTestSubmit } from '../../helpers/blockTestSubmit';
import { QUESTIONS_IN_NUMERICAL_TESTLET } from '../NumericalTest.constants';
import { INumericalTestAnswer } from '../NumericalTest.types';

interface IMobileNumericalTest {
  assessmentId: number;
  onFinishTest: () => void;
}

const TEST_HEADER_HEIGHT = 82;
const PADDING = 10;

// Image should correctly fits when question is not expanded.
const getImageSize = (containerSize: TSize, questionWrapperSize: TSize) => ({
  width: containerSize.width - PADDING * 2,
  height:
    containerSize.height -
    questionWrapperSize.height -
    TEST_HEADER_HEIGHT -
    PADDING * 2,
});

const MobileNumericalTest: FC<IMobileNumericalTest> = ({
  assessmentId,
  onFinishTest,
}) => {
  useHubspotDisable(true);
  const { t } = useTranslation();
  const { handleMsgType } = useNotification();
  const [containerSize] = useWindowSize();
  const questionHeaderRef = useRef<HTMLDivElement | null>(null);
  const { blockedSubmit, activateBlockingSubmit } = useBlockTestSubmit();

  const [imageSize, setImageSize] = useState(
    getImageSize(containerSize, { height: 0, width: 0 })
  );
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [numericalQuestion, setNumericalQuestion] =
    useState<NumericalTestletModel>();
  const [numericalAnswer, setNumericalAnswer] = useState<
    INumericalTestAnswer[]
  >([]);
  const [defaultAnswer, setDefaultAnswer] = useState(0);

  const [qCalcGetNextQuestionMutation, qCalcGetNextQuestionResponse] =
    useQCalcGetNextQuestionMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      onCompleted: data => {
        if (data && data.QCalcGetNextQuestion.isCompleted) {
          onFinishTest();
        }
        if (data.QCalcGetNextQuestion) {
          setNumericalQuestion(
            data.QCalcGetNextQuestion as NumericalTestletModel
          );
          setDefaultAnswerValue(
            data.QCalcGetNextQuestion as NumericalTestletModel
          );
          setCurrentIndex(0);
        }
      },
    });

  const setDefaultAnswerValue = (data: NumericalTestletModel) => {
    const isDefaultAnswer = data.questions.map(item => ({
      optionSubId: item.options[0].subId,
      questionSubId: item.subId,
    }));
    setNumericalAnswer(isDefaultAnswer);
  };

  useEffect(() => {
    qCalcGetNextQuestionMutation({
      variables: {
        assessmentId,
        nQuestions: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (questionHeaderRef.current) {
      setImageSize(
        getImageSize(containerSize, {
          width: questionHeaderRef.current.offsetWidth,
          height: questionHeaderRef.current.offsetHeight,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, containerSize, questionHeaderRef.current]);

  const getAnswer = (
    questionIndex: number,
    optionSubId: string,
    questionSubId: string
  ) => {
    const newAnswer = {
      optionSubId,
      questionSubId,
    };
    const temp = [...numericalAnswer];
    temp[questionIndex] = newAnswer;
    setNumericalAnswer(temp);
  };

  const handleAnswer = () => {
    if (
      numericalQuestion &&
      currentIndex + 1 < numericalQuestion?.questions.length
    ) {
      setCurrentIndex(currentIndex + 1);
      setDefaultAnswer(-1);
    } else {
      qCalcGetNextQuestionMutation({
        variables: {
          answers: numericalAnswer,
          assessmentId,
          nQuestions: 1,
        },
      });
      setDefaultAnswer(-1);
    }
    activateBlockingSubmit();
  };

  const handleTimeout = () => {
    qCalcGetNextQuestionMutation({
      variables: {
        answers: numericalAnswer,
        assessmentId,
        nQuestions: 1,
      },
    });
    setDefaultAnswer(-1);
    activateBlockingSubmit();
  };

  const handleUpdateDefault = () => {
    setDefaultAnswer(-2);
  };

  if (qCalcGetNextQuestionResponse.loading) {
    return <Loader isOverlay />;
  }

  if (!numericalQuestion) {
    return null;
  }

  const { totalQuestions, remainingQuestions } = numericalQuestion;
  const currentTestlet =
    totalQuestions / QUESTIONS_IN_NUMERICAL_TESTLET -
    remainingQuestions / QUESTIONS_IN_NUMERICAL_TESTLET +
    1;
  const testletsInTest = totalQuestions / QUESTIONS_IN_NUMERICAL_TESTLET;
  const currentQuestion =
    currentIndex + 1 + totalQuestions - remainingQuestions;

  return (
    <div
      style={{ height: containerSize.height }}
      className="MobileNumericalTest"
    >
      <TestHeader
        assessmentId={assessmentId}
        progressBarProps={{
          value: currentTestlet,
          maxValue: testletsInTest,
        }}
        hasTimer
        timerInterval={numericalQuestion.remainingTime}
        timeLimit={numericalQuestion.timeLimit}
        onTimeout={handleTimeout}
        hasProgressBar={false}
        pageIndicatorProps={{
          currentPage: currentQuestion,
          existingPages: totalQuestions,
          prefixWord: 'Question',
          connectorWord: 'of',
        }}
      />
      <div className="MobileNumericalTest__Testlet">
        <div className="MobileNumericalTest__Testlet__ImageWrapper">
          <SVGRenderer
            src={numericalQuestion.imageUrl}
            alt={t('alt.testChart')}
            size={imageSize}
            loading={() => (
              <div
                style={{
                  width: imageSize.width,
                  height: imageSize.height,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader />
              </div>
            )}
          />
        </div>
        <div
          className="MobileNumericalTest__Testlet__QuestionWrapper"
          ref={questionHeaderRef}
        >
          <ProgressBar
            value={currentIndex + 1}
            maxValue={numericalQuestion.questions.length}
          />
          <SliderTestQuestion
            questionNumberingFormat={`${currentIndex + 1}/${
              numericalQuestion.questions.length
            }`}
            defaultSelectedAnswer={defaultAnswer}
            questionIndex={currentIndex}
            questions={numericalQuestion.questions[currentIndex]}
            onAnswer={getAnswer}
            isMobile={true}
            updateDefault={handleUpdateDefault}
          />
          <div className="MobileNumericalTest__SubmitButton">
            <Button
              size="large"
              onClick={handleAnswer}
              disabled={blockedSubmit}
            >
              {t('test.submitAndProceed')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNumericalTest;
