import { useEffect } from 'react';

const useHubspotDisable = (shouldRemove: boolean) => {
  useEffect(() => {
    // Remove Hubspot chat on Mobile
    shouldRemove && window.HubSpotConversations?.widget?.remove();

    return () => {
      // Add back when leaving test
      shouldRemove && window.HubSpotConversations?.widget?.load();
    };
  }, [shouldRemove]);
};

export default useHubspotDisable;
