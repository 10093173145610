import './styles.scss';
import { IconType } from 'components/atoms/Icon';
import Container from 'components/molecules/Container/Container';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import { appSwitchRoutes, stageSwitchRoutes } from 'constants/routes';
import {
  RssAssessmentCandidateStatus,
  RssAssessmentType,
  SkillsProfileOpenOneDocument,
  SoftSkillSelfAssessmentProficiencyLevel,
  useGetRssAssessmentStageActionLazyQuery,
  useResultCreateManySoftSkillsProfileFromScMutation,
  useRssAssessmentCandidateOpenOneLazyQuery,
  useRssAssessmentCandidateSubmitOneMutation,
} from 'generated/graphql';
import { isMobile } from 'helpers/deviceDetect';
import { barChartValuesToSoftSkillProficiencyLevelMap } from 'helpers/skillsProficiency';
import { IRoleSpecificSkillAssessmentPath } from 'interfaces/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  parseAssessmentCandidateGradeBands,
  parseAssessmentCandidateSkillsWithResult,
} from './helpers';
import RoleSpecificSkillsAssessmentDesktop from './RoleSpecificSkillsAssessmentDesktop';
import RoleSpecificSkillsAssessmentMobile from './RoleSpecificSkillsAssessmentMobile';
import { TSkillWithAnswer } from './types';

const RoleSpecificSoftSkillsAssessment: FC = () => {
  const { t } = useTranslation();
  const HEADING_TITLE = t('common.softSkills');
  const HEADING_ICON: IconType = 'skills_self_assessment';
  const HEADING_DESCRIPTION = t('skills.softSkillsDescr');

  const { handleMsgType } = useNotification();
  const navigate = useNavigate();
  const { rssAssessmentCandidateId, stageCandidateId } =
    useParams() as IRoleSpecificSkillAssessmentPath;
  const parsedRssAssessmentCandidateId = Number(rssAssessmentCandidateId);
  const parsedStageCandidateId = Number(stageCandidateId);
  const [
    rssAssessmentCandidateOpenOneQuery,
    rssAssessmentCandidateOpenOneQueryResponse,
  ] = useRssAssessmentCandidateOpenOneLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parsedRssAssessmentCandidateId,
    },
  });
  const [getRssAssessmentStageActionQuery, { data: stageActionData }] =
    useGetRssAssessmentStageActionLazyQuery({
      variables: {
        id: parsedStageCandidateId,
      },
      fetchPolicy: 'cache-and-network',
    });
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!rssAssessmentCandidateId || !stageCandidateId) {
        navigate(appSwitchRoutes.stages.getUrl());
      }

      try {
        const { data: rssAssessmentCandidateOpenOneData } =
          await rssAssessmentCandidateOpenOneQuery();
        const rssAssessmentCandidateStatus =
          rssAssessmentCandidateOpenOneData?.RssAssessmentCandidateOpenOne
            .rssAssessmentCandidate.status;

        const { data: stageActionData } =
          await getRssAssessmentStageActionQuery();
        const stageTechnicalRssAssessmentCandidate =
          stageActionData?.StageCandidateFindOne?.actions.rssAssessmentAction
            .technicalRssAssessmentCandidate;

        if (
          rssAssessmentCandidateStatus ===
            RssAssessmentCandidateStatus.Submitted &&
          stageCandidateId
        ) {
          if (
            stageTechnicalRssAssessmentCandidate &&
            [
              RssAssessmentCandidateStatus.NotStarted,
              RssAssessmentCandidateStatus.OpenForChanges,
            ].includes(stageTechnicalRssAssessmentCandidate.status)
          ) {
            return navigate(
              stageSwitchRoutes.roleSpecificSkillsAssessment.getUrl(
                stageCandidateId,
                stageTechnicalRssAssessmentCandidate.id,
                RssAssessmentType.TechnicalSkill
              )
            );
          }

          return navigate(stageSwitchRoutes.stage.getUrl(stageCandidateId));
        }
        setLoading(false);
      } catch (_error) {
        handleMsgType({
          type: TNotification.error,
          title: 'Oops, some error occurred!',
        });
        if (stageCandidateId) {
          navigate(appSwitchRoutes.stage.getUrl(stageCandidateId));
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [rssAssessmentCandidateSubmitOneMutation] =
    useRssAssessmentCandidateSubmitOneMutation({
      variables: {
        id: parsedRssAssessmentCandidateId,
      },
    });
  const [resultCreateManySoftSkillsProfileFromScMutation] =
    useResultCreateManySoftSkillsProfileFromScMutation({
      refetchQueries: [SkillsProfileOpenOneDocument],
    });

  const submit = async (skillsWithAnswers: TSkillWithAnswer[]) => {
    const allSkillsAnswered = !skillsWithAnswers.some(
      skillWithAnswer => !skillWithAnswer.answerValue
    );

    if (
      !rssAssessmentCandidateOpenOneQueryResponse.data ||
      !allSkillsAnswered ||
      !stageCandidateId
    ) {
      return;
    }

    setSubmitting(true);
    try {
      const skillsScores = skillsWithAnswers.map(({ answerValue, id }) => {
        return {
          score: {
            proficiency: answerValue
              ? barChartValuesToSoftSkillProficiencyLevelMap[answerValue]
              : SoftSkillSelfAssessmentProficiencyLevel.Beginner,
          },
          softSkillId: id,
          stageCandidateId: parsedStageCandidateId,
        };
      });
      await resultCreateManySoftSkillsProfileFromScMutation({
        variables: {
          args: skillsScores,
        },
      });
      await rssAssessmentCandidateSubmitOneMutation();
      await rssAssessmentCandidateOpenOneQuery();
      handleMsgType({
        type: TNotification.success,
        title: t('assessment.softSkills.successNotification'),
      });

      const stageTechnicalRssAssessmentCandidate =
        stageActionData?.StageCandidateFindOne?.actions.rssAssessmentAction
          .technicalRssAssessmentCandidate;

      if (
        stageTechnicalRssAssessmentCandidate &&
        [
          RssAssessmentCandidateStatus.NotStarted,
          RssAssessmentCandidateStatus.OpenForChanges,
        ].includes(stageTechnicalRssAssessmentCandidate.status)
      ) {
        navigate(
          stageSwitchRoutes.roleSpecificSkillsAssessment.getUrl(
            stageCandidateId,
            stageTechnicalRssAssessmentCandidate.id,
            RssAssessmentType.TechnicalSkill
          )
        );
      } else {
        navigate(
          stageSwitchRoutes.skillsSelfAssessmentThankYou.getUrl(
            stageCandidateId
          )
        );
      }
    } catch (_error) {
      handleMsgType({
        type: TNotification.error,
        title: t('assessment.softSkills.errorNotification'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const gradeBands = parseAssessmentCandidateGradeBands(
    rssAssessmentCandidateOpenOneQueryResponse.data
  );
  const initialSkillsWithAnswers = parseAssessmentCandidateSkillsWithResult(
    rssAssessmentCandidateOpenOneQueryResponse.data
  );

  return (
    <>
      <HelmetAndPageAnnouncer pageTitle={t('assessment.softSkills.name')} />
      {!rssAssessmentCandidateOpenOneQueryResponse.data || loading ? (
        <Container className="page-loader-wrapper">
          <Loader variant="bubbles" />
        </Container>
      ) : isMobile() ? (
        <RoleSpecificSkillsAssessmentMobile
          headingDescription={HEADING_DESCRIPTION}
          headingIcon={HEADING_ICON}
          headingTitle={HEADING_TITLE}
          onSubmit={submit}
          initialSkillsWithAnswers={initialSkillsWithAnswers}
          gradeBands={gradeBands}
          loading={submitting}
          skillName={t('common.softSkills')}
        />
      ) : (
        <RoleSpecificSkillsAssessmentDesktop
          headingDescription={HEADING_DESCRIPTION}
          headingIcon={HEADING_ICON}
          headingTitle={HEADING_TITLE}
          onSubmit={submit}
          initialSkillsWithAnswers={initialSkillsWithAnswers}
          gradeBands={gradeBands}
          loading={submitting}
          skillName={t('common.softSkills')}
        />
      )}
    </>
  );
};

export default RoleSpecificSoftSkillsAssessment;
