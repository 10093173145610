import './ThankYouDefaultTemplate.scss';
import { ReactComponent as ClapsIcon } from 'assets/icons/new-claps.svg';
import BaseCompanyLogo from 'components/atoms/BaseCompanyLogo/BaseCompanyLogo';
import Container from 'components/molecules/Container/Container';
import Navbar from 'components/organisms/Navbar/Navbar';
import { ThankYouPageContentType } from 'components/organisms/ThankYouTemplate/ThankYouTemplate.enums';
import { ProjectModuleType } from 'generated/graphql';
import { isMobile } from 'helpers/deviceDetect';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from '@spotted-zebra-uk/ui-components';

interface IThankYouDefaultTemplate {
  onClick: () => void;
  title?: string;
  mainText: string;
  subText?: string;
  buttonText?: string;
  // Adds data-content-type attribute, used only in tests.
  contentType?: ThankYouPageContentType;
  projectModuleType?: ProjectModuleType;
  isCalibrationComplete?: boolean;
  companyLogoUrl?: string;
  doneFor?: string;
  isLoading?: boolean;
}

const ThankYouDefaultTemplate: FC<IThankYouDefaultTemplate> = ({
  onClick,
  title,
  mainText,
  subText,
  buttonText,
  contentType,
  projectModuleType,
  isCalibrationComplete = false,
  companyLogoUrl,
  doneFor,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  const defaultTitle = t('stage.testCallback.thankYou');
  const defaultButtonText = _.capitalize(t('common.continue'));

  const renderDesktop = () => {
    return (
      <>
        {projectModuleType === ProjectModuleType.TalentReview && (
          <div className="navbarShadow">
            <Navbar />
          </div>
        )}
        <Container
          className={`TestCallback ThankYouDefaultTemplate ${
            // isCalibrationComplete &&
            projectModuleType === ProjectModuleType.TalentReview
              ? 'TRWidth'
              : ''
          }`}
        >
          {projectModuleType !== ProjectModuleType.TalentReview && (
            <BaseCompanyLogo className="ThankYouDefaultTemplate__Logo" />
          )}

          <div
            className="ThankYouDefaultTemplate__Center"
            data-content-type={contentType}
          >
            {isCalibrationComplete &&
              projectModuleType === ProjectModuleType.TalentReview && (
                <img
                  className="logo-img"
                  height={33}
                  src={companyLogoUrl}
                  alt={t('alt.logo')}
                />
              )}
            {!isCalibrationComplete && (
              <div className="ThankYouDefaultTemplate__IconWrapper">
                <ClapsIcon className="ThankYouDefaultTemplate__Icon" />
              </div>
            )}
            <h2 className="ThankYouDefaultTemplate__Title">
              {title || defaultTitle}
            </h2>
            {isCalibrationComplete &&
              projectModuleType === ProjectModuleType.TalentReview && (
                <p className="ThankYouDefaultTemplate__Username">{doneFor}</p>
              )}
            <p className="ThankYouDefaultTemplate__Text">{mainText}</p>
            {isCalibrationComplete &&
              projectModuleType === ProjectModuleType.TalentReview && (
                <p className="ThankYouDefaultTemplate__Text">{subText}</p>
              )}
            {isLoading ? (
              <div className="ThankYouDefaultTemplate__Loader">
                <Loader variant="bubbles" />
              </div>
            ) : (
              <Button
                onClick={onClick}
                className="ThankYouDefaultTemplate__Button"
              >
                {buttonText || defaultButtonText}
              </Button>
            )}
          </div>
        </Container>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <div className="mobileTR">
        {projectModuleType === ProjectModuleType.TalentReview && (
          <div className="navbarShadow">
            <Navbar />
          </div>
        )}
        <Container className="ThankYouDefaultTemplate">
          {isCalibrationComplete ? (
            projectModuleType === ProjectModuleType.TalentReview && (
              <img
                className="logo-img"
                height={33}
                src={companyLogoUrl}
                alt={t('alt.logo')}
              />
            )
          ) : (
            <BaseCompanyLogo className="ThankYouDefaultTemplate__Logo" />
          )}
          <div
            className="ThankYouDefaultTemplate__Center"
            data-content-type={contentType}
          >
            {!isCalibrationComplete && (
              <ClapsIcon className="ThankYouDefaultTemplate__Icon" />
            )}
            <h2 className="ThankYouDefaultTemplate__Title">
              {title || defaultTitle}
            </h2>
            {isCalibrationComplete &&
              projectModuleType === ProjectModuleType.TalentReview && (
                <p className="ThankYouDefaultTemplate__Username">{doneFor}</p>
              )}
            <p className="ThankYouDefaultTemplate__Text">{mainText}</p>
            {isCalibrationComplete &&
              projectModuleType === ProjectModuleType.TalentReview && (
                <p className="ThankYouDefaultTemplate__Text">{subText}</p>
              )}
          </div>
          {isLoading ? (
            <div className="ThankYouDefaultTemplate__Loader">
              <Loader variant="bubbles" />
            </div>
          ) : (
            <Button
              onClick={onClick}
              className="ThankYouDefaultTemplate__Button"
            >
              {buttonText || defaultButtonText}
            </Button>
          )}
        </Container>
      </div>
    );
  };

  return isMobile() ? renderMobile() : renderDesktop();
};

export default ThankYouDefaultTemplate;
