import { FC } from 'react';
import { Button } from '@spotted-zebra-uk/ui-components';
import { ReactComponent as PerformanceIcon } from './ic_performance.svg';
import { ReactComponent as PotentialIcon } from './ic_potential.svg';
import { ReactComponent as ValueIcon } from './ic_values.svg';

interface IOnboardingContent1 {
  getPageCount: (count: number) => void;
}

const OnboardingContent1: FC<IOnboardingContent1> = ({ getPageCount }) => {
  const handleNextPage = () => {
    getPageCount(2);
  };

  return (
    <div className="TRInfoContent">
      <p className="intro">Talent Review Introduction</p>
      <p className="reviewBody">
        Welcome to the Talent Review solution. This has been designed to allow
        leaders an opportunity to rate each of their direct reports in relation
        to 3 critical domains that underpin successful performance in role:
      </p>
      <div className="listGroup">
        <div className="reviewList">
          <div className="iconContainer">
            <ValueIcon className="icon" />
          </div>
          <span>Their alignment with our Values</span>
        </div>
        <div className="reviewList">
          <div className="iconContainer">
            <PerformanceIcon className="icon" />
          </div>
          <span>Their level of performance</span>
        </div>
        <div className="reviewList">
          <div className="iconContainer">
            <PotentialIcon className="icon" />
          </div>
          <span>
            Their potential to operate at the next level within 12 months
          </span>
        </div>
      </div>
      <div className="btn-container">
        <Button size="large" onClick={handleNextPage}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default OnboardingContent1;
