import { useEffect, useRef } from 'react';
import {
  AssessmentBrowserSwitchCandidateTrackingEvent,
  useCreateBrowserSwitchEventMutation,
} from 'generated/graphql';

export const useTrackWindowChange = (
  assessmentId: number,
  hasTimer: boolean
) => {
  const [createBrowserSwitchEvent] = useCreateBrowserSwitchEventMutation();
  const startTimeRef = useRef<number>(Date.now());

  const getTimeDifference = () => {
    return Math.floor((Date.now() - startTimeRef.current) / 1000);
  };

  //Prevents multiple calls to blur/focus functions
  const visible = useRef<boolean>(true);

  const handleWindowBlur = () => {
    //When user clicks on hubspot chat, window blur is called. We don't want to catch this event.
    if (visible.current && document.activeElement?.tagName !== 'IFRAME') {
      createBrowserSwitchEvent({
        variables: {
          assessmentId: Number(assessmentId),
          assessmentTimeSeconds: hasTimer ? getTimeDifference() : undefined,
          type: AssessmentBrowserSwitchCandidateTrackingEvent.AssessmentWindowOutOfFocusStartCandidateTrackingEvent,
        },
      });
      visible.current = false;
    }
  };

  const handleWindowFocus = () => {
    //When user clicks on hubspot chat, window blur is called. We don't want to catch this event.
    if (!visible.current && document.activeElement?.tagName !== 'IFRAME') {
      createBrowserSwitchEvent({
        variables: {
          assessmentId: Number(assessmentId),
          assessmentTimeSeconds: hasTimer ? getTimeDifference() : undefined,
          type: AssessmentBrowserSwitchCandidateTrackingEvent.AssessmentWindowOutOfFocusEndCandidateTrackingEvent,
        },
      });
      visible.current = true;
    }
  };

  useEffect(() => {
    window.addEventListener('blur', handleWindowBlur);
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('focus', handleWindowFocus);
    };
    //eslint-disable-next-line
  }, []);
};
