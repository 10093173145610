import './Stages.scss';
import AppBar from 'components/organisms/AppBar/AppBar';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StageCardsListContainer from './StageCardsListContainer/StageCardsListContainer';

interface IStages {}

const Stages: FC<IStages> = () => {
  const { t } = useTranslation();

  return (
    <>
      <HelmetAndPageAnnouncer pageTitle={t('stages.listOfAssessments')} />
      <div className="Stages" aria-live="polite">
        <AppBar />
        <main>
          <StageCardsListContainer />
        </main>
      </div>
    </>
  );
};

export default Stages;
