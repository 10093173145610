import {
  DeiAgeRange,
  DeiEthnicity,
  DeiGender,
  DeiReligiousBelief,
  DeiSexualOrientation,
  DeiStageActionFieldsModel,
  DeiStageActionResponsesFragment,
  DeiYesNo,
  FieldType,
} from 'generated/graphql';
import { DeiFields, deiPayloadKeys } from './StageActionDeiForm.interfaces';

export const deiFieldQuestions: Record<
  keyof DeiFields,
  { key: deiPayloadKeys | ''; description?: string }
> = {
  hasGenderIdentityField: {
    key: 'genderIdentity',
  },
  hasTransgenderIdentityField: {
    key: 'hasTransgenderIdentity',
  },
  hasSexualOrientationField: {
    key: 'sexualOrientation',
  },
  hasEthnicityField: {
    key: 'ethnicity',
  },
  hasReligiousBeliefsField: {
    key: 'religiousBelief',
  },
  hasDisabilityField: {
    description: 'hasDisabilityDescription',
    key: 'hasDisability',
  },
  hasNeurodiversityField: {
    description: 'hasNeurodiversityDescription',
    key: 'hasNeurodiversity',
  },
  hasAgeRangeField: {
    key: 'ageRange',
  },
  __typename: { key: '' },
};

const genderLabels = {
  [DeiGender.Male]: 'male',
  [DeiGender.Female]: 'female',
  [DeiGender.Intersex]: 'intersex',
  [DeiGender.NonBinary]: 'nonBinary',
  [DeiGender.GenderQueer]: 'genderQueer',
  [DeiGender.Other]: 'other',
  [DeiGender.PreferNotToSay]: 'preferNotToSay',
};

const sexualityLabels = {
  [DeiSexualOrientation.Asexual]: 'asexual',
  [DeiSexualOrientation.Bisexual]: 'bisexual',
  [DeiSexualOrientation.Gay]: 'gay',
  [DeiSexualOrientation.Straight]: 'heterosexual',
  [DeiSexualOrientation.Lesbian]: 'lesbian',
  [DeiSexualOrientation.Pansexual]: 'pansexual',
  [DeiSexualOrientation.Queer]: 'queer',
  [DeiSexualOrientation.Other]: 'other',
  [DeiSexualOrientation.PreferNotToSay]: 'preferNotToSay',
};

const ethnicityLabels = {
  [DeiEthnicity.Asian]: 'asian',
  [DeiEthnicity.Black]: 'black',
  [DeiEthnicity.Hispanic]: 'hispanic',
  [DeiEthnicity.IndigenousPeoples]: 'indigenousPeoples',
  [DeiEthnicity.MiddleEasternNorthAfrican]: 'middleEasternNorthAfrican',
  [DeiEthnicity.Mixed]: 'mixed',
  [DeiEthnicity.PacificIslander]: 'pacificIslander',
  [DeiEthnicity.White]: 'white',
  [DeiEthnicity.Other]: 'other',
  [DeiEthnicity.PreferNotToSay]: 'preferNotToSay',
};

const religiousBeliefLabels = {
  [DeiReligiousBelief.Agnosticism]: 'agnosticism',
  [DeiReligiousBelief.Buddhism]: 'buddhism',
  [DeiReligiousBelief.Christianity]: 'christianity',
  [DeiReligiousBelief.Hinduism]: 'hinduism',
  [DeiReligiousBelief.Islam]: 'islam',
  [DeiReligiousBelief.Judaism]: 'judaism',
  [DeiReligiousBelief.Secular]: 'secular',
  [DeiReligiousBelief.Sikhism]: 'sikhism',
  [DeiReligiousBelief.Spiritual]: 'spiritual',
  [DeiReligiousBelief.Other]: 'other',
  [DeiReligiousBelief.PreferNotToSay]: 'preferNotToSay',
};

const ageLabels = {
  [DeiAgeRange.N18_30]: '18-30',
  [DeiAgeRange.N31_40]: '31-40',
  [DeiAgeRange.N41_50]: '41-50',
  [DeiAgeRange.N51_65]: '51-65',
  [DeiAgeRange.N65Plus]: '65+',
};

const yesNoLabels = {
  [DeiYesNo.Yes]: 'yes',
  [DeiYesNo.No]: 'no',
  [DeiYesNo.PreferNotToSay]: 'preferNotToSay',
};

const createSelectOptions = <T extends Record<string, string>>(
  labelsObject: T
): { label: string; value: keyof T }[] =>
  (Object.keys(labelsObject) as Array<keyof T>).map(key => ({
    label: labelsObject[key],
    value: key,
  }));

const yesNoOptions = createSelectOptions(yesNoLabels);

const mapFieldToOptions = {
  hasGenderIdentityField: createSelectOptions(genderLabels),
  hasTransgenderIdentityField: yesNoOptions,
  hasSexualOrientationField: createSelectOptions(sexualityLabels),
  hasEthnicityField: createSelectOptions(ethnicityLabels),
  hasReligiousBeliefsField: createSelectOptions(religiousBeliefLabels),
  hasDisabilityField: yesNoOptions,
  hasNeurodiversityField: yesNoOptions,
  hasAgeRangeField: createSelectOptions(ageLabels),
};

// Fields object contains not only the selected questions but all possible ones, this function
// will filter out the unused questions and then sort the fields in the desired order
const filterAndSortUsedFields = (fields: DeiStageActionFieldsModel) => {
  const orderedKeys = Object.keys(deiFieldQuestions);
  return Object.entries(fields)
    .filter(
      elem =>
        fields[elem[0] as keyof DeiStageActionFieldsModel] &&
        elem[0] !== '__typename'
    )
    .sort((a, b) => orderedKeys.indexOf(a[0]) - orderedKeys.indexOf(b[0]));
};

export const getDeiFieldsWithOptions = (fields: DeiStageActionFieldsModel) => {
  const sortedFieldsWithOptions = filterAndSortUsedFields(fields).flatMap(
    field => {
      const { key, description } =
        deiFieldQuestions[field[0] as keyof DeiFields];

      // Add question label, description and select options to the DEI field
      const newField = {
        id: key,
        description: description,
        options: mapFieldToOptions[field[0] as keyof typeof mapFieldToOptions],
        type: FieldType.SingleSelectField,
      };

      // If genderIdentity is a question in the form, the hasTransgenderIdentity question is also added along with it
      if (field[0] === 'hasGenderIdentityField') {
        return [
          newField,
          {
            id: 'hasTransgenderIdentity',
            options: mapFieldToOptions.hasTransgenderIdentityField,
            type: FieldType.SingleSelectField,
          },
        ];
      }

      return newField;
    }
  );

  return sortedFieldsWithOptions;
};

const deiKeysToFields: Record<deiPayloadKeys, keyof typeof mapFieldToOptions> =
  {
    genderIdentity: 'hasGenderIdentityField',
    hasTransgenderIdentity: 'hasTransgenderIdentityField',
    sexualOrientation: 'hasSexualOrientationField',
    ethnicity: 'hasEthnicityField',
    religiousBelief: 'hasReligiousBeliefsField',
    hasDisability: 'hasDisabilityField',
    hasNeurodiversity: 'hasNeurodiversityField',
    ageRange: 'hasAgeRangeField',
  };

export const initializeDeiValues = (
  pastResponses: DeiStageActionResponsesFragment
) => {
  // Get responses that are not null
  const filteredResponses = Object.entries(pastResponses).filter(
    elem => elem[0] !== '__typename' && elem[1]
  );

  const prefilledValues = filteredResponses.reduce((acc, key) => {
    return Object.assign(acc, {
      [key[0]]: getSelectedOptionByField(key[0], key[1]),
    });
  }, {});

  return prefilledValues;
};

const getSelectedOptionByField = (
  fieldKey: string,
  fieldValue: string | null
) => {
  const field = deiKeysToFields[fieldKey as deiPayloadKeys];
  return (
    mapFieldToOptions[field].find(item => item.value === fieldValue) || {
      label: '',
      value: '',
    }
  );
};
