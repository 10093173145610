import { SkillType } from 'generated/graphql';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  filterNoKnowledgeSkills,
  filterSkillsByType,
  sortSkillsByName,
} from '../../helpers/helpers';
import { ILayout } from '../../types';
import { SkillCard } from '@spotted-zebra-uk/ui-components';
import { Skill } from 'components/molecules/SkillButton/SkillButton';
import capitalize from 'lodash/capitalize';

const TypeLayout: FC<ILayout> = ({
  skills,
  showNoKnowledgeSkills,
  onSkillClick,
}) => {
  const { t } = useTranslation();
  const softSkills = filterNoKnowledgeSkills(
    filterSkillsByType(skills, SkillType.SoftSkill),
    !!showNoKnowledgeSkills
  );
  const technicalSkills = filterNoKnowledgeSkills(
    filterSkillsByType(skills, SkillType.TechnicalSkill),
    !!showNoKnowledgeSkills
  );

  const sortedSoftSkills = sortSkillsByName(softSkills);
  const sortedTechnicalSkills = sortSkillsByName(technicalSkills);

  const getSkillCardComponent = (skill: Skill) => {
    return (
      <SkillCard
        testId="skill-button"
        id={skill.id}
        key={`${skill.name}-${skill.id}`}
        label={capitalize(skill.name)}
        companyOwned={skill.isCompanySpecific}
        proficiency={skill.proficiency}
        onClick={() => onSkillClick && onSkillClick(skill)}
      />
    );
  };

  return (
    <>
      {!!softSkills.length && (
        <div
          className="personal-skills-profile__skills-group"
          data-testid="soft-skills"
        >
          <h3 className="personal-skills-profile__skills-group-title">
            {t('common.softSkills')}
          </h3>
          <div className="personal-skills-profile__skills-items">
            {sortedSoftSkills.map(getSkillCardComponent)}
          </div>
        </div>
      )}
      {!!technicalSkills.length && (
        <div
          className="personal-skills-profile__skills-group"
          data-testid="technical-skills"
        >
          <h3 className="personal-skills-profile__skills-group-title">
            {t('common.technicalSkills')}
          </h3>
          <div className="personal-skills-profile__skills-items">
            {sortedTechnicalSkills.map(getSkillCardComponent)}
          </div>
        </div>
      )}
    </>
  );
};

export default TypeLayout;
