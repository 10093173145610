import { FieldType } from 'generated/graphql';
import { t } from 'i18next';
import { TRespondantFormField } from 'interfaces/talentReview';

export const setInitialValues = (fields: TRespondantFormField[]) => {
  return fields.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr.initialValue ? curr.initialValue : '',
    }),
    {}
  );
};

export const setValidationSchema = (fields: TRespondantFormField[]) => {
  return fields.reduce((acc, curr) => {
    if (curr.isOptional) {
      return acc;
    } else {
      if (curr.type === FieldType.SingleSelectField) {
        return {
          ...acc,
          [curr.id]: {
            presence: {
              allowEmpty: false,
              message: `^${t('common.thisFieldIsRequired')}`,
            },
          },
        };
      }

      if (curr.type === FieldType.DateField) {
        return {
          ...acc,
          [curr.id]: {
            length: {
              minimum: 1,
              message: `^${t('common.thisFieldIsRequired')}`,
            },
          },
        };
      }

      return {
        ...acc,
        [curr.id]: {
          length: {
            minimum: 1,
            message: `^${t('common.thisFieldIsRequired')}`,
          },
        },
      };
    }
  }, {});
};
