import { ThankYouTemplate } from 'components/organisms/ThankYouTemplate/ThankYouTemplate';
import { appSwitchRoutes } from 'constants/routes';
import { IStagePath } from 'interfaces/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const ThankYou = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { stageCandidateId } = useParams() as IStagePath;

  const handleClick = () => {
    stageCandidateId &&
      navigate(appSwitchRoutes.stage.getUrl(stageCandidateId));
  };

  return (
    <ThankYouTemplate
      title={t('test.instructions.selfAssessment.selfAssessmentCompleted')}
      mainText={t('assessment.success')}
      onClick={handleClick}
      buttonText={t('test.instructions.general.closeTest')}
    />
  );
};

export default ThankYou;
