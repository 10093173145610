import { User, SupportedLocale as SupportedLocaleGQL } from 'generated/graphql';

import {
  formatPersonalName,
  SupportedLocale,
} from '@spotted-zebra-uk/ui-components';

//Converts GQL locale to SupportedLocale
export const getLocale = (locale: SupportedLocaleGQL) => {
  return SupportedLocale[locale];
};

export const localeToLanguageCode: { [key: string]: string } = {
  [SupportedLocale.ENGLISH]: 'en',
  [SupportedLocale.DANISH]: 'da',
  [SupportedLocale.GERMAN]: 'de',
  [SupportedLocale.SPANISH_LATIN_AMERICAN]: 'es',
  [SupportedLocale.FRENCH]: 'fr',
  [SupportedLocale.INDONESIAN]: 'id',
  [SupportedLocale.ITALIAN]: 'it',
  [SupportedLocale.KOREAN]: 'ko',
  [SupportedLocale.MALAY]: 'ms',
  [SupportedLocale.DUTCH]: 'nl',
  [SupportedLocale.TURKISH]: 'tr',
  [SupportedLocale.CHINESE]: 'zh-Hans',
  [SupportedLocale.CHINESE_TRADITIONAL]: 'zh-Hant',
};

export const getFullNameFromLanguage = ({
  firstName,
  lastName,
  locale,
}: User): string => {
  return formatPersonalName(
    {
      givenName: firstName,
      familyName: lastName,
    },
    getLocale(locale)
  );
};

export const getInitialsInOrderFromLanguage = (user: User): string => {
  const fullName = getFullNameFromLanguage(user);

  if (fullName.startsWith(user.firstName)) {
    return user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase();
  }

  return user.lastName[0].toUpperCase() + user.firstName[0].toUpperCase();
};

export const getLocalisationLanguage = (locale: SupportedLocaleGQL) => {
  const userLocale = getLocale(locale);

  //Set english as default language and otherwise use locale name provided from the BE
  return userLocale === SupportedLocale.ENGLISH ? 'en' : userLocale;
};
