import { MutableRefObject, useEffect, useRef, KeyboardEvent } from 'react';

const eventName = 'mousedown';

const useOuterClick = <T extends HTMLElement>(
  callback: (e: Event) => void
): MutableRefObject<T | null> => {
  const callbackRef = useRef<(e: Event) => void>();
  const innerRef = useRef<T>(null);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const container = document.body;
    const handleClick = (e: Event) =>
      e.target instanceof Element &&
      !innerRef.current?.contains(e.target) &&
      callbackRef.current?.(e);

    container.addEventListener(eventName, handleClick);

    return () => container.removeEventListener(eventName, handleClick);
  }, []);

  return innerRef;
};

const useOuterClickList = <T extends HTMLElement>(
  callback: (e: Event) => void,
  initialValue: MutableRefObject<T | null>[] = []
): void => {
  const callbackRef = useRef<(e: Event) => void>();
  const innerRefList = useRef<MutableRefObject<T | null>[]>(initialValue);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const container = document.body;
    const handleClick = (e: Event) =>
      !innerRefList.current.some(
        innerRef =>
          e.target instanceof Element && innerRef.current?.contains(e.target)
      ) && callbackRef.current?.(e);

    container.addEventListener(eventName, handleClick);

    return () => container.removeEventListener(eventName, handleClick);
  }, []);
};

const useOuterClickAndEscape = <T extends HTMLElement>(
  callback: (e: Event) => void
): MutableRefObject<T | null> => {
  const callbackRef = useRef<(e: Event) => void>();
  const innerRef = useRef<T>(null);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const container = document.body;
    const handleClick = (e: Event) =>
      e.target instanceof Element &&
      !innerRef.current?.contains(e.target) &&
      callbackRef.current?.(e);
    const handleEscape = (e: Event) => {
      if ((e as unknown as KeyboardEvent<HTMLDivElement>).key === 'Escape') {
        callbackRef.current?.(e);
      }
    };

    container.addEventListener(eventName, handleClick);
    container.addEventListener('keydown', handleEscape);

    return () => {
      container.removeEventListener(eventName, handleClick);
      container.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return innerRef;
};

export { useOuterClickList, useOuterClickAndEscape };
export default useOuterClick;
