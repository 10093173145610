import './TalentReview.scss';
import './TestsListItem.scss';
import Navbar from 'components/organisms/Navbar/Navbar';
import { appSwitchRoutes } from 'constants/routes';
import {
  StageCandidateStatus,
  StageStatus,
  useGetStageActionsQuery,
} from 'generated/graphql';
import { IStagePath } from 'interfaces/routes';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import TalentReviewInfo from '../TalentReviewInfo/TalentReviewInfo';
import TalentReviewList from './TalentReviewList/TalentReviewList';

const TalentReview: FC = () => {
  const { handleMsgType } = useNotification();
  const { stageCandidateId } = useParams() as IStagePath;
  const stageCandidateIdParsed = Number(stageCandidateId);
  const navigate = useNavigate();
  const stageActionsQuery = useGetStageActionsQuery({
    variables: { stageCandidateId: stageCandidateIdParsed },
    onCompleted: data => {
      const stageStatus = data.GetStageActions.stage.status;
      const stageCandidateStatus = data.GetStageActions.stageCandidate.status;

      if (
        stageStatus === StageStatus.Expired ||
        stageStatus === StageStatus.Scheduled ||
        stageCandidateStatus === StageCandidateStatus.Completed
      ) {
        navigate(appSwitchRoutes.stages.getUrl());
      }
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(appSwitchRoutes.stages.getUrl());
    },
    fetchPolicy: 'network-only',
  });

  const [isTalentReviewinfo, setIsTalentReviewInfo] = useState<boolean>(false);

  const handleOnboardingInstruction = (value: boolean) => {
    setIsTalentReviewInfo(value);
  };

  const goToReviewPage = (value: boolean) => {
    setIsTalentReviewInfo(value);
  };

  const stageActions = stageActionsQuery?.data?.GetStageActions;

  return (
    <>
      {isTalentReviewinfo ? (
        <>
          <div className="navbarShadow">
            <Navbar />
          </div>
          <TalentReviewInfo
            companyImage={stageActions?.company?.logoUrl || ''}
            goToReviewPage={goToReviewPage}
          />
        </>
      ) : (
        <>
          <div className="navbarShadow">
            <Navbar />
          </div>
          {stageActions && (
            <TalentReviewList
              stageActionsQuery={stageActionsQuery?.data}
              showOnboardingInstruction={handleOnboardingInstruction}
            />
          )}
        </>
      )}
    </>
  );
};

export default TalentReview;
