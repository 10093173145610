import useMatchMedia from 'hooks/useMatchMedia';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DESKTOP_BREAKPOINT } from '../../constants';
import {
  DropdownMenu,
  MenuSize,
  MenuPosition,
  Tooltip,
} from '@spotted-zebra-uk/ui-components';
export type SkillsSortValue = 'level' | 'type' | 'lastUpdated';

interface ISkillsSort {
  value: SkillsSortValue;
  dropdownWidth?: number;
  disabled?: boolean;
  onChange: (value: SkillsSortValue) => void;
}

const SkillsSort: FC<ISkillsSort> = ({ value, disabled, onChange }) => {
  const { t } = useTranslation();
  const isDesktop = useMatchMedia(`(min-width: ${DESKTOP_BREAKPOINT})`);

  const sortOptions = [
    {
      label: t('skills.sort.level'),
      additionalInfo: t('skills.sort.highToLow'),
      ariaLabel: t('skills.sort.groupAndSortHighToLow'),
      onClick: () => onChange('level'),
    },
    {
      label: t('skills.sort.type'),
      ariaLabel: t('skills.sort.groupByType'),
      onClick: () => onChange('type'),
    },
    {
      label: t('skills.sort.lastUpdated'),
      additionalInfo: t('skills.sort.oldToNew'),
      ariaLabel: t('skills.sort.sortByTime'),
      onClick: () => onChange('lastUpdated'),
    },
  ];

  return (
    <div data-testid="skills-sort" className="skills-sort">
      <Tooltip
        content={t('skills.clickToChangeView')}
        place="top"
        positionStrategy="fixed"
        disabled={disabled || !isDesktop}
        disableAriaLabel
      >
        <DropdownMenu
          options={sortOptions}
          size={MenuSize.SMALL}
          disabled={disabled}
          ariaLabel={t('skills.sort.groupAndSort')}
          align={isDesktop ? MenuPosition.LEFT : MenuPosition.RIGHT}
        />
      </Tooltip>
    </div>
  );
};

export default SkillsSort;
