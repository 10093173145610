import './NavbarWithBackLink.scss';
import BackArrow from 'components/molecules/BackArrow/BackArrow';
import { FC } from 'react';

interface INavbarWithBackLink {
  to: string;
  ariaLabel?: string;
  label?: string;
}

const NavbarWithBackLink: FC<INavbarWithBackLink> = ({
  to,
  ariaLabel,
  label,
}) => {
  return (
    <header>
      <div className="NavbarWithBackLink">
        <BackArrow to={to} aria-label={ariaLabel} label={label} />
      </div>
    </header>
  );
};

export default NavbarWithBackLink;
