import './TalentReviewList.scss';
import Notification from 'components/atoms/Notification/Notification';
import Container from 'components/molecules/Container/Container';
import StageNavbar from 'components/organisms/StageNavbar';
import { GetStageActionsQuery, User } from 'generated/graphql';
import { getFullNameFromLanguage } from 'helpers/locales';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@spotted-zebra-uk/ui-components';
import { ReactComponent as EyeIcon } from './ic_eye_outlined.svg';
import CalibrateCard from './TRCard/CalibrateCard';
import FormCard from './TRCard/FormCard';
import TRCard from './TRCard/TRCard';

interface ITalentReviewList {
  showOnboardingInstruction: (value: boolean) => void;
  stageActionsQuery?: GetStageActionsQuery;
}

const TalentReviewList: FC<ITalentReviewList> = ({
  showOnboardingInstruction,
  stageActionsQuery,
}) => {
  const { t } = useTranslation();
  const handleOnboardingInstruction = () => {
    showOnboardingInstruction(true);
  };
  const navigate = useNavigate();

  const stageActions = stageActionsQuery?.GetStageActions;
  const stage = stageActions?.stage;
  const company = stageActions?.company;

  const formActions = stageActions?.actions.filter(
    action => action.__typename === 'StageActionFormModel'
  );

  if (stage && company) {
    const stageCandidateId = stageActions.stageCandidate.id;
    const doneFor = stageActions.doneForUser.id;
    const doneBy = stageActions.doneByUser.id;

    if (!formActions?.length) {
      return (
        <div className="TalentReviewList__NotificationWrapper">
          <Notification
            notification={{
              icon: 'Warning',
              color: 'Purple',
              message:
                'Currently we are facing technical issue our team is working on it. ',
            }}
          />
          <div className="notification-action">
            <Button onClick={() => navigate(-1)}>{'Go Back'}</Button>
          </div>
        </div>
      );
    }

    return (
      <>
        <Container className="StagePresentationalContainer TalentReviewList">
          <StageNavbar
            displayLogout={false}
            endTime={stage.endTime}
            isMainScreen={true}
          />
          <div className="TRList">
            <img src={company.logoUrl || ''} alt={t('alt.logo')} height={33} />
            <div className="TRBrand-details">
              <p className="TRBrand-details__brandType">Talent Review</p>
              <p className="TRBrand-details__name">
                {getFullNameFromLanguage(stageActions.doneForUser as User)}
              </p>
            </div>
            <div className="TRList__List">
              {stageActions?.actions?.map((action, index) => {
                if (action.__typename === 'StageActionTalentReviewTestModel') {
                  const test = action.testCandidate.test;
                  return (
                    <TRCard
                      key={`talent-review-test-${index}`}
                      timeRequirement={action.estimatedTotalTime as number}
                      testSubType={test?.subType}
                      testStatus={action.testCandidate?.status}
                      testCandidateId={action.testCandidate.id}
                      stageCandidateId={stageCandidateId}
                      doneFor={doneFor}
                      doneBy={doneBy}
                      projectId={stageActions.project.id}
                    />
                  );
                }

                if (action.__typename === 'StageActionFormModel') {
                  return (
                    <FormCard
                      key={`${action.formCandidate.form.id}${index}`}
                      testStatus={action.formCandidate.status}
                      stageId={stage.id}
                      companyId={company.id}
                      stageCandidateId={stageCandidateId}
                    />
                  );
                }

                if (action.__typename === 'StageActionCalibrationModel') {
                  return (
                    <CalibrateCard
                      key={`calibrate-card-${index}`}
                      stageCandidateId={stageCandidateId}
                      availabilityStatus={action.availability.status}
                      doneFor={doneFor}
                      doneBy={doneBy}
                      projectId={stageActions.project.id}
                    />
                  );
                }

                return null;
              })}
            </div>
            <div
              className="TRList__viewInstruction"
              onClick={handleOnboardingInstruction}
            >
              <EyeIcon />
              <span>{t('common.viewInstructions')}</span>
            </div>
          </div>
        </Container>
      </>
    );
  }
  return null;
};

export default TalentReviewList;
