import './CandidateInfo.scss';
import { User, useUserQuery } from 'generated/graphql';
import {
  Avatar,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { FC } from 'react';
import {
  getFullNameFromLanguage,
  getInitialsInOrderFromLanguage,
} from 'helpers/locales';

const CandidateInfo: FC = () => {
  const { handleMsgType } = useNotification();

  const { data } = useUserQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
  });

  if (!data) {
    return <div className="AppBar__Left" />;
  }

  const user = data.User as User;

  return (
    <div className="CandidateInfo">
      <Avatar
        user={{
          initials: getInitialsInOrderFromLanguage(user),
          imageUrl: user.avatarUrl || undefined,
        }}
        isInteractive={false}
        className="CandidateInfo__Avatar"
      />
      <div
        className="CandidateInfo__CandidateName"
        data-testid="navbar__candidate-name"
      >
        {getFullNameFromLanguage(user)}
      </div>
    </div>
  );
};

export default CandidateInfo;
