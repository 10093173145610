import { StageCardStatus } from '../enums/stageCardStatus.enum';
import { StageCandidateStatus, StageStatus } from '../generated/graphql';

export const parseStageStatus = (
  stageStatus?: StageStatus,
  stageCandidateStatus?: StageCandidateStatus
): StageCardStatus => {
  if (stageCandidateStatus === StageCandidateStatus.Completed)
    return StageCardStatus.COMPLETED;

  if (stageCandidateStatus === StageCandidateStatus.SignedOff)
    return StageCardStatus.SIGNED_OFF;

  if (stageStatus === StageStatus.Active) {
    return StageCardStatus.ACTIVE;
  }

  if (stageStatus === StageStatus.Expired) {
    return StageCardStatus.EXPIRED;
  }

  if (stageStatus === StageStatus.Scheduled) {
    return StageCardStatus.SCHEDULED;
  }

  return StageCardStatus.ACTIVE;
};
