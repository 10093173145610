import './styles.scss';
import { IconType } from 'components/atoms/Icon';
import Container from 'components/molecules/Container/Container';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import { appSwitchRoutes, stageSwitchRoutes } from 'constants/routes';
import {
  RssAssessmentCandidateStatus,
  SkillsProfileOpenOneDocument,
  TechnicalSkillSelfAssessmentProficiencyLevel,
  useResultCreateManyTechnicalSkillsProfileFromScMutation,
  useRssAssessmentCandidateOpenOneLazyQuery,
  useRssAssessmentCandidateSubmitOneMutation,
} from 'generated/graphql';
import { isMobile } from 'helpers/deviceDetect';
import { barChartValuesToTechnicalSkillProficiencyLevelMap } from 'helpers/skillsProficiency';
import { IRoleSpecificSkillAssessmentPath } from 'interfaces/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  parseAssessmentCandidateGradeBands,
  parseAssessmentCandidateSkillsWithResult,
} from './helpers';
import RoleSpecificSkillsAssessmentDesktop from './RoleSpecificSkillsAssessmentDesktop';
import RoleSpecificSkillsAssessmentMobile from './RoleSpecificSkillsAssessmentMobile';
import { TSkillWithAnswer } from './types';

const RoleSpecificTechnicalSkillsAssessment: FC = () => {
  const { t } = useTranslation();
  const HEADING_TITLE = t('common.technicalSkills');
  const HEADING_ICON: IconType = 'cog';
  const HEADING_DESCRIPTION = t('skills.technicalSkillsDescr');

  const { handleMsgType } = useNotification();
  const navigate = useNavigate();
  const { rssAssessmentCandidateId, stageCandidateId } =
    useParams() as IRoleSpecificSkillAssessmentPath;
  const parsedRssAssessmentCandidateId = Number(rssAssessmentCandidateId);
  const parsedStageCandidateId = Number(stageCandidateId);
  const [
    rssAssessmentCandidateOpenOneQuery,
    rssAssessmentCandidateOpenOneQueryResponse,
  ] = useRssAssessmentCandidateOpenOneLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parsedRssAssessmentCandidateId,
    },
  });
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!rssAssessmentCandidateId || !stageCandidateId) {
        navigate(appSwitchRoutes.stages.getUrl());
      }

      try {
        const { data } = await rssAssessmentCandidateOpenOneQuery();

        if (
          data?.RssAssessmentCandidateOpenOne.rssAssessmentCandidate.status ===
            RssAssessmentCandidateStatus.Submitted &&
          stageCandidateId
        ) {
          return navigate(stageSwitchRoutes.stage.getUrl(stageCandidateId));
        }

        setLoading(false);
      } catch (_error) {
        handleMsgType({
          type: TNotification.error,
          title: 'Oops, some error occurred!',
        });
        if (stageCandidateId) {
          navigate(appSwitchRoutes.stage.getUrl(stageCandidateId));
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [rssAssessmentCandidateSubmitOneMutation] =
    useRssAssessmentCandidateSubmitOneMutation({
      variables: {
        id: parsedRssAssessmentCandidateId,
      },
    });
  const [resultCreateManyTechnicalSkillsProfileFromScMutation] =
    useResultCreateManyTechnicalSkillsProfileFromScMutation({
      refetchQueries: [SkillsProfileOpenOneDocument],
    });

  const submit = async (skillsWithAnswers: TSkillWithAnswer[]) => {
    const allSkillsAnswered = !skillsWithAnswers.some(
      skillWithAnswer => !skillWithAnswer.answerValue
    );

    if (
      !rssAssessmentCandidateOpenOneQueryResponse.data ||
      !allSkillsAnswered ||
      !stageCandidateId
    ) {
      return;
    }

    setSubmitting(true);
    try {
      const skillsScores = skillsWithAnswers.map(({ answerValue, id }) => {
        return {
          score: {
            technicalProficiency: answerValue
              ? barChartValuesToTechnicalSkillProficiencyLevelMap[answerValue]
              : TechnicalSkillSelfAssessmentProficiencyLevel.NoKnowledge,
          },
          technicalSkillId: id,
          stageCandidateId: parsedStageCandidateId,
        };
      });

      await resultCreateManyTechnicalSkillsProfileFromScMutation({
        variables: {
          args: skillsScores,
        },
      });
      await rssAssessmentCandidateSubmitOneMutation();
      await rssAssessmentCandidateOpenOneQuery();
      handleMsgType({
        type: TNotification.success,
        title: t('assessment.technicalSkills.successNotification'),
      });

      navigate(
        stageSwitchRoutes.skillsSelfAssessmentThankYou.getUrl(stageCandidateId)
      );
    } catch (_error) {
      handleMsgType({
        type: TNotification.error,
        title: t('assessment.technicalSkills.errorNotification'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const gradeBands = parseAssessmentCandidateGradeBands(
    rssAssessmentCandidateOpenOneQueryResponse.data
  );
  const initialSkillsWithAnswers = parseAssessmentCandidateSkillsWithResult(
    rssAssessmentCandidateOpenOneQueryResponse.data
  );

  return (
    <>
      <HelmetAndPageAnnouncer
        pageTitle={t('assessment.technicalSkills.name')}
      />
      {!rssAssessmentCandidateOpenOneQueryResponse.data || loading ? (
        <Container className="page-loader-wrapper">
          <Loader variant="bubbles" />
        </Container>
      ) : isMobile() ? (
        <RoleSpecificSkillsAssessmentMobile
          headingDescription={HEADING_DESCRIPTION}
          headingIcon={HEADING_ICON}
          headingTitle={HEADING_TITLE}
          onSubmit={submit}
          initialSkillsWithAnswers={initialSkillsWithAnswers}
          gradeBands={gradeBands}
          loading={submitting}
          hasMarkAllButton
          skillName={t('common.technicalSkills')}
        />
      ) : (
        <RoleSpecificSkillsAssessmentDesktop
          headingDescription={HEADING_DESCRIPTION}
          headingIcon={HEADING_ICON}
          headingTitle={HEADING_TITLE}
          onSubmit={submit}
          initialSkillsWithAnswers={initialSkillsWithAnswers}
          gradeBands={gradeBands}
          loading={submitting}
          hasMarkAllButton
          skillName={t('common.technicalSkills')}
        />
      )}
    </>
  );
};

export default RoleSpecificTechnicalSkillsAssessment;
