import './LogoutButton.scss';
import { ReactComponent as ExitIcon } from 'assets/icons/Exit.svg';
import { LANGUAGE_LOCAL_STORAGE_KEY } from 'components/organisms/LocalizationWrapper/LocalizationWrapper';
import { AUTH_LOGIN_ROUTE } from 'constants/authentication';
import { useDeauthenticateMutation } from 'generated/graphql';
import { authCleanup, getAccessToken } from 'helpers/auth';
import i18n from 'helpers/i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

const LogoutButton: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleMsgType } = useNotification();

  const [deauthenticate] = useDeauthenticateMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
  });

  const handleLogout = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      deauthenticate({
        variables: {
          accessToken,
        },
      });
    }
    authCleanup();
    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, i18n.language);
    let redirectFrom = localStorage.getItem('redirectFrom');
    let onCompleted = localStorage.getItem('onCompleted');
    if (redirectFrom && onCompleted) {
      localStorage.removeItem('redirectFrom');
      localStorage.removeItem('onCompleted');
      window.open(`${redirectFrom}?logout=true`, '_self');
    } else {
      navigate(AUTH_LOGIN_ROUTE);
    }
  };

  return (
    <Button
      size="small"
      variant="text"
      className="logout-button"
      onClick={handleLogout}
      aria-label={t('common.signOut')}
    >
      <ExitIcon aria-hidden focusable="false" />
    </Button>
  );
};

export default LogoutButton;
