import './styles.scss';
import Logout from 'components/organisms/LogoutButton/LogoutButton';
import { isAfter, isBefore } from 'helpers/time';
import { Maybe } from 'interfaces/maybe';
import { FC } from 'react';
import ExpirationNotification from './ExpirationNotification';
import StageNavbarBackButton from './StageNavbarBackButton';

interface IStageNavbar {
  endTime?: Maybe<Date>;
  displayLogout?: boolean;
  isForm?: boolean;
  isMainScreen?: boolean;
}

const StageNavbar: FC<IStageNavbar> = ({
  endTime,
  displayLogout = true,
  isForm,
  isMainScreen,
}) => {
  const showDeadlinePassedString = endTime && isBefore(endTime, new Date());
  const showExpirationNotification = endTime && isAfter(endTime, new Date());

  return (
    <div className="stage-navbar">
      <StageNavbarBackButton isForm={isForm} isMainScreen={isMainScreen} />
      {showDeadlinePassedString && (
        <div className="stage-navbar__note">The deadline has passed</div>
      )}
      {showExpirationNotification && (
        <ExpirationNotification endTime={endTime} />
      )}
      {displayLogout && <Logout />}
    </div>
  );
};

export default StageNavbar;
