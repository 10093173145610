import { FC } from 'react';
import { ReactSVG } from 'react-svg';

interface ISVGRenderer {
  src: string;
  alt: string;
  size?: {
    height: number;
    width: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loading?: () => any;
}

const SVGRenderer: FC<ISVGRenderer> = ({ src, alt, size, loading }) => {
  return (
    <ReactSVG
      className="SVGRenderer"
      src={src}
      alt={alt}
      beforeInjection={svg => {
        if (size) {
          svg.setAttribute('width', `${size.width}`);
          svg.setAttribute('height', `${size.height}`);
        }
      }}
      loading={loading}
    />
  );
};

export default SVGRenderer;
