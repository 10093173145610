import Loader from 'components/atoms/Loader/Loader';
import { appSwitchRoutes } from 'constants/routes';
import {
  useGetStageActionsQuery,
  useGetStageCardsQuery,
  useStartTpTestMutation,
} from 'generated/graphql';
import { FC, useEffect, useState } from 'react';
import {
  isAndroid,
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isMobile,
  isSafari,
  isTablet,
  isWinPhone,
} from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import TPTest from '../TPTest/TPTest';

interface ITPTestContainer {
  testCandidateId: number;
  stageCandidateId: number;
}

const TPTestContainer: FC<ITPTestContainer> = ({
  testCandidateId,
  stageCandidateId,
}) => {
  const navigate = useNavigate();
  const { handleMsgType } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const [startTpTestMutation, startTpTestMutationResponse] =
    useStartTpTestMutation({
      variables: {
        testCandidateId,
      },
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      onCompleted: data => {
        if (
          data &&
          (isMobile ||
            isIPad13 ||
            isIPhone13 ||
            isTablet ||
            isIOS ||
            isIOS13 ||
            isAndroid ||
            isWinPhone ||
            isSafari)
        ) {
          window.location.replace(data.StartTpTest.url);
        }
      },
    });

  useEffect(() => {
    startTpTestMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stageActionsResponse = useGetStageActionsQuery({
    variables: {
      stageCandidateId,
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(appSwitchRoutes.stages.getUrl());
    },
  });

  const stageCardsQueryResponse = useGetStageCardsQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(appSwitchRoutes.stages.getUrl());
    },
  });

  const onFinishTest = async () => {
    setIsLoading(true);
    try {
      /**
       * After test stage cards query and test cards query need refetch
       * to check if test is finished correctly and update cache.
       */
      await stageCardsQueryResponse.refetch();
      await stageActionsResponse.refetch();
      navigate(appSwitchRoutes.tpCallback.getUrl(stageCandidateId));
    } catch (error) {
      navigate(appSwitchRoutes.stages.getUrl());
    }
  };

  if (isLoading || startTpTestMutationResponse.loading) {
    return <Loader isOverlay />;
  }

  if (startTpTestMutationResponse?.data) {
    return (
      <TPTest
        tpTestUrl={startTpTestMutationResponse.data.StartTpTest.url}
        id={testCandidateId}
        onFinishTest={onFinishTest}
      />
    );
  }
  return null;
};

export default TPTestContainer;
