import { useEffect } from 'react';

export const useDisableCopyAndRightClick = () => {
  const enableCheatingPrevention = () => {
    return process.env.REACT_APP_ENABLE_CHEATING_PREVENTION === 'true';
  };

  useEffect(() => {
    const handleContextmenu = (e: Event) => {
      e.preventDefault();
    };
    const handleSelectStart = (e: Event) => {
      e.preventDefault();
    };

    if (enableCheatingPrevention()) {
      document.addEventListener('contextmenu', handleContextmenu);
      document.addEventListener('selectstart', handleSelectStart);
      document.body.classList.add('unselectable');
    }

    return () => {
      if (enableCheatingPrevention()) {
        document.removeEventListener('contextmenu', handleContextmenu);
        document.removeEventListener('selectstart', handleSelectStart);
        document.body.classList.remove('unselectable');
      }
    };
  }, []);
};
