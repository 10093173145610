import { IIndirectInvitationData } from 'interfaces/indirectInvitation';

export const parseIndirectInvitationUrl = (
  urlSearch: string
): IIndirectInvitationData | undefined => {
  const queryParams = new URLSearchParams(urlSearch);

  const urlProject = queryParams.get('project');
  const urlStage = queryParams.get('stageId');
  const urlHms = queryParams.get('hms');

  if (!urlProject || !urlStage) {
    return undefined;
  }

  return {
    projectId: parseInt(urlProject, 10),
    stageId: parseInt(urlStage, 10),
    hiringManagers: urlHms
      ? urlHms.split(',').map(hm => ({ externalId: hm }))
      : [],
  };
};
