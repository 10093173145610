import { ReactComponent as ArrowIcon } from 'assets/icons/LeftArrow.svg';
import BackArrow from 'components/molecules/BackArrow/BackArrow';
import { appSwitchRoutes } from 'constants/routes';
import { IStageActionFormPath } from 'interfaces/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

interface IStageNavbarBackButton {
  isForm?: boolean;
  isMainScreen?: boolean;
}

const getBackButtonUrl = () => {
  const redirectFrom = localStorage.getItem('redirectFrom');
  if (redirectFrom) {
    return redirectFrom;
  }
  return appSwitchRoutes.stages.getUrl();
};

const StageNavbarBackButton: FC<IStageNavbarBackButton> = ({
  isForm,
  isMainScreen,
}) => {
  const { stageCandidateId } = useParams() as IStageActionFormPath;
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (isForm) {
    return (
      <>
        {!!stageCandidateId ? (
          <BackArrow
            to={appSwitchRoutes.stage.getUrl(stageCandidateId || 0)}
            isMainScreen={isMainScreen}
            aria-label={t('assessment.returnToAllAssessmentActions')}
            label={t('assessment.actions')}
          />
        ) : (
          //TODO - remove this after TalentReview and TalentReviewForm are updated to use same back link
          <div
            onClick={() => {
              navigate(-1);
            }}
            style={{ backgroundColor: 'transparent', cursor: 'pointer' }}
          >
            <ArrowIcon />
          </div>
        )}
      </>
    );
  } else {
    return (
      <BackArrow
        to={!isForm ? getBackButtonUrl() : appSwitchRoutes.stages.getUrl()}
        isMainScreen={isMainScreen}
      />
    );
  }
};

export default StageNavbarBackButton;
