import './SkillsHeader.scss';
import clsx from 'clsx';
import Icon from 'components/atoms/Icon';
import useIsElementSticky from 'hooks/useIsElementSticky';
import useMatchMedia from 'hooks/useMatchMedia';
import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotted-zebra-uk/ui-components';
import { DESKTOP_BREAKPOINT } from '../constants';
import NoKnowledgeToggle from './NoKnowledgeToggle/NoKnowledgeToggle';
import SkillsSearch, { SearchValue } from './SkillsSearch/SkillsSearch';
import SkillsSort, { SkillsSortValue } from './SkillsSort/SkillsSort';

interface ISkillsHeader {
  sortValue: SkillsSortValue;
  sortDisabled?: boolean;
  noKnowledgeSkillsToggleValue: boolean;
  noKnowledgeSkillsToggleDisabled?: boolean;
  onNoKnowledgeSkillsToggle: (value: boolean) => void;
  onSearchChange?: (value: SearchValue) => void;
  onSortChange: (value: SkillsSortValue) => void;
}

const SkillsHeader: FC<ISkillsHeader> = ({
  sortValue,
  sortDisabled,
  noKnowledgeSkillsToggleValue,
  noKnowledgeSkillsToggleDisabled,
  onNoKnowledgeSkillsToggle,
  onSearchChange,
  onSortChange,
}) => {
  const { t } = useTranslation();
  const isDesktop = useMatchMedia(`(min-width: ${DESKTOP_BREAKPOINT})`);
  const header = useRef<HTMLDivElement>(null);
  const isHeaderSticky = useIsElementSticky(header.current);

  const [showSearch, setShowSearch] = useState(false);
  const toggleSearchHandler = () => setShowSearch(!showSearch);
  const onSearchExit = useCallback(() => setShowSearch(false), []);
  const onSearchChangeHandler = useCallback(
      //@ts-ignore
    val => {
      setShowSearch(false);
      onSearchChange?.(val.value);
    },
    [onSearchChange]
  );

  return (
    <div
      className={clsx(
        'personal-skills-profile-header',
        isHeaderSticky && 'personal-skills-profile-header--sticky'
      )}
      ref={header}
      data-testid="skills-header"
    >
      <div className="personal-skills-profile-header__top">
        <div
          className="personal-skills-profile-header__controls"
          data-testid="skills-header-controls"
        >
          {t('common.mySkills')}
          <SkillsSort
            value={sortValue}
            disabled={sortDisabled}
            onChange={onSortChange}
          />
          {isDesktop && (
            <NoKnowledgeToggle
              value={noKnowledgeSkillsToggleValue}
              disabled={noKnowledgeSkillsToggleDisabled}
              onToggle={onNoKnowledgeSkillsToggle}
            />
          )}
        </div>
        {showSearch && isDesktop ? (
          <SkillsSearch
            onSearchExit={onSearchExit}
            onChange={onSearchChangeHandler}
          />
        ) : (
          <div>
            {isDesktop ? (
              <Button
                size="medium"
                className="personal-skills-profile-header__add-btn"
                data-testid="show-search-btn"
                onClick={toggleSearchHandler}
              >
                <Icon
                  icon="plus"
                  className="personal-skills-profile-header__add-btn-icon"
                />{' '}
                {t('skills.addSkill')}
              </Button>
            ) : (
              <Button
                className="personal-skills-profile-header__add-btn"
                data-testid="show-search-btn"
                onClick={toggleSearchHandler}
              >
                <Icon
                  icon="plus"
                  className="personal-skills-profile-header__add-btn-icon"
                />
              </Button>
            )}
          </div>
        )}
      </div>
      {!isDesktop && (
        <div className="personal-skills-profile-header__bottom">
          {showSearch ? (
            <SkillsSearch
              onSearchExit={onSearchExit}
              onChange={onSearchChangeHandler}
            />
          ) : (
            <NoKnowledgeToggle
              value={noKnowledgeSkillsToggleValue}
              disabled={noKnowledgeSkillsToggleDisabled}
              onToggle={onNoKnowledgeSkillsToggle}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SkillsHeader;
