import {
  GetStageActionsQuery,
  StageActionAvailabilityStatus,
  StageCandidateStatus,
} from 'generated/graphql';
import { FC } from 'react';
import FinishedAssessmentContent from './FinishedAssessmentContent';
import NextActionContent from './NextActionContent';

interface IStageActionThankYouPanel {
  stageActionsData: GetStageActionsQuery;
  stageCandidateId: number;
}

const StageActionThankYouPanel: FC<IStageActionThankYouPanel> = ({
  stageActionsData,
  stageCandidateId,
}) => {
  const { stageCandidate, actions, company } = stageActionsData.GetStageActions;
  const numberOfCompletedActions = actions.filter(
    action =>
      action.availability.status === StageActionAvailabilityStatus.Completed
  ).length;
  const allActionsCompleted = numberOfCompletedActions === actions.length;
  const numberOfNotCompletedActions = actions.length - numberOfCompletedActions;
  if (
    stageCandidate.status === StageCandidateStatus.Completed ||
    allActionsCompleted
  ) {
    return (
      <FinishedAssessmentContent
        companyName={company.name}
        stageCandidateId={stageCandidateId}
      />
    );
  }

  return (
    <NextActionContent
      numberOfNotCompletedActions={numberOfNotCompletedActions}
      stageCandidateId={stageCandidateId}
    />
  );
};

export default StageActionThankYouPanel;
