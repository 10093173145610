import './styles.scss';
import clsx from 'clsx';
import Icon from 'components/atoms/Icon';
import { FC } from 'react';

interface IPreviousArrow {
  onClick?: () => void;
  currentSlide?: number;
}

const PreviousArrow: FC<IPreviousArrow> = ({ onClick, currentSlide }) => (
  <div
    onClick={onClick}
    className={clsx(
      'sz-carousel-arrow-icon',
      'sz-carousel-arrow-icon_previous',
      {
        'sz-carousel-arrow-icon_faded': !currentSlide,
      }
    )}
  >
    <Icon icon="chevron" className="sz-carousel-arrow-icon__icon" />
  </div>
);

export default PreviousArrow;
