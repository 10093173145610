import { FC } from 'react';
import EquailityAndDiversityContainer from './EquailityAndDiversityContainer/EquailityAndDiversityContainer';

interface IEquailityAndDiversityContent {
  stageCandidateId: number;
  onEqualityStatementButtonClick: () => void;
  onCloseModal: () => void;
}

const EquailityAndDiversityContent: FC<IEquailityAndDiversityContent> = ({
  stageCandidateId,
  onEqualityStatementButtonClick,
}) => {
  return (
    <EquailityAndDiversityContainer
      stageCandidateId={stageCandidateId}
      onEqualityStatementButtonClick={onEqualityStatementButtonClick}
    />
  );
};

export default EquailityAndDiversityContent;
