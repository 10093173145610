import './InductiveTestQuestionAnswerPreview.scss';
import SVGRenderer from 'components/molecules/SVGRenderer/SVGRenderer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IInductiveTestQuestionAnswerPreview {
  previewImage: string | undefined;
  index: number;
  width?: number;
  height?: number;
}

const InductiveTestQuestionAnswerPreview: FC<IInductiveTestQuestionAnswerPreview> =
  ({ previewImage, index, width = 100, height = 100 }) => {
    const { t } = useTranslation();
    const size = {
      width,
      height,
    };

    return (
      <div className="InductiveTestQuestionAnswerPreview">
        <div
          className={`InductiveTestQuestionAnswerPreview__Main${
            !previewImage
              ? ' InductiveTestQuestionAnswerPreview__Main--Empty'
              : ''
          }`}
          style={{ ...size }}
        >
          {!previewImage ? (
            '?'
          ) : (
            <SVGRenderer
              src={previewImage}
              alt={t('alt.inductiveTestQAPreview')}
              size={{ height: size.height - 10, width: size.width - 10 }}
            />
          )}
        </div>
        <p className="InductiveTestQuestionAnswerPreview__Index">{index + 1}</p>
      </div>
    );
  };

export default InductiveTestQuestionAnswerPreview;
