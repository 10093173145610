import Container from 'components/molecules/Container/Container';
import StageActionThankYouPanel from 'components/organisms/StageActionThankYouPanel/StageActionThankYouPanel';
import { appSwitchRoutes } from 'constants/routes';
import { useGetStageActionsQuery } from 'generated/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface ICodingAssessmentThankYou {}

const CodingAssessmentThankYou: FC<ICodingAssessmentThankYou> = () => {
  const { handleMsgType } = useNotification();
  const stageCandidateId = localStorage.getItem('stageCandidateId');
  const navigate = useNavigate();
  const stagesUrl = appSwitchRoutes.stages.getUrl();

  const stageActionsQueryResponse = useGetStageActionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      stageCandidateId: Number(stageCandidateId),
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(stagesUrl);
    },
  });

  if (!stageActionsQueryResponse.data) {
    return (
      <Container className="page-loader-wrapper">
        <Loader variant="bubbles" />
      </Container>
    );
  }

  return (
    <StageActionThankYouPanel
      stageActionsData={stageActionsQueryResponse.data}
      stageCandidateId={Number(stageCandidateId)}
    />
  );
};

export default CodingAssessmentThankYou;
