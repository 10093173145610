import './ColoredText.scss';
import { FC } from 'react';

interface IColoredText {
  text: string;
  color?: 'Purple' | 'Gray' | 'Blue';
  className?: string;
}

const ColoredText: FC<IColoredText> = ({ className, text, color = 'Blue' }) => {
  const wrapperClassName = `ColoredText ColoredText--${color}${
    className ? ` ${className}` : ''
  }`;

  return <div className={wrapperClassName}>{text}</div>;
};

export default ColoredText;
