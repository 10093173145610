import './ProfileMenu.scss';
import LogoutButton from 'components/organisms/LogoutButton/LogoutButton';
import { User } from 'generated/graphql';
import { getInitialsInOrderFromLanguage } from 'helpers/locales';
import { FC } from 'react';
import { Avatar } from '@spotted-zebra-uk/ui-components';

interface IProfileMenu {
  user: User;
}

const ProfileMenu: FC<IProfileMenu> = ({ user }) => {
  return (
    <div className="profile-menu-container">
      <div className="profile-name-container">
        <Avatar
          user={{
            initials: getInitialsInOrderFromLanguage(user),
            imageUrl: user.avatarUrl as string,
          }}
          isInteractive={false}
        />

        <label className="profile-name-label">
          {`${user.firstName} ${user.lastName}`}
        </label>
      </div>
      <div>
        <LogoutButton />
      </div>
    </div>
  );
};

export default ProfileMenu;
