import './StageCardCompleted.scss';
import Gauge from 'components/atoms/Gauge/Gauge';
import Icon from 'components/atoms/Icon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IStageCardCompleted {
  candidateReportId?: string;
  score?: number;
  scoreString?: string;
  projectName: string;
  stageName: string;
  companyLogoUrl?: string | undefined;
  companyName: string;
  renderCandidateReport?: boolean;
}

const StageCardCompleted: FC<IStageCardCompleted> = ({
  candidateReportId,
  score,
  scoreString,
  stageName,
  projectName,
  companyLogoUrl,
  companyName,
  renderCandidateReport = true,
}) => {
  const { t } = useTranslation();
  const link = `/candidate-report/${candidateReportId}`;
  const isReportVisible = Boolean(renderCandidateReport && candidateReportId);
  const linkAriaLabel = t(
    'stages.stageCardCompleted.completedAssessmentStage',
    { companyName, projectName }
  );
  return (
    <Link
      to={!isReportVisible ? '#' : link}
      aria-label={linkAriaLabel}
      aria-disabled
      className="StageCardCompleted"
    >
      <div className="StageCardCompleted__Heading">
        {companyLogoUrl ? (
          <img
            className="StageCardCompleted__Heading__CompanyLogo"
            src={companyLogoUrl}
            alt={t('alt.companyLogo', { companyName })}
          />
        ) : (
          <span className="StageCardCompleted__Heading__CompanyName">
            {companyName}
          </span>
        )}
      </div>
      {isReportVisible ? (
        <Gauge score={score || 0} grade={scoreString} />
      ) : (
        <div className="StageCardCompleted__Center">
          <div className="StageCardCompleted__GreenBackgroundCircle">
            <Icon
              icon="green_check"
              className={'StageCardCompleted__status-icon'}
            />
          </div>
        </div>
      )}
      <p className="StageCardCompleted__Name">{projectName}</p>
      <p className="StageCardCompleted__Stage">{stageName}</p>
    </Link>
  );
};

export default StageCardCompleted;
