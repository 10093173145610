import { AUTH_CODE } from 'constants/authentication';
import { useExchangeAuthCodeMutation } from 'generated/graphql';
import { logoutCleanup, setAccessToken, setRefreshToken } from 'helpers/auth';
import { FC, useEffect, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

type TAuthenticationWrapper = {
  children: React.ReactNode;
};

export const AuthenticationWrapper: FC<TAuthenticationWrapper> = ({
  children,
}) => {
  const { handleMsgType } = useNotification();
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const authCode = queryParams.get(AUTH_CODE);

  const [exchangeAuthCode] = useExchangeAuthCodeMutation({
    variables: {
      authCode: authCode || '',
    },
    onCompleted: data => {
      const { accessToken, refreshToken } = data.ExchangeAuthCode;

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      setIsLoading(false);
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
      logoutCleanup();
      window.location.href = '/auth/login';
      alert('err');
    },
  });

  useEffect(() => {
    if (authCode) {
      exchangeAuthCode();
      return;
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode]);

  return <>{isLoading ? null : children}</>;
};
