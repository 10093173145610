import './DesktopPersonalityTestQuestion.scss';
import { PersonalityTestsAnswers } from 'enums/tests';
import { PersonalityOptionModel } from 'generated/graphql';
import { FC, useEffect, useState } from 'react';
import PersonalityTestSlider from '../../../components/PersonalityTestSlider/PersonalityTestSlider';

interface IDesktopPersonalityTestQuestion {
  index: number;
  question: {
    options: PersonalityOptionModel[];
    subId: string;
    text: string;
  };
  onAnswer: (value: number, questionId: string, answerSubId: string) => void;
  onAnswerChange: (
    value: number,
    questionId: string,
    answerSubId: string
  ) => void;
  currentPage: number;
}

const DesktopPersonalityTestQuestion: FC<IDesktopPersonalityTestQuestion> = ({
  question,
  onAnswer,
  onAnswerChange,
  index,
  currentPage,
}) => {
  const [answerValue, setAnswerValue] = useState<number>(
    PersonalityTestsAnswers.NEUTRAL
  );

  const handleAnswerChange = (value: number | undefined) => {
    if (value !== undefined) {
      const optionSubId = question.options[value].subId;
      setAnswerValue(value);
      onAnswerChange(index, question.subId, optionSubId);
    }
  };

  useEffect(() => {
    if (answerValue === PersonalityTestsAnswers.NEUTRAL) {
      const optionSubId =
        question.options[PersonalityTestsAnswers.NEUTRAL].subId;
      onAnswer(index, question.subId, optionSubId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="DesktopPersonalityTestQuestion">
      <p className="DesktopPersonalityTestQuestion__Number">
        {currentPage + index + 1}
      </p>
      <p className="DesktopPersonalityTestQuestion__Text">{question.text}</p>
      <div className="DesktopPersonalityTestQuestion__SliderWrapper">
        <PersonalityTestSlider
          value={answerValue}
          onChange={handleAnswerChange}
          id={`desktop-personality-test-question-slider-${question.subId}`}
          ariaLabel={question.text}
          options={question.options}
        />
      </div>
    </div>
  );
};

export default DesktopPersonalityTestQuestion;
