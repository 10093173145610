import './CandidateInformationForm.scss';
import FormBuilderField from 'components/molecules/FormBuilderField/FormBuilderField';
import { FieldType } from 'generated/graphql';
import { useForm } from 'hooks/useForm';
import { TFormFieldValue } from 'interfaces/forms/formGQL';
import { TRespondantFormField } from 'interfaces/forms/respondantForm';
import _ from 'lodash';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotted-zebra-uk/ui-components';

interface ICandidateInformationForm {
  fields: TRespondantFormField[];
  isSkipButtonVisible?: boolean;
  onSubmit: (
    values: {
      [key in string]: TFormFieldValue;
    },
    fields: TRespondantFormField[]
  ) => void;
  onSkipButtonClick?: () => void;
}

const CandidateInformationForm: FC<ICandidateInformationForm> = ({
  onSubmit,
  onSkipButtonClick,
  isSkipButtonVisible,
  fields,
}) => {
  const { t } = useTranslation();
  const requiredFieldMessage = `^${t('common.thisFieldIsRequired')}`;

  const initialValues = useMemo(
    () =>
      fields.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr.initialValue,
        }),
        {}
      ),
    [fields]
  );

  const validationShema = useMemo(
    () =>
      fields.reduce((acc, curr) => {
        if (curr.isOptional) {
          return acc;
        } else {
          if (curr.type === FieldType.SingleSelectField) {
            return {
              ...acc,
              [curr.id]: {
                presence: {
                  allowEmpty: false,
                  message: requiredFieldMessage,
                },
              },
            };
          }

          if (curr.type === FieldType.DateField) {
            return {
              ...acc,
              [curr.id]: {
                length: { minimum: 1, message: requiredFieldMessage },
              },
            };
          }

          return {
            ...acc,
            [curr.id]: {
              length: { minimum: 1, message: requiredFieldMessage },
            },
          };
        }
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  );

  const [values, errors, handleChange, handleSubmit] = useForm({
    options: {
      initialValues,
      validationShema,
    },
    onSubmit: values => {
      onSubmit(values, fields);
    },
  });

  const handleSkipButtonClick = () => {
    if (onSkipButtonClick) {
      onSkipButtonClick();
    }
  };

  const formKey = 'cif';
  return (
    <form onSubmit={handleSubmit} className="CandidateInformationForm">
      <div className="CandidateInformationForm__Fields">
        {fields.map(field => (
          <FormBuilderField
            key={field.id}
            id={`${formKey}-${field.id}`}
            name={field.id}
            type={field.type}
            label={field.label}
            options={field.options}
            value={values[field.id]}
            error={errors && errors[field.id]}
            onChange={handleChange}
            autoComplete="off"
            className="ci-form"
          />
        ))}
      </div>
      <div className="CandidateInformationForm__Actions">
        <Button type="submit" size="large" fullWidth>
          {_.capitalize(t('dialogue.submitResponses'))}
        </Button>
        {isSkipButtonVisible && (
          <Button
            variant="secondary"
            onClick={handleSkipButtonClick}
            className="CandidateInformationForm__Actions__SkipButton"
            size="large"
            fullWidth
          >
            {t('common.preferNotToSay')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default CandidateInformationForm;
