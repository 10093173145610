import './MobileVerbalTest.scss';
import Loader from 'components/atoms/Loader/Loader';
import ProgressBar from 'components/molecules/ProgressBar/ProgressBar';
import {
  TestStatus,
  useQCalcGetNextQuestionMutation,
  VerbalTestletModel,
} from 'generated/graphql';
import { useWindowSize } from 'hooks/containerSize';
import useHubspotDisable from 'hooks/useHubspotDisable';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SliderTestQuestion from 'views/Test/Tests/components/SliderTestQuestion/SliderTestQuestion';
import TestHeader from 'views/Test/Tests/components/TestHeader/TestHeader';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { useBlockTestSubmit } from '../../helpers/blockTestSubmit';
import { QUESTIONS_IN_TESTLET } from '../VerbalTest.constants';

interface IMobileVerbalTest {
  assessmentId: number;
  onFinishTest: () => void;
}

interface IVerbalTestAnswer {
  optionSubId: string | null;
  questionSubId: string;
}

const MobileVerbalTest: FC<IMobileVerbalTest> = ({
  assessmentId,
  onFinishTest,
}) => {
  useHubspotDisable(true);
  const { t } = useTranslation();
  const { handleMsgType } = useNotification();
  const [containerSize] = useWindowSize();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [verbalQuestion, setVerbalQuestion] = useState<VerbalTestletModel>();
  const [verbalAnswer, setVerbalAnswer] = useState<IVerbalTestAnswer[]>([]);
  const [defaultAnswer, setDefaultAnswer] = useState(0);
  const { blockedSubmit, activateBlockingSubmit } = useBlockTestSubmit();

  const [qCalcGetNextQuestionMutation, qCalcGetNextQuestionResponse] =
    useQCalcGetNextQuestionMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      onCompleted: data => {
        if (
          data &&
          data.QCalcGetNextQuestion.testCandidateStatus === TestStatus.Completed
        ) {
          onFinishTest();
        }
        if (data.QCalcGetNextQuestion) {
          setVerbalQuestion(data.QCalcGetNextQuestion as VerbalTestletModel);
          setDefaultAnswerValue(
            data.QCalcGetNextQuestion as VerbalTestletModel
          );
          setCurrentIndex(0);
        }
      },
    });

  const setDefaultAnswerValue = (data: VerbalTestletModel) => {
    const isDefaultAnswer = data.questions.map(item => ({
      optionSubId: item.options[2].subId,
      questionSubId: item.subId,
    }));
    setVerbalAnswer(isDefaultAnswer);
  };

  useEffect(() => {
    qCalcGetNextQuestionMutation({
      variables: {
        assessmentId,
        nQuestions: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAnswer = (
    questionIndex: number,
    optionSubId: string,
    questionSubId: string
  ) => {
    const newAnswer = {
      optionSubId,
      questionSubId,
    };
    const temp = [...verbalAnswer];
    temp[questionIndex] = newAnswer;
    setVerbalAnswer(temp);
  };

  const handleAnswer = () => {
    if (verbalQuestion && currentIndex + 1 < verbalQuestion?.questions.length) {
      setCurrentIndex(currentIndex + 1);
      setDefaultAnswer(-1);
    } else {
      qCalcGetNextQuestionMutation({
        variables: {
          answers: verbalAnswer,
          assessmentId,
          nQuestions: 1,
        },
      });
      setDefaultAnswer(-1);
    }
    activateBlockingSubmit();
  };

  const handleTimeout = () => {
    qCalcGetNextQuestionMutation({
      variables: {
        answers: verbalAnswer,
        assessmentId,
        nQuestions: 1,
      },
    });
    setDefaultAnswer(-1);
    activateBlockingSubmit();
  };

  const handleUpdateDefault = () => {
    setDefaultAnswer(-2);
  };

  if (qCalcGetNextQuestionResponse.loading) {
    return <Loader isOverlay />;
  }

  if (!verbalQuestion) {
    return null;
  }

  const { totalQuestions, remainingQuestions } = verbalQuestion;
  const currentTestlet =
    totalQuestions / QUESTIONS_IN_TESTLET -
    remainingQuestions / QUESTIONS_IN_TESTLET +
    1;
  const totalNumberOfTestlets = totalQuestions / QUESTIONS_IN_TESTLET;
  const currentQuestion =
    currentIndex + 1 + totalQuestions - remainingQuestions;

  return (
    <div style={{ height: containerSize.height }} className="MobileVerbalTest">
      <TestHeader
        assessmentId={assessmentId}
        progressBarProps={{
          value: currentTestlet,
          maxValue: totalNumberOfTestlets,
        }}
        hasTimer
        timerInterval={verbalQuestion.remainingTime}
        timeLimit={verbalQuestion.timeLimit}
        onTimeout={handleTimeout}
        hasProgressBar={false}
        pageIndicatorProps={{
          prefixWord: 'Question',
          connectorWord: 'of',
          currentPage: currentQuestion,
          existingPages: totalQuestions,
        }}
      />
      <div className="MobileVerbalTest__Testlet">
        <div className="MobileVerbalTest__Testlet__TextWrapper">
          <p className="TextInstruction">{t('test.readAndAnswer')}</p>
          <p className="MobileVerbalTest__Testlet__Text">
            {verbalQuestion.text}
          </p>
        </div>
        <div className="MobileVerbalTest__Testlet__QuestionWrapper">
          <ProgressBar
            value={currentIndex + 1}
            maxValue={verbalQuestion.questions.length}
          />
          <SliderTestQuestion
            questionNumberingFormat={`${currentIndex + 1}/${
              verbalQuestion.questions.length
            }`}
            defaultSelectedAnswer={defaultAnswer}
            questionIndex={currentIndex}
            questions={verbalQuestion.questions[currentIndex]}
            onAnswer={getAnswer}
            isMobile={true}
            startPoint={2}
            updateDefault={handleUpdateDefault}
          />
          <div className="SubmitButton">
            <Button
              onClick={handleAnswer}
              size="large"
              disabled={blockedSubmit}
            >
              {t('test.submitAndProceed')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileVerbalTest;
