import './MobilePersonalityTest.scss';
import Loader from 'components/atoms/Loader/Loader';
import Container from 'components/molecules/Container/Container';
import { PersonalityTestsAnswers } from 'enums/tests';
import {
  PersonalityItemModel,
  useQCalcGetNextQuestionMutation,
} from 'generated/graphql';
import useHubspotDisable from 'hooks/useHubspotDisable';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TestHeader from 'views/Test/Tests/components/TestHeader/TestHeader';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { useBlockTestSubmit } from '../../helpers/blockTestSubmit';
import MobilePersonalityTestSlider from './MobilePersonalityTestSlider/MobilePersonalityTestSlider';
import PersonalityTestAnswerPreview from './PersonalityTestAnswerPreview/PersonalityTestAnswerPreview';

interface IMobilePersonalityTest {
  assessmentId: number;
  hideTpCallback?: boolean;
  isPracticeTest?: boolean;
  onFinishTest: (hideTpCallback?: boolean) => void;
}

interface IQuestionAnswerPair {
  questionSubId: string;
  optionSubId: string;
}

const MobilePersonalityTest: FC<IMobilePersonalityTest> = ({
  assessmentId,
  hideTpCallback,
  onFinishTest,
}) => {
  useHubspotDisable(true);
  const { handleMsgType } = useNotification();
  const { t } = useTranslation();
  const { blockedSubmit, activateBlockingSubmit } = useBlockTestSubmit();

  const [answerValue, setAnswerValue] = useState<PersonalityTestsAnswers>(
    PersonalityTestsAnswers.NEUTRAL
  );
  const [candidateAnswers, setCandidateAnswers] = useState<
    IQuestionAnswerPair[]
  >([]);

  const [qCalcGetNextQuestionMutation, qCalcGetNextQuestionResponse] =
    useQCalcGetNextQuestionMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      onCompleted: data => {
        if (data.QCalcGetNextQuestion.isCompleted) {
          onFinishTest(hideTpCallback);
        } else {
          setCandidateAnswers([
            {
              questionSubId: (data.QCalcGetNextQuestion as PersonalityItemModel)
                .questions[0].subId,
              optionSubId: (data.QCalcGetNextQuestion as PersonalityItemModel)
                .questions[0].options[PersonalityTestsAnswers.NEUTRAL].subId,
            },
          ]);
        }
      },
    });

  useEffect(() => {
    qCalcGetNextQuestionMutation({
      variables: {
        assessmentId,
        nQuestions: 1,
      },
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnswerChange = (
    newAnswerValue: PersonalityTestsAnswers,
    questionSubId: string,
    optionSubId: string
  ) => {
    setAnswerValue(newAnswerValue);
    setCandidateAnswers([
      {
        questionSubId,
        optionSubId,
      },
    ]);
  };

  const handleNextQuestion = () => {
    setAnswerValue(PersonalityTestsAnswers.NEUTRAL);
    qCalcGetNextQuestionMutation({
      variables: {
        assessmentId,
        nQuestions: 1,
        answers: candidateAnswers,
      },
    });
    activateBlockingSubmit();
  };

  if (qCalcGetNextQuestionResponse.loading) {
    return <Loader isOverlay />;
  }

  if (!qCalcGetNextQuestionResponse.data) {
    return null;
  }

  const qCalcQuestion = qCalcGetNextQuestionResponse.data
    .QCalcGetNextQuestion as PersonalityItemModel;

  const { totalQuestions } = qCalcQuestion;
  const currentQuestion =
    totalQuestions - (qCalcQuestion.remainingQuestions - 1);

  return (
    <Container className="MobilePersonalityTest">
      <TestHeader
        assessmentId={assessmentId}
        progressBarProps={{
          value: currentQuestion,
          maxValue: totalQuestions,
        }}
        pageIndicatorProps={{
          prefixWord: t('common.question'),
          connectorWord: t('common.of'),
          currentPage: currentQuestion,
          existingPages: totalQuestions,
        }}
      />
      <div className="MobilePersonalityTestQuestion">
        <h2 className="MobilePersonalityTestQuestion__Text">
          {qCalcQuestion?.questions?.[0]?.text}
        </h2>
        <div className="MobilePersonalityTestQuestion__AnswerPreviewWrapper">
          <PersonalityTestAnswerPreview
            answerValue={answerValue}
            options={qCalcQuestion?.questions[0]?.options || []}
          />
        </div>
        {qCalcQuestion.questions.map((question, index) => (
          <MobilePersonalityTestSlider
            onAnswerChange={handleAnswerChange}
            question={question}
            key={index}
          />
        ))}
        <Button
          onClick={handleNextQuestion}
          className="MobilePersonalityTestQuestion__SubmitButton"
          fullWidth
          disabled={blockedSubmit}
        >
          {t('test.submitAndProceed')}
        </Button>
      </div>
    </Container>
  );
};

export default MobilePersonalityTest;
