import './StageCardsList.scss';
import { StageCardFragment, User } from 'generated/graphql';
import { getFullNameFromLanguage } from 'helpers/locales';
import { parseStageStatus } from 'helpers/parseStageStatus';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StageCard, { TStageCardProps } from '../../StageCard/StageCard';

interface IStageCardsList {
  stageCards: StageCardFragment[];
}

const parseStageCard = (stageCard: StageCardFragment): TStageCardProps => {
  return {
    projectName: stageCard.project.name,
    stageName: stageCard.stage.name,
    companyLogoUrl: stageCard.company.logoUrl || undefined,
    companyName: stageCard.company.name,
    renderCandidateReport: stageCard.stage.renderCandidateReport,
    candidateReportId: stageCard.candidateReportId
      ? stageCard.candidateReportId.toLocaleString()
      : undefined,
    stageEndTime: stageCard.stage.endTime
      ? new Date(stageCard.stage.endTime)
      : undefined,
    inspectable: stageCard.inspectable,
    doneFor: getFullNameFromLanguage(stageCard.doneForUser as User),
    status: parseStageStatus(
      stageCard.stage.status,
      stageCard.stageCandidate.status
    ),
    projectModuleType: stageCard.project.moduleType,
    stageCandidateId: stageCard.stageCandidate.id,
    hasAdjustmentQuestionnaire:
      stageCard.stage.hasAdjustmentQuestionnaire &&
      !stageCard.stageCandidate.hasCompletedAdjustmentQuestionnaire,
  };
};

const StageCardsList: FC<IStageCardsList> = ({ stageCards }) => {
  const { t } = useTranslation();
  return (
    <nav
      className="StageCardsList"
      role="navigation"
      aria-label={t('assessment.listOfYourAssessmentsStages')}
    >
      {stageCards?.map(stageCard => (
        <StageCard
          key={stageCard.stageCandidate.id}
          {...parseStageCard(stageCard)}
        />
      ))}
    </nav>
  );
};

export default StageCardsList;
