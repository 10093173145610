import NavbarWithBackLink from 'components/molecules/NavbarWithBackLink/NavbarWithBackLink';
import Navbar from 'components/organisms/Navbar/Navbar';
import { appSwitchRoutes, stageSwitchRoutes } from 'constants/routes';
import { ICalibrateFormPath, ICalibrateRouteState } from 'interfaces/routes';
import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CalibrationForm from 'views/CalibrationForm/components/CalibrationForm/CalibrationForm';

interface ICalibration {}

const Calibration: FC<ICalibration> = () => {
  const redirectFrom = localStorage.getItem('redirectFrom');
  const { stageCandidateId } = useParams() as ICalibrateFormPath;
  const location = useLocation();
  const state = location.state as ICalibrateRouteState;

  const navigate = useNavigate();
  const onCloseHandler = () => {
    if (redirectFrom) {
      navigate(stageSwitchRoutes.tpCallback.getUrl(stageCandidateId));
    } else {
      navigate(stageSwitchRoutes.stage.getUrl(stageCandidateId));
    }
  };
  return (
    <div>
      <Navbar fromCompany={false} />
      <NavbarWithBackLink to={appSwitchRoutes.stage.getUrl(stageCandidateId)} />
      <CalibrationForm
        stageCandidateId={parseInt(stageCandidateId)}
        userType={'candidate'}
        onCloseHandler={onCloseHandler}
        projectId={state && state.projectId ? state.projectId : 0}
        doneBy={state && state.doneBy ? state.doneBy : 0}
        doneFor={state && state.doneFor ? state.doneFor : 0}
      />
    </div>
  );
};

export default Calibration;
