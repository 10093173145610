import {
  DeiAgeRange,
  DeiStageActionResponsesFragment,
} from 'generated/graphql';
import { capitalize, omit } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SelectFormField } from '@spotted-zebra-uk/ui-components';
import {
  getDeiFieldsWithOptions,
  initializeDeiValues,
} from './StageActionDeiForm.helpers';
import {
  DeiErrors,
  deiPayloadKeys,
  DeiValues,
  IDeiFormFieldProps,
} from './StageActionDeiForm.interfaces';
import styles from './StageActionDeiForm.module.scss';

const DeiFormField = ({
  id,
  options,
  value,
  onChange,
  error,
  description,
}: IDeiFormFieldProps) => {
  const { t } = useTranslation();

  const translatedOptions = useMemo(() => {
    if (id === 'ageRange') {
      return [
        ...options,
        {
          label: t(`test.dei.preferNotToSay`),
          value: DeiAgeRange.PreferNotToSay,
        },
      ];
    }
    return options?.map(option => ({
      label: t(`test.dei.${option.label}`),
      value: option.value,
    }));
  }, [id, options, t]);

  return (
    <div className={styles.formFieldContainer}>
      <label>{t(`test.dei.${id}`)}</label>
      {description && (
        <p className={styles.description}>{t(`test.dei.${description}`)}</p>
      )}
      <div className={styles.formField}>
        <SelectFormField
          label={t(`test.dei.option`)}
          placeholder={t(`test.dei.option`)}
          onChange={onChange}
          value={value}
          id={id}
          name={id}
          options={translatedOptions || []}
          hasError={!!error}
          bottomText={error}
        />
      </div>
    </div>
  );
};

const DeiFormFields = ({
  formFields,
  onSubmit,
  loadingSubmit,
  responses,
}: {
  formFields: ReturnType<typeof getDeiFieldsWithOptions>;
  onSubmit: (payload: { [key: string]: string }) => void;
  loadingSubmit: boolean;
  responses: DeiStageActionResponsesFragment;
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<DeiValues>(
    initializeDeiValues(responses)
  );
  const [errors, setErrors] = useState<DeiErrors>({});

  const handleChange = (
    newValue: { label: string; value: string },
    id: string
  ) => {
    if (newValue.value === '') {
      const updatedValues = omit(values, id as deiPayloadKeys);
      setValues(updatedValues);
      return;
    }

    if (errors && errors.hasOwnProperty(id)) {
      const updatedErrors = omit(errors, id as deiPayloadKeys);
      setErrors(updatedErrors);
    }
    setValues(prevValues => ({ ...prevValues, [id]: newValue }));
  };

  const handleSubmitDeiForm = () => {
    const answerKeys = Object.keys(values);

    if (answerKeys.length < formFields.length) {
      const errorsTmp = { ...errors };
      formFields.forEach(
        field =>
          !answerKeys.includes(field.id) &&
          (errorsTmp[field.id as deiPayloadKeys] = t('common.required'))
      );
      setErrors(errorsTmp);
      return;
    }
    if (Object.keys(errors).length === 0) {
      const payload: { [key: string]: string } = {};
      Object.entries(values).forEach(val => (payload[val[0]] = val[1]?.value));
      onSubmit(payload);
    }
  };

  return (
    <>
      {formFields.map(field => (
        <DeiFormField
          key={field.id}
          {...field}
          options={field.options}
          value={values[field.id as deiPayloadKeys]}
          onChange={handleChange}
          error={errors[field.id as deiPayloadKeys]}
        />
      ))}
      <div className={styles.submit}>
        <Button
          disabled={loadingSubmit}
          loading={loadingSubmit}
          onClick={handleSubmitDeiForm}
        >
          {loadingSubmit
            ? `${t('common.submitting')}...`
            : capitalize(t('assessment.completeQuestionnaire'))}
        </Button>
      </div>
    </>
  );
};

export default DeiFormFields;
