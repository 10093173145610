import { ApolloCache } from '@apollo/client';
import { SearchOption } from '@spotted-zebra-uk/ui-components';
import {
  ResultCreateOnePspSoftSkillMutation,
  ResultCreateOnePspTechnicalSkillMutation,
  SkillsProfileOpenOneDocument,
  SkillsProfileOpenOneQuery,
  SkillsProfileOpenOneSkillsWithResultsFragment,
  SkillType,
  SoftSkillSelfAssessmentScoreModel,
  SzSkillsMergeFreeTextSearchPaginatedDocument,
  SzSkillsMergeFreeTextSearchPaginatedQuery,
  TechnicalSkillSelfAssessmentProficiencyLevel,
  TechnicalSkillSelfAssessmentScoreModel,
} from 'generated/graphql';
import {
  softSkillProficiencyLevelToBarChartValuesMap,
  technicalSkillProficiencyLevelToBarChartValuesMap,
} from 'helpers/skillsProficiency';
import { SZ_COMPANY_ID } from 'constants/companyId';
import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import {
  SearchValue,
  SearchVariables,
} from '../SkillsHeader/SkillsSearch/SkillsSearch';
import SkillsSearchOptionIcons from '../SkillsHeader/SkillsSearch/SkillsSearchOptionIcons';
import { SkillWithUpdate } from '../types';

export const mapSkills = (
  skills: SkillsProfileOpenOneSkillsWithResultsFragment[]
): SkillWithUpdate[] =>
  skills.map(({ skill, result }) => ({
    id: skill.id,
    name: skill.name,
    type: SkillType[skill.__typename as keyof typeof SkillType],
    proficiency:
      result.score.__typename === 'SoftSkillSelfAssessmentScoreModel' &&
      result.score.proficiency
        ? softSkillProficiencyLevelToBarChartValuesMap[result.score.proficiency]
        : result.score.__typename ===
            'TechnicalSkillSelfAssessmentScoreModel' &&
          result.score.technicalProficiency
        ? technicalSkillProficiencyLevelToBarChartValuesMap[
            result.score.technicalProficiency
          ]
        : 1,
    description: (skill as { description?: string }).description,
    isCompanySpecific:
      skill.__typename === 'SoftSkill' && skill.companyId !== SZ_COMPANY_ID,
    updated: result.updatedAt,
  }));

export const updateCache = (
  cache: ApolloCache<{}>,
  data:
    | ResultCreateOnePspSoftSkillMutation
    | ResultCreateOnePspTechnicalSkillMutation,
  skillType: SkillType
) => {
  const cachedData = cache.readQuery<SkillsProfileOpenOneQuery>({
    query: SkillsProfileOpenOneDocument,
  });

  if (cachedData) {
    const skillsWithResultsToUpdate = cachedData && [
      ...cachedData.SkillsProfileOpenOne.skillsWithResults,
    ];
    const skillToUpdateIndex = skillsWithResultsToUpdate?.findIndex(
      ({ skill: { id, __typename } }) => {
        if (skillType === SkillType.SoftSkill)
          return (
            __typename === 'SoftSkill' &&
            id ===
              (data as ResultCreateOnePspSoftSkillMutation)
                .ResultCreateOnePspSoftSkill.measurementId
          );

        if (skillType === SkillType.TechnicalSkill)
          return (
            __typename === 'TechnicalSkill' &&
            id ===
              (data as ResultCreateOnePspTechnicalSkillMutation)
                .ResultCreateOnePspTechnicalSkill.measurementId
          );

        return false;
      }
    );

    if (skillToUpdateIndex > -1) {
      const updatedSkill = {
        ...skillsWithResultsToUpdate[skillToUpdateIndex],
        result: {
          ...skillsWithResultsToUpdate[skillToUpdateIndex].result,
          updatedAt:
            skillType === SkillType.SoftSkill
              ? (data as ResultCreateOnePspSoftSkillMutation)
                  .ResultCreateOnePspSoftSkill.updatedAt
              : skillType === SkillType.TechnicalSkill
              ? (data as ResultCreateOnePspTechnicalSkillMutation)
                  .ResultCreateOnePspTechnicalSkill.updatedAt
              : skillsWithResultsToUpdate[skillToUpdateIndex].result.updatedAt,
          score:
            skillType === SkillType.SoftSkill
              ? (data as ResultCreateOnePspSoftSkillMutation)
                  .ResultCreateOnePspSoftSkill.score
              : skillType === SkillType.TechnicalSkill
              ? (data as ResultCreateOnePspTechnicalSkillMutation)
                  .ResultCreateOnePspTechnicalSkill.score
              : null,
        },
      };
      const updatedSkillsWithResults = skillsWithResultsToUpdate.map(
        (skill, i) => (i === skillToUpdateIndex ? updatedSkill : skill)
      );

      cache.writeQuery({
        query: SkillsProfileOpenOneDocument,
        data: {
          SkillsProfileOpenOne: {
            skillsWithResults: updatedSkillsWithResults,
          },
        },
      });
    }
  }
};

export const updateSearchCache = (
  cache: ApolloCache<{}>,
  data:
    | ResultCreateOnePspSoftSkillMutation
    | ResultCreateOnePspTechnicalSkillMutation,
  type: SkillType,
  variables?: SearchVariables
) => {
  const cachedData = cache.readQuery<SzSkillsMergeFreeTextSearchPaginatedQuery>(
    {
      query: SzSkillsMergeFreeTextSearchPaginatedDocument,
      variables,
    }
  );

  if (cachedData) {
    const searchSkillsToUpdate = cachedData && [
      ...cachedData.SzSkillsMergeFreeTextSearchPaginated.skills,
    ];
    const skillToUpdateIndex = searchSkillsToUpdate?.findIndex(
      ({ skill: { originId, skillType } }) => {
        if (type === SkillType.SoftSkill)
          return (
            skillType === SkillType.SoftSkill &&
            originId ===
              (data as ResultCreateOnePspSoftSkillMutation)
                .ResultCreateOnePspSoftSkill.measurementId
          );

        if (type === SkillType.TechnicalSkill)
          return (
            skillType === SkillType.TechnicalSkill &&
            originId ===
              (data as ResultCreateOnePspTechnicalSkillMutation)
                .ResultCreateOnePspTechnicalSkill.measurementId
          );

        return false;
      }
    );

    if (skillToUpdateIndex > -1) {
      const updatedSkill = {
        ...searchSkillsToUpdate[skillToUpdateIndex],
        alreadyExist: true,
        result: {
          ...searchSkillsToUpdate[skillToUpdateIndex].result,
          score:
            type === SkillType.SoftSkill
              ? (data as ResultCreateOnePspSoftSkillMutation)
                  .ResultCreateOnePspSoftSkill.score
              : type === SkillType.TechnicalSkill
              ? (data as ResultCreateOnePspTechnicalSkillMutation)
                  .ResultCreateOnePspTechnicalSkill.score
              : null,
        },
      };
      const updatedSearchSkills = searchSkillsToUpdate.map((skill, i) =>
        i === skillToUpdateIndex ? updatedSkill : skill
      );

      cache.writeQuery({
        query: SzSkillsMergeFreeTextSearchPaginatedDocument,
        data: {
          SzSkillsMergeFreeTextSearchPaginated: {
            ...cachedData.SzSkillsMergeFreeTextSearchPaginated,
            skills: updatedSearchSkills,
          },
        },
        variables,
      });
    }
  }
};

export const sortSkillsByName = (skills: SkillWithUpdate[]) =>
  skills.sort((a, b) =>
    a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
  );

export const sortSkillsByUpdate = (skills: SkillWithUpdate[]) =>
  skills.sort((a, b) => (a.updated >= b.updated ? 1 : -1));

export const filterSkillsByProficiency = (
  skills: SkillWithUpdate[],
  proficiencyLevel: TechnicalSkillSelfAssessmentProficiencyLevel
) =>
  skills.filter(
    ({ proficiency }) =>
      proficiency ===
      technicalSkillProficiencyLevelToBarChartValuesMap[proficiencyLevel]
  );

export const filterSkillsByType = (
  skills: SkillWithUpdate[],
  type: SkillType
) => skills.filter(skill => type === skill.type);

export const filterNoKnowledgeSkills = (
  skills: SkillWithUpdate[],
  includeNoKnowledgeSkills: boolean
) =>
  skills.filter(({ proficiency }) => {
    if (proficiency) {
      return includeNoKnowledgeSkills
        ? true
        : proficiency >
            technicalSkillProficiencyLevelToBarChartValuesMap[
              TechnicalSkillSelfAssessmentProficiencyLevel.NoKnowledge
            ];
    }

    return false;
  });

export const mapSearchSkills = (
  data?: SzSkillsMergeFreeTextSearchPaginatedQuery
): SearchOption<SearchValue>[] => {
  return (
    data?.SzSkillsMergeFreeTextSearchPaginated.skills.map(
      ({
        skill: { originId, name, description, skillType, companyId },
        alreadyExist,
        result,
      }) => {
        let proficiency: TBarChartValue | undefined = undefined;

        if (result?.score) {
          if (skillType === SkillType.SoftSkill) {
            proficiency =
              softSkillProficiencyLevelToBarChartValuesMap[
                (result?.score as SoftSkillSelfAssessmentScoreModel).proficiency
              ];
          }

          if (skillType === SkillType.TechnicalSkill) {
            proficiency =
              technicalSkillProficiencyLevelToBarChartValuesMap[
                (result?.score as TechnicalSkillSelfAssessmentScoreModel)
                  .technicalProficiency
              ];
          }
        }

        return {
          value: {
            id: originId,
            name,
            description: description || '',
            type: skillType,
            proficiency,
            isCompanySpecific: companyId !== SZ_COMPANY_ID,
            alreadyExist,
          },
          label: name,
          secondLabel: description || '',
          icon: (
            <SkillsSearchOptionIcons
              isCompanySpecific={companyId !== SZ_COMPANY_ID}
              alreadyExist={alreadyExist}
              proficiency={proficiency}
            />
          ),
        };
      }
    ) || []
  );
};
