import './InductiveTestQuestionView.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@spotted-zebra-uk/ui-components';
import {
  TInductiveTestOptions,
  TInductiveTestQuestionImages,
} from '../inductiveTestTypes';
import InductiveTestQuestion from './InductiveTestQuestion/InductiveTestQuestion';
import InductiveTestQuestionAnswers from './InductiveTestQuestionAnswers/InductiveTestQuestionAnswers';

interface IInductiveTestQuestionView {
  questionImages: TInductiveTestQuestionImages;
  options: TInductiveTestOptions[];
  questionText: string;
  onAnswerSubmit: () => void;
  onAnswer: (answerId: string, answerImg: string) => void;
  answerId: string | undefined;
  answerImage: string | undefined;
}

const InductiveTestQuestionView: FC<IInductiveTestQuestionView> = ({
  questionImages,
  options,
  questionText,
  onAnswerSubmit,
  onAnswer,
  answerId,
  answerImage,
}) => {
  const { t } = useTranslation();
  return (
    <div className="InductiveTestQuestionView">
      <div className="InductiveTestQuestionView__QuestionWrapper">
        <InductiveTestQuestion
          questionImages={questionImages}
          previewImage={answerImage}
        />
      </div>
      <p className="InductiveTestQuestionView__Text">{questionText}</p>
      <InductiveTestQuestionAnswers
        inductiveTestAnswers={options}
        onSetPreview={onAnswer}
        selectedAnswerId={answerId}
      />
      <div className="InductiveTestQuestionView__SquareButtonWrapper">
        <Button
          disabled={!answerId}
          onClick={onAnswerSubmit}
          aria-label={!answerId ? t('test.pickAnOptionToProceed') : undefined}
          fullWidth
        >
          {t('test.submitAndProceed')}
        </Button>
      </div>
    </div>
  );
};

export default InductiveTestQuestionView;
