import Loader from 'components/atoms/Loader/Loader';
import {
  AUTH_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from 'constants/authentication';
import {
  useGetCompanyWithStageCandidateIdQuery,
  useUserQuery,
  useUserSettingsCreateMutation,
  useUserSettingsFindOneLazyQuery,
  useUserSettingsUpdateMutation,
} from 'generated/graphql';
import { setAccessToken, setRefreshToken } from 'helpers/auth';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import TalentReviewInfo from '../TalentReviewInfo/TalentReviewInfo';

const CompanyNavigationDataContainer = ({
  redirectTo,
}: {
  redirectTo: string | null;
}) => {
  const stageCandidateId = redirectTo?.split('/')[2];
  const navigate = useNavigate();
  const { handleMsgType } = useNotification();

  const [userSettingsId, setUserSettingsId] = useState<number>(0);
  const [userSettingsCreated, setUserSettingsCreated] =
    useState<boolean>(false);

  const [userSettingsUpdate] = useUserSettingsUpdateMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
  });

  const [userSettingQuery, userSettingsQueryResponse] =
    useUserSettingsFindOneLazyQuery({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      onCompleted: data => {
        if (data.UserSettingsFindOne?.id) {
          setUserSettingsId(data.UserSettingsFindOne.id);
        }
        if (!data.UserSettingsFindOne && userResponse.data?.User) {
          userSettingsCreate({
            variables: {
              userId: userResponse.data?.User.id,
              settings: {
                TR_displayOnboarding: true,
                CM_lastVisitedModule: null,
              },
            },
          });
        }
      },
    });

  const [userSettingsCreate] = useUserSettingsCreateMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
    onCompleted: data => {
      setUserSettingsId(data.UserSettingsCreate.id);
      setUserSettingsCreated(true);
    },
  });

  const companyQuery = useGetCompanyWithStageCandidateIdQuery({
    variables: {
      stageCandidateId: stageCandidateId ? parseInt(stageCandidateId) : 0,
    },
    fetchPolicy: 'network-only',
    skip: !stageCandidateId,
  });

  const userResponse = useUserQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
    onCompleted: data => {
      if (data?.User) {
        userSettingQuery({
          variables: {
            userId: data?.User.id,
          },
        });
      }
    },
  });

  const loading =
    userSettingsQueryResponse.loading ||
    companyQuery.loading ||
    userResponse.loading;

  if (loading) {
    return <Loader isOverlay />;
  }

  if (
    (userSettingsQueryResponse.data?.UserSettingsFindOne?.settings
      ?.TR_displayOnboarding &&
      !userSettingsQueryResponse.loading) ||
    userSettingsCreated
  ) {
    return (
      <TalentReviewInfo
        companyImage={
          companyQuery.data?.GetStageActions.company?.logoUrl || undefined
        }
        goToReviewPage={review => {
          userSettingsUpdate({
            variables: {
              id: userSettingsId,
              settings: {
                CM_lastVisitedModule: null,
                TR_displayOnboarding: false,
              },
            },
          });
          if (redirectTo) navigate(redirectTo);
        }}
      />
    );
  }
  if (
    userSettingsQueryResponse.data?.UserSettingsFindOne &&
    !userSettingsQueryResponse.data?.UserSettingsFindOne?.settings
      ?.TR_displayOnboarding &&
    !userSettingsQueryResponse.loading &&
    redirectTo
  ) {
    return <Navigate to={redirectTo} replace />;
  }
  return null;
};

const CompanyNavigation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const auth_token = queryParams.get(AUTH_TOKEN_STORAGE_KEY);
  const refresh_token = queryParams.get(REFRESH_TOKEN_STORAGE_KEY);
  const redirectFrom = queryParams.get('redirectFrom');
  const onCompleted = queryParams.get('onCompleted');
  const redirectTo = queryParams.get('redirectTo');

  //TODO - should be updated to pass authCode instead of auth_token

  if (redirectFrom) localStorage.setItem('redirectFrom', redirectFrom);
  if (onCompleted) localStorage.setItem('onCompleted', onCompleted);
  if (auth_token) setAccessToken(auth_token);
  if (refresh_token) setRefreshToken(refresh_token);

  return <CompanyNavigationDataContainer redirectTo={redirectTo} />;
};

export default CompanyNavigation;
