import Icon from 'components/atoms/Icon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const SkillsLevelNoSkillsLeftScreen: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="skills-level-no-skills-left">
    <div className="skills-level-no-skills-left__completed-icon-wrapper">
      <Icon
        icon="completed"
        className="skills-level-no-skills-left__completed-icon"
      />
    </div>
    <div className="skills-level-no-skills-left__text" data-testid="no-skills-left">{t('common.noSkillsLeft')}</div>
  </div>
  )
}

export default SkillsLevelNoSkillsLeftScreen;
