import './DesktopNumericalTest.scss';
import Loader from 'components/atoms/Loader/Loader';
import ProgressBar from 'components/molecules/ProgressBar/ProgressBar';
import SVGRenderer from 'components/molecules/SVGRenderer/SVGRenderer';
import {
  NumericalTestletModel,
  useQCalcGetNextQuestionMutation,
} from 'generated/graphql';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SliderTestQuestion from 'views/Test/Tests/components/SliderTestQuestion/SliderTestQuestion';
import TestHeader from 'views/Test/Tests/components/TestHeader/TestHeader';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { useBlockTestSubmit } from '../../helpers/blockTestSubmit';
import { QUESTIONS_IN_NUMERICAL_TESTLET } from '../NumericalTest.constants';
import { INumericalTestAnswer } from '../NumericalTest.types';

interface IDesktopNumericalTest {
  assessmentId: number;
  isPracticeTest: boolean;
  onFinishTest: () => void;
}

const SVG_DIMENSION = 450;
const sliderQuestionMarksStyle: React.CSSProperties = {
  marginTop: 34,
  transform: 'rotate(-45deg)',
  marginLeft: -86,
  width: 100,
  padding: 0,
  textAlign: 'right',
};

const DesktopNumericalTest: FC<IDesktopNumericalTest> = ({
  assessmentId,
  onFinishTest,
}) => {
  const { t } = useTranslation();
  const { handleMsgType } = useNotification();
  const { blockedSubmit, activateBlockingSubmit } = useBlockTestSubmit();

  const questionWrapperRef = useRef<HTMLDivElement | null>(null);
  const [numericalQuestion, setNumericalQuestion] =
    useState<NumericalTestletModel>();
  const imageSize = { width: SVG_DIMENSION, height: SVG_DIMENSION };
  const [numericalAnswer, setNumericalAnswer] = useState<
    INumericalTestAnswer[]
  >([]);

  const [qCalcGetNextQuestionMutation, qCalcGetNextQuestionResponse] =
    useQCalcGetNextQuestionMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      onCompleted: data => {
        if (data && data.QCalcGetNextQuestion.isCompleted) {
          onFinishTest();
        }
        if (data.QCalcGetNextQuestion) {
          setNumericalQuestion(
            data.QCalcGetNextQuestion as NumericalTestletModel
          );
          setDefaultAnswer(data.QCalcGetNextQuestion as NumericalTestletModel);
        }
      },
    });

  const setDefaultAnswer = (data: NumericalTestletModel) => {
    const isDefaultAnswer = data.questions.map(item => ({
      optionSubId: item.options[0].subId,
      questionSubId: item.subId,
    }));
    setNumericalAnswer(isDefaultAnswer);
  };

  useEffect(() => {
    qCalcGetNextQuestionMutation({
      variables: {
        assessmentId,
        nQuestions: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAnswer = (
    questionIndex: number,
    optionSubId: string,
    questionSubId: string
  ) => {
    const newAnswer = {
      optionSubId,
      questionSubId,
    };
    const temp = [...numericalAnswer];
    temp[questionIndex] = newAnswer;
    setNumericalAnswer(temp);
  };

  const handleAnswer = (event: React.MouseEvent<HTMLButtonElement>) => {
    qCalcGetNextQuestionMutation({
      variables: {
        answers: numericalAnswer,
        assessmentId,
        nQuestions: 1,
      },
    });
    event.currentTarget.blur();
    activateBlockingSubmit();
  };

  const handleTimeout = () => {
    qCalcGetNextQuestionMutation({
      variables: {
        answers: numericalAnswer,
        assessmentId,
        nQuestions: 1,
      },
    });
    activateBlockingSubmit();
  };

  if (qCalcGetNextQuestionResponse.loading) {
    return <Loader isOverlay />;
  }

  if (!numericalQuestion) {
    return null;
  }

  const currentTestlet =
    numericalQuestion.totalQuestions / QUESTIONS_IN_NUMERICAL_TESTLET -
    numericalQuestion.remainingQuestions / QUESTIONS_IN_NUMERICAL_TESTLET +
    1;
  const testletsInTest =
    numericalQuestion.totalQuestions / QUESTIONS_IN_NUMERICAL_TESTLET;

  return (
    <div className="DesktopNumericalTest">
      <ProgressBar value={currentTestlet} maxValue={testletsInTest} />
      <TestHeader
        assessmentId={assessmentId}
        progressBarProps={{
          value: currentTestlet,
          maxValue: testletsInTest,
        }}
        hasTimer
        timerInterval={numericalQuestion.remainingTime}
        timeLimit={numericalQuestion.timeLimit}
        onTimeout={handleTimeout}
        hasProgressBar={false}
        pageIndicatorProps={{
          currentPage: currentTestlet,
          existingPages: testletsInTest,
          connectorWord: 'of',
          prefixWord: 'Page',
        }}
      />
      <div className="DesktopNumericalTestContainer">
        <div className="DesktopNumericalTestContainer__ImageWrapper">
          <SVGRenderer
            src={numericalQuestion.imageUrl}
            alt={t('alt.testChart')}
            size={imageSize}
            loading={() => (
              <div className="DesktopNumericalTestContainer__ImageLoaderWrapper">
                <Loader />
              </div>
            )}
          />
        </div>
        <div
          ref={questionWrapperRef}
          className="DesktopNumericalTestContainer__QuestionWrapper"
        >
          {numericalQuestion.questions.map((item, index) => (
            <div key={item.subId} className="singleNumerical-testlet">
              <SliderTestQuestion
                questions={item}
                questionIndex={index}
                onAnswer={getAnswer}
                questionNumberingFormat={`${index + 1}.`}
                sliderMarkStyle={sliderQuestionMarksStyle}
                startPoint={0}
              />
            </div>
          ))}
          <div className="DesktopNumericalTestContainer__QuestionWrapper__Actions">
            <Button
              onClick={handleAnswer}
              size="large"
              disabled={blockedSubmit}
            >
              {t('test.submitAndProceed')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopNumericalTest;
