import './StageCardActive.scss';
import Icon from 'components/atoms/Icon';
import { appSwitchRoutes } from 'constants/routes';
import { ProjectModuleType } from 'generated/graphql';
import { differenceInCalendarDaysFns, isAfter } from 'helpers/time';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IStageCardActive {
  stageCandidateId: number;
  projectName: string;
  stageEndTime?: Date;
  companyLogoUrl?: string | undefined;
  companyName: string;
  doneFor?: string;
  projectModuleType: ProjectModuleType;
  stageName?: string;
  hasAdjustmentQuestionnaire?: boolean;
}

const StageCardActive: FC<IStageCardActive> = ({
  stageCandidateId,
  projectName,
  stageEndTime,
  companyLogoUrl,
  companyName,
  doneFor,
  projectModuleType,
  stageName,
  hasAdjustmentQuestionnaire,
}) => {
  const { t } = useTranslation();
  const link = hasAdjustmentQuestionnaire
    ? appSwitchRoutes.accessibilityAdjustments.getUrl(stageCandidateId, 'intro')
    : appSwitchRoutes.stage.getUrl(stageCandidateId);

  const isAssesmetExpirationNotificationVisible =
    stageEndTime &&
    isAfter(new Date(stageEndTime), new Date()) &&
    differenceInCalendarDaysFns(new Date(stageEndTime), new Date()) < 1;

  return (
    <Link
      to={link}
      aria-label={t('stages.assessmentStage', { projectName, companyName })}
      className="StageCardActive"
    >
      <div className="StageCardActive__Heading">
        {companyLogoUrl ? (
          <img
            className="StageCardActive__Heading__CompanyLogo"
            src={companyLogoUrl}
            alt={t('alt.logo')}
          />
        ) : (
          <span className="StageCardActive__Heading__CompanyName">
            {companyName}
          </span>
        )}
        {isAssesmetExpirationNotificationVisible ? <Icon icon="flame" /> : null}
      </div>
      <div className="StageCardActive__ArrowInCircle">
        <Icon icon="right_arrow" />
      </div>
      <p className="StageCardActive__Name">{projectName}</p>
      <p className="StageCardActive__Stage">
        {' '}
        {projectModuleType === ProjectModuleType.TalentReview
          ? doneFor
          : stageName}
      </p>
    </Link>
  );
};

export default StageCardActive;
