import Container from 'components/molecules/Container/Container';
import { useTestCandidateStartSzTestMutation } from 'generated/graphql';
import { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Loader } from '@spotted-zebra-uk/ui-components';
import DesktopPersonalityTest from '../PersonalityTest/DesktopPersonalityTest/DesktopPersonalityTest';
import MobilePersonalityTest from '../PersonalityTest/MobilePersonalityTest/MobilePersonalityTest';

interface ITRTest {
  testCandidateId: number;
  onFinishTest: (hideTpCallback?: boolean) => void;
}

const TRTest: FC<ITRTest> = ({ testCandidateId, onFinishTest }) => {
  const [testCandidateStartSzTestMutation, testCandidateStartSzTestResponse] =
    useTestCandidateStartSzTestMutation({
      variables: {
        testCandidateId,
      },
    });

  useEffect(() => {
    testCandidateStartSzTestMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (testCandidateStartSzTestResponse?.loading) {
    return (
      <Container className="page-loader-wrapper">
        <Loader variant="bubbles" />
      </Container>
    );
  }

  const assessmentId =
    testCandidateStartSzTestResponse.data?.TestCandidateStartSzTest
      ?.assessmentId;

  if (assessmentId) {
    return isMobile ? (
      <MobilePersonalityTest
        assessmentId={assessmentId}
        hideTpCallback={true}
        onFinishTest={onFinishTest}
      />
    ) : (
      <DesktopPersonalityTest
        assessmentId={assessmentId}
        hideTpCallback={true}
        onFinishTest={onFinishTest}
      />
    );
  }
  return null;
};

export default TRTest;
