import './styles.scss';
import Icon, { IconType } from 'components/atoms/Icon';
import { FC } from 'react';

interface IHeadingWithIcon {
  title: string;
  description?: string;
  icon: IconType;
  alt?: string;
  iconClassName?: string;
}

export const HeadingWithIcon: FC<IHeadingWithIcon> = ({
  title,
  description,
  icon,
  alt,
  iconClassName,
}) => (
  <div className="sz-header-with-icon" data-testid="heading-with-icon">
    <Icon
      icon={icon}
      className={iconClassName || 'sz-header-with-icon__icon'}
      alt={alt}
      aria-hidden
    />

    <div
      className="sz-header-with-icon__title"
      data-testid="heading-with-icon-title"
    >
      {title}
    </div>
    {description && (
      <div
        className="sz-header-with-icon__description"
        data-testid="heading-with-icon-description"
      >
        {description}
      </div>
    )}
  </div>
);
