import './styles.scss';
import Container from 'components/molecules/Container/Container';
import { ThankYouPageContentType } from 'components/organisms/ThankYouTemplate/ThankYouTemplate.enums';
import { appSwitchRoutes } from 'constants/routes';
import { FormType } from 'enums/form';
import { ModalTypes } from 'enums/modalTypes';
import { openModal } from 'graphql/modals';
import { useGetStageCompanyRespondantFormEffect } from 'hooks/useRespondantForm';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@spotted-zebra-uk/ui-components';
import ThankYouContentDefaultTemplate from '../ThankYouDefaultTemplate/ThankYouDefaultTemplate';

interface IFinishedStageWithoutReportContent {
  companyName: string;
  stageCandidateId: number;
}

const FinishedStageWithoutReportContent: FC<
  IFinishedStageWithoutReportContent
> = ({ companyName, stageCandidateId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(appSwitchRoutes.stages.path);
  };

  const [
    // eslint-disable-next-line no-unused-vars
    _getStageCompanyRespondantFormResult,
    isLoading,
  ] = useGetStageCompanyRespondantFormEffect(
    stageCandidateId,
    FormType.CD_FORM,
    getRespondantFormResult => {
      if (
        getRespondantFormResult?.data?.respondantForm &&
        getRespondantFormResult.data.respondantForm.respondantFieldWithAnswers
          .length &&
        !getRespondantFormResult.data.respondantForm.isComplete
      ) {
        openModal({
          modalType: ModalTypes.EQUALITY_AND_DIVERSITY_MODAL,
          modalProps: {
            stageCandidateId,
          },
        });
      }
    }
  );

  const mainText = t(
    'stage.testCallback.thankYouForCompletingTestWithoutReport',
    { companyName }
  );

  if (isLoading) {
    return (
      <Container className="finished-stage-without-report-content-loader-wrapper">
        <Loader variant="bubbles" />
      </Container>
    );
  }

  return (
    <ThankYouContentDefaultTemplate
      mainText={mainText}
      onClick={handleClick}
      contentType={ThankYouPageContentType.FINISHED_STAGE_WITHOUT_REPORT}
    />
  );
};

export default FinishedStageWithoutReportContent;
