import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  RadioButtonGroup,
  RadioButtonOption,
  BarChartWithBorder,
} from '@spotted-zebra-uk/ui-components';
import { SKILL_ANSWER_STRINGS } from 'helpers/skillsProficiency';

interface ISkillsLevelQuestionnaire {
  id: number;
  title: string;
  selectedAnswerValue?: TBarChartValue;
  gradeBands: { proficiency: TBarChartValue }[];
  description?: string;
  summaryButtonVisible: boolean;
  summaryButtonDisabled: boolean;
  onSelectAnswer: (selectedAnswerValue?: TBarChartValue) => void;
  onSummaryButtonClick: () => void;
}

const SkillsLevelQuestionnaire: FC<ISkillsLevelQuestionnaire> = ({
  id,
  title,
  selectedAnswerValue,
  gradeBands,
  description,
  summaryButtonVisible,
  summaryButtonDisabled,
  onSelectAnswer,
  onSummaryButtonClick,
}) => {
  const { t } = useTranslation();

  const proficiencyOptions: RadioButtonOption[] = useMemo(() => {
    return gradeBands.map(gradeBand => {
      return {
        id: `skill-proficiency-option-${gradeBand.proficiency}`,
        label: t(SKILL_ANSWER_STRINGS[gradeBand.proficiency]),
        value: String(gradeBand.proficiency),
        image: <BarChartWithBorder value={gradeBand.proficiency} />,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradeBands]);

  const handleProficiencyChange = (newValue: string) => {
    const newSelectedValue = parseInt(newValue) as TBarChartValue;
    onSelectAnswer(newSelectedValue);
  };

  const handleClick = (
    newValue: string | undefined,
    id: string | undefined
  ) => {
    if (newValue) {
      handleProficiencyChange(newValue);
    }
  };

  return (
    <div className="skills-level-questionnaire">
      <div className="skills-level-questionnaire__heading">
        <div className="skills-level-questionnaire__title">{title}</div>
        <div className="skills-level-questionnaire__description">
          {description}
        </div>
      </div>
      <div className="skills-level-questionnaire__main-content">
        <div className="skills-level-questionnaire__note">
          {!selectedAnswerValue && t('skillLevelNotSet')}
        </div>
        <div className="skills-level-questionnaire__answer-options">
          <RadioButtonGroup
            id={`skill-proficiency-mobile-group-${id}`}
            name={`skillProficiency-${id}`}
            value={String(selectedAnswerValue)}
            options={proficiencyOptions}
            ariaLabel={t('skills.selectYourSkillLevelFor', {
              skillName: title,
            })}
            radioButtonClassName="skills-level-questionnaire__answer-options-option"
            onChange={handleProficiencyChange}
            onClick={handleClick}
          />
        </div>
        <div className="skills-level-questionnaire__actions">
          {summaryButtonVisible && (
            <Button
              className="skills-level-questionnaire__actions-button"
              disabled={summaryButtonDisabled}
              onClick={onSummaryButtonClick}
            >
              {t('common.proceedToSummary')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SkillsLevelQuestionnaire;
