import StageActionThankYouPanel from 'components/organisms/StageActionThankYouPanel/StageActionThankYouPanel';
import { GetStageActionsQuery } from 'generated/graphql';
import { ITestCallbackPath } from 'interfaces/routes';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import FinishedPracticeTestContent from './FinishedPracticeTestContent';

interface IHiringThankYouContent {
  isPractice: boolean;
  stageActionsData: GetStageActionsQuery;
}

const HiringThankYouContent: FC<IHiringThankYouContent> = ({
  isPractice,
  stageActionsData,
}) => {
  const { stageCandidateId, testCandidateId } =
    useParams() as ITestCallbackPath;

  if (isPractice) {
    return (
      <FinishedPracticeTestContent
        testCandidateId={Number(testCandidateId)}
        stageCandidateId={Number(stageCandidateId)}
      />
    );
  }

  return (
    <StageActionThankYouPanel
      stageActionsData={stageActionsData}
      stageCandidateId={Number(stageCandidateId)}
    />
  );
};

export default HiringThankYouContent;
