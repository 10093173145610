import { useUserQuery } from 'generated/graphql';
import { getAccessToken } from 'helpers/auth';
import { analyticsClient } from '@spotted-zebra-uk/ui-components';

const UserWrapper = ({ children }: { children: React.ReactNode }) => {
  const accessToken = getAccessToken();
  useUserQuery({
    fetchPolicy: 'network-only',
    skip: !accessToken,
    onCompleted: data => {
      if (data.User.subId) {
        analyticsClient.setAnalyticsContext(
          {
            subId: data.User.subId,
            roles: data.User.roles,
            isInternal: data.User.isInternal,
          },
          undefined,
          false
        );
      }
    },
    onError: console.error,
  });

  return <>{children}</>;
};

export default UserWrapper;
