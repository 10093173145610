import { IconType } from 'components/atoms/Icon';
import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import Container from 'components/molecules/Container/Container';
import { HeadingWithIcon } from 'components/molecules/HeadingWithIcon';
import NavbarWithBackLink from 'components/molecules/NavbarWithBackLink/NavbarWithBackLink';
import { stageSwitchRoutes } from 'constants/routes';
import { IRoleSpecificSkillAssessmentPath } from 'interfaces/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TSkillWithAnswer } from '../types';
import Assessment from './Assessment';

interface IRoleSpecificSoftSkillsAssessmentDesktop {
  headingTitle: string;
  headingIcon: IconType;
  headingDescription: string;
  onSubmit: (skillsWithAnswers: TSkillWithAnswer[]) => void;
  loading: boolean;
  initialSkillsWithAnswers: TSkillWithAnswer[];
  gradeBands: {
    proficiency: TBarChartValue;
  }[];
  hasMarkAllButton?: boolean;
  skillName: string;
}

const RoleSpecificSoftSkillsAssessmentDesktop: FC<
  IRoleSpecificSoftSkillsAssessmentDesktop
> = ({
  headingDescription,
  headingIcon,
  headingTitle,
  initialSkillsWithAnswers,
  gradeBands,
  loading,
  hasMarkAllButton,
  skillName,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { stageCandidateId, rssAssessmentCandidateId, rssAssessmentType } =
    useParams() as IRoleSpecificSkillAssessmentPath;
  const parsedStageCandidateId = Number(stageCandidateId);
  const parsedRssAssessmentCandidateId = Number(rssAssessmentCandidateId);

  return (
    <Container className="role-specific-soft-skills-assessment">
      <NavbarWithBackLink
        to={stageSwitchRoutes.skillsSelfAssessmentIntro.getUrl(
          parsedStageCandidateId,
          parsedRssAssessmentCandidateId,
          rssAssessmentType as string
        )}
        ariaLabel={t('assessment.returnToAllAssessmentActions')}
        label={t('assessment.actions')}
      />
      <div className="role-specific-soft-skills-assessment__content">
        <HeadingWithIcon
          title={headingTitle}
          icon={headingIcon}
          description={headingDescription}
          alt=""
        />
        <Assessment
          initialSkillsWithAnswers={initialSkillsWithAnswers}
          gradeBands={gradeBands}
          onSubmit={onSubmit}
          loading={loading}
          hasMarkAllButton={hasMarkAllButton}
          skillName={skillName}
        />
      </div>
    </Container>
  );
};

export default RoleSpecificSoftSkillsAssessmentDesktop;
