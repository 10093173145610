import './InductiveTestQuestionAnswers.scss';
import { FC, useMemo } from 'react';
import { TInductiveTestOptions } from '../../inductiveTestTypes';
import SVGRenderer from 'components/molecules/SVGRenderer/SVGRenderer';
import { useTranslation } from 'react-i18next';
import {
  Loader,
  RadioButtonGroup,
  RadioButtonOption,
} from '@spotted-zebra-uk/ui-components';

interface IInductiveTestQuestionAnswers {
  inductiveTestAnswers: TInductiveTestOptions[];
  onSetPreview: (answerId: string, answerImg: string) => void;
  selectedAnswerId: string | undefined;
}

const ITEM_MAX_SIZE = 60;

const InductiveTestQuestionAnswers: FC<IInductiveTestQuestionAnswers> = ({
  inductiveTestAnswers,
  onSetPreview,
  selectedAnswerId,
}) => {
  const { t } = useTranslation();

  const getSvgImageOption = (answerImg: string) => {
    return (
      <SVGRenderer
        key={answerImg}
        src={answerImg}
        alt={t('alt.inductiveTestQA')}
        size={{
          width: ITEM_MAX_SIZE - 2,
          height: ITEM_MAX_SIZE - 2,
        }}
        loading={() => <Loader />}
      />
    );
  };

  const options: RadioButtonOption[] = useMemo(
    () =>
      inductiveTestAnswers.map(answer => {
        return {
          id: `inductive-test-answer-${answer.key}`,
          value: answer.subId,
          image: getSvgImageOption(answer.imageUrl),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inductiveTestAnswers]
  );

  const getAnswerImage = (answerId: string) => {
    return inductiveTestAnswers.find(answer => answer.subId === answerId)
      ?.imageUrl;
  };

  const handleChange = (answerId: string) => {
    const answerImage = getAnswerImage(answerId);
    if (answerImage) {
      onSetPreview(answerId, answerImage);
    }
  };

  return (
    <div className="InductiveTestQuestionAnswers">
      {options.length ? (
        <RadioButtonGroup
          id="inductive-test-answers"
          name="inductiveTestAnswers"
          value={selectedAnswerId}
          options={options}
          layout="horizontal"
          onChange={handleChange}
          withSquareOutline
          className="InductiveTestQuestionAnswers__radioGroup"
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default InductiveTestQuestionAnswers;
