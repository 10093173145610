import { ReactComponent as SpottedZebraLogo } from 'assets/logos/SpottedZebraLogo.svg';
import { FC } from 'react';

interface IBaseCompanyLogo {
  className?: string;
}

const BaseCompanyLogo: FC<IBaseCompanyLogo> = ({ className }) => {
  return <SpottedZebraLogo className={className} />;
};

export default BaseCompanyLogo;
