import { ReactNode } from 'react';
import { isMobile as rddIsMobile } from 'react-device-detect';

export const MobileView = ({ children } : { children: ReactNode }) => {
  return rddIsMobile ? <>{children}</> : null;
};

export const BrowserView = ({ children } : { children: ReactNode }) => {
  return !rddIsMobile ? <>{children}</> : null;
};

export const isMobile = () => rddIsMobile;
