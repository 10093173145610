import { useWindowSize } from 'hooks/containerSize';
import { CSSProperties, FC, ReactNode } from 'react';

interface IContainer {
  className?: string;
  vh?: number;
  dataTestId?: string;
  style?: CSSProperties;
  children: ReactNode;
}

const Container: FC<IContainer> = ({
  className,
  vh = 100,
  children,
  dataTestId,
  style,
}) => {
  const [size] = useWindowSize();

  return (
    <div
      style={{ minHeight: size.height * 0.01 * vh, ...style }}
      className={`Container${className ? ` ${className}` : ''}`}
      data-test-id={dataTestId}
    >
      {children}
    </div>
  );
};

export default Container;
