import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType, Modal, ModalSize } from '@spotted-zebra-uk/ui-components';
import { TSkillWithAnswer } from '../types';
import {
  SkillsLevelSelection,
  TSkillsLevelSelectionMode,
} from './SkillsLevelSelection';
import { SkillsList } from './SkillsList';

interface IAssessment {
  initialSkillsWithAnswers: TSkillWithAnswer[];
  gradeBands: {
    proficiency: TBarChartValue;
  }[];
  loading: boolean;
  hasMarkAllButton?: boolean;
  skillName: string;
  onSubmit: (skillsWithAnswers: TSkillWithAnswer[]) => void;
}

const Assessment: FC<IAssessment> = ({
  initialSkillsWithAnswers,
  gradeBands,
  loading,
  hasMarkAllButton,
  skillName,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [focusedSkillIndex, setFocusedSkillIndex] = useState(0);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [skillsWithAnswers, setSkillsWithAnswers] = useState<
    TSkillWithAnswer[]
  >(initialSkillsWithAnswers);
  const [skillsLevelSelectionMode, setSkillsLevelSelectionMode] =
    useState<TSkillsLevelSelectionMode>('active');

  const focusNextSkill = () => {
    const newIndex = focusedSkillIndex + 1;

    if (newIndex < skillsWithAnswers.length) {
      return setFocusedSkillIndex(newIndex);
    }

    const unansweredSkill = skillsWithAnswers.findIndex(
      skill => !skill.answerValue
    );

    // When the last skill in "Skills" array is focused and user clicks "Next skill",
    // if there is unanswered skill that one should be focused next,
    // if there is no unanswered skill the "completed" screen should be visible
    unansweredSkill !== -1
      ? setFocusedSkillIndex(unansweredSkill)
      : setSkillsLevelSelectionMode('completed');
  };

  const focusPreviousSkill = () => {
    if (skillsLevelSelectionMode === 'completed') {
      return setSkillsLevelSelectionMode('active');
    }

    const newIndex = focusedSkillIndex - 1;
    newIndex >= 0 && setFocusedSkillIndex(newIndex);
  };

  const selectAnswerValue = (selectedAnswerValue?: TBarChartValue) => {
    const newSkillsWithAnswers = skillsWithAnswers.map(
      (skillWithAnswer, index) =>
        index === focusedSkillIndex
          ? {
              ...skillWithAnswer,
              answerValue: selectedAnswerValue,
            }
          : skillWithAnswer
    );

    setSkillsWithAnswers(newSkillsWithAnswers);

    const newIndex = focusedSkillIndex + 1;
    if (newIndex < skillsWithAnswers.length) {
      return setFocusedSkillIndex(newIndex);
    }

    const unansweredSkill = newSkillsWithAnswers.findIndex(
      skill => !skill.answerValue
    );

    // When user select answer on the last skill in "Skills" array,
    // if there is unanswered skill that one should be focused next,
    // if there is no unanswered skill the "completed" screen should be visible
    unansweredSkill !== -1
      ? setFocusedSkillIndex(unansweredSkill)
      : setSkillsLevelSelectionMode('completed');
  };

  const handleSkillsListItemClick = (skillWithAnswerId: number) => {
    const newFocusedSkillIndex = skillsWithAnswers.findIndex(
      skillWithAnswer => skillWithAnswer.id === skillWithAnswerId
    );

    if (
      newFocusedSkillIndex !== -1 &&
      newFocusedSkillIndex !== focusedSkillIndex
    ) {
      setFocusedSkillIndex(newFocusedSkillIndex);
    }
  };

  const handleMarkAllAsNoKnowledge = () => {
    setIsModalOpened(true);
  };

  const handleMarkAllSkillsAsNoKnowledge = () => {
    const newSkillsWithAnswers = skillsWithAnswers.map(skillWithAnswer => ({
      ...skillWithAnswer,
      answerValue: 1 as TBarChartValue,
    }));
    setSkillsWithAnswers(newSkillsWithAnswers);
    setSkillsLevelSelectionMode('completed');
    setIsModalOpened(false);
  };

  const handleSubmit = () => {
    onSubmit(skillsWithAnswers);
  };

  const focusedSkill = skillsWithAnswers[focusedSkillIndex];

  return (
    <div className="assessment">
      <SkillsList
        focusedSkillId={focusedSkill?.id}
        skillsWithAnswers={skillsWithAnswers}
        loading={loading}
        hasMarkAllButton={hasMarkAllButton}
        onItemClick={handleSkillsListItemClick}
        onMarkAllAsNoKnowledge={handleMarkAllAsNoKnowledge}
      />
      <SkillsLevelSelection
        onPreviousSkillButtonClick={focusPreviousSkill}
        onNextSkillButtonClick={focusNextSkill}
        disablePreviousSkillButton={
          focusedSkillIndex === 0 && skillsLevelSelectionMode === 'active'
        }
        disableNextSkillButton={false}
        questionnaireTitle={focusedSkill?.name}
        selectedAnswerValue={focusedSkill?.answerValue}
        questionnaireDescription={focusedSkill?.description}
        selectAnswerValue={selectAnswerValue}
        gradeBands={gradeBands}
        mode={skillsLevelSelectionMode}
        onSubmit={handleSubmit}
        loading={loading}
        skillName={skillName}
      />
      <Modal
        isOpen={isModalOpened}
        modalSize={ModalSize.SMALL}
        onClose={() => setIsModalOpened(false)}
        headerIconType={IconType.WARNING}
        header={t('common.areYouSure')}
        showCloseIcon={false}
        buttonsProps={{
          confirmButtonProps: {
            ariaLabel: t('assessment.markAllSkillsAsNoKnowledge'),
          },
          cancelButtonProps: {
            ariaLabel: t('common.cancel'),
          },
          onConfirmButtonClick: handleMarkAllSkillsAsNoKnowledge,
        }}
      >
        <div>{t('dialogue.markAllAsNoKnowledge')}</div>
      </Modal>
    </div>
  );
};

export default Assessment;
