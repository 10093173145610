import { ThankYouPageContentType } from 'components/organisms/ThankYouTemplate/ThankYouTemplate.enums';
import { appSwitchRoutes } from 'constants/routes';
import { ProjectModuleType } from 'generated/graphql';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ThankYouContentDefaultTemplate from '../ThankYouDefaultTemplate/ThankYouDefaultTemplate';

export interface INextTalentReviewTestContent {
  stageCandidateId: number;
  projectModuleType?: ProjectModuleType;
}

const NextTalentReviewTestContent: FC<INextTalentReviewTestContent> = ({
  stageCandidateId,
  projectModuleType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNextTestClick = () => {
    navigate(appSwitchRoutes.stage.getUrl(stageCandidateId), { replace: true });
  };

  const mainText = t('stage.testCallback.goodProgress');
  const buttonText = t('test.instructions.general.continue');

  return (
    <ThankYouContentDefaultTemplate
      mainText={mainText}
      onClick={handleNextTestClick}
      buttonText={buttonText}
      projectModuleType={projectModuleType}
      contentType={ThankYouPageContentType.NEXT_TEST_CONTENT}
    />
  );
};

export default NextTalentReviewTestContent;
