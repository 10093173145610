import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { isMobile } from 'helpers/deviceDetect';
import { FC } from 'react';
import CandidateInformationDesktop from './CandidateInformationDesktop/CandidateInformationDesktop';
import CandidateInformationMobile from './CandidateInformationMobile/CandidateInformationMobile';

export type TCandidateInformationModal = {
  stageCandidateId: number;
};

const CandidateInformationModal: FC<TCandidateInformationModal> = ({
  stageCandidateId,
}) => {
  const handleCloseModal = () => {
    closeModal(ModalTypes.CANDIDATE_INFORMATION);
  };

  if (!isMobile()) {
    return (
      <CandidateInformationDesktop
        onCloseButtonClick={handleCloseModal}
        stageCandidateId={stageCandidateId}
        onCandidateInformationProvided={handleCloseModal}
        onCandidateInformationFormPreviouslyCompleted={handleCloseModal}
      />
    );
  } else {
    return (
      <CandidateInformationMobile
        onCloseModal={handleCloseModal}
        stageCandidateId={stageCandidateId}
      />
    );
  }
};

export default CandidateInformationModal;
