import { ModalTypes } from 'enums/modalTypes';
import { GET_MODALS_QUERY } from 'graphql/modals';
import { IGetModalsQueryResponse } from 'interfaces/modal';
import { FC } from 'react';
import CandidateInformationModal from 'views/CandidateInformation/CandidateInformationModal/CandidateInformationModal';
import EqualityAndDiversityModal from 'views/EqualityAndDiversity/EqualityAndDiversityModal';
import { useQuery } from '@apollo/client';
import { NotificationModal } from '@spotted-zebra-uk/ui-components';

const ModalView: FC = () => {
  const modalsQueryResponse =
    useQuery<IGetModalsQueryResponse>(GET_MODALS_QUERY);

  if (modalsQueryResponse.data?.modals.length) {
    const { modals } = modalsQueryResponse.data;

    return (
      <>
        {modals.map(modal => {
          switch (modal.modalType) {
            case ModalTypes.CANDIDATE_INFORMATION:
              return (
                <CandidateInformationModal
                  key={modal.modalType}
                  {...modal.modalProps}
                />
              );
            case ModalTypes.EQUALITY_AND_DIVERSITY_MODAL:
              return (
                <EqualityAndDiversityModal
                  key={modal.modalType}
                  {...modal.modalProps}
                />
              );
            case ModalTypes.NOTIFICATION_MODAL:
              return (
                <NotificationModal
                  key={modal.modalType}
                  {...modal.modalProps}
                />
              );
            default:
              return null;
          }
        })}
      </>
    );
  }

  return null;
};

export default ModalView;
