import { FormType } from 'generated/graphql';

export const appSwitchRoutes = {
  root: {
    path: '/',
    getUrl: () => '/',
  },
  authentication: {
    path: '/auth/*',
    getUrl: () => '/auth',
  },
  stages: {
    path: '/stages',
    getUrl: () => '/stages',
  },
  stage: {
    path: '/stages/:stageCandidateId',
    getUrl: (stageCandidateId: number | string) =>
      `/stages/${stageCandidateId}`,
  },
  tpCallback: {
    path: '/stages/:stageCandidateId/tp-callback',
    getUrl: (stageCandidateId: number | string) =>
      `/stages/${stageCandidateId}/tp-callback`,
  },
  externalService: {
    path: '/external-service',
    getUrl: () => '/external-service',
  },
  indirectInvitation: {
    path: '/inv',
    getUrl: () => '/inv',
  },
  companyNavigation: {
    path: '/company',
    getUrl: () => '/company',
  },
  onboarding: {
    path: '/onboarding',
    getUrl: () => '/onboarding',
  },
  accessibilityAdjustments: {
    path: '/stages/:stageCandidateId/accessibility-adjustments/:step',
    getUrl: (stageCandidateId: number | string, step: string) =>
      `/stages/${stageCandidateId}/accessibility-adjustments/${step}`,
  },
  personalSkillsProfile: {
    path: '/personal-skills-profile',
  },
  codingAssessmentThankYou: {
    path: '/stages/coding-test-thank-you',
  },
};

export const stageSwitchRoutes = {
  stage: {
    path: appSwitchRoutes.stage.path,
    getUrl: appSwitchRoutes.stage.getUrl,
  },
  trform: {
    path: '/stages/:stageId/form/TRForm/:ownerId/:stageCandidateId',
    getUrl: ({
      companyId,
      stageCandidateId,
      stageId,
    }: {
      companyId: number | string;
      stageCandidateId: number | string;
      stageId: number | string;
    }) => `${stageId}/form/TRForm/${companyId}/${stageCandidateId}`,
  },
  tpCallback: {
    path: appSwitchRoutes.tpCallback.path,
    getUrl: appSwitchRoutes.tpCallback.getUrl,
  },
  test: {
    path: '/stages/:stageCandidateId/test/:testCandidateId',
    getUrl: (stageCandidateId: number | string, testId: number | string) =>
      `/stages/${stageCandidateId}/test/${testId}`,
  },
  candidateInformation: {
    path: '/stages/:stageCandidateId/candidate-information',
    getUrl: (stageCandidateId: number | string) =>
      `/stages/${stageCandidateId}/candidate-information`,
  },
  calibrate: {
    path: '/stages/:stageCandidateId/calibrate',
    getUrl: (stageCandidateId: number | string) =>
      `/stages/${stageCandidateId}/calibrate`,
  },
  practiceTestCallback: {
    path: '/stages/:stageCandidateId/tp-callback/practice/:testCandidateId',
    getUrl: (
      stageCandidateId: number | string,
      testCandidateId: number | string
    ) => `/stages/${stageCandidateId}/tp-callback/practice/${testCandidateId}`,
  },
  stageActionForm: {
    path: '/stages/:stageId/form/:formType/:ownerId/:stageCandidateId',
    getUrl: ({
      companyId,
      stageCandidateId,
      stageId,
      formType,
    }: {
      companyId: number | string;
      stageCandidateId: number | string;
      stageId: number | string;
      formType: FormType;
    }) =>
      `/stages/${stageId}/form/${formType}/${companyId}/${stageCandidateId}`,
  },
  skillsSelfAssessmentIntro: {
    path: '/stages/:stageCandidateId/skills-self-assessment-intro/:rssAssessmentCandidateId/:rssAssessmentType',
    getUrl: (
      stageCandidateId: string | number,
      rssAssessmentCandidateId: string | number,
      rssAssessmentType: string
    ) =>
      `/stages/${stageCandidateId}/skills-self-assessment-intro/${rssAssessmentCandidateId}/${rssAssessmentType}`,
  },
  deiIntro: {
    path: '/stages/:stageCandidateId/dei-intro',
    getUrl: (stageCandidateId: string | number) =>
      `/stages/${stageCandidateId}/dei-intro`,
  },
  deiForm: {
    path: '/stages/:stageCandidateId/dei-form',
    getUrl: (stageCandidateId: string | number) =>
      `/stages/${stageCandidateId}/dei-form`,
  },
  roleSpecificSkillsAssessment: {
    path: '/stages/:stageCandidateId/role-specific-skills-assessment/:rssAssessmentCandidateId/:rssAssessmentType',
    getUrl: (
      stageCandidateId: string | number,
      rssAssessmentCandidateId: string | number,
      rssAssessmentType: string
    ) =>
      `/stages/${stageCandidateId}/role-specific-skills-assessment/${rssAssessmentCandidateId}/${rssAssessmentType}`,
  },
  skillsSelfAssessmentThankYou: {
    path: '/stages/:stageCandidateId/skills-self-assessment-thank-you',
    getUrl: (stageCandidateId: string | number) =>
      `/stages/${stageCandidateId}/skills-self-assessment-thank-you`,
  },
  codingAssessmentIntro: {
    path: '/stages/:stageCandidateId/coding-test-intro/:eaStageActionCandidateId',
    getUrl: (
      stageCandidateId: string | number,
      eaStageActionCandidateId: string | number | undefined
    ) =>
      `/stages/${stageCandidateId}/coding-test-intro/${eaStageActionCandidateId}`,
  },
  codingAssessmentThankYou: {
    path: '/stages/coding-test-thank-you',
    getUrl: () => '/stages/coding-test-thank-you',
  },
};
