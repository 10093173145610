import { appSwitchRoutes } from 'constants/routes';
import { useAcceptIndirectInvitationMutation } from 'generated/graphql';
import { getAccessToken } from 'helpers/auth';
import { parseIndirectInvitationUrl } from 'helpers/indirectInvitation';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export const IndirectInvitation: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleMsgType } = useNotification();

  const indirectInvitationData = parseIndirectInvitationUrl(location.search);

  const [acceptIndirectInvitationMutation] =
    useAcceptIndirectInvitationMutation({
      onCompleted: data => {
        const accessToken = getAccessToken();
        if (
          indirectInvitationData &&
          accessToken &&
          data.AcceptIndirectInvitation
        ) {
          navigate(
            appSwitchRoutes.accessibilityAdjustments.getUrl(
              data.AcceptIndirectInvitation.id,
              'intro'
            )
          );
        }
      },
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });

        navigate('/');
      },
    });

  useEffect(() => {
    if (indirectInvitationData) {
      acceptIndirectInvitationMutation({
        variables: {
          projectId: indirectInvitationData.projectId,
          stageId: indirectInvitationData.stageId,
          hiringManagers: indirectInvitationData.hiringManagers,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader isPageLoader />;
};
