import Loader from 'components/atoms/Loader/Loader';
import {
  setTheme,
  Theme,
} from 'components/organisms/ThemeProvider/ThemeProvider';
import { AUTHENITCATE_EXTERNAL_MUTATION } from 'graphql/externalAuth';
import { setAccessToken, setRefreshToken } from 'helpers/auth';
import {
  ExternalAuthenticationActions,
  getExternalAuthenticationUrlParams,
} from 'helpers/externalAuthentication';
import {
  IAuthenticateExternalInput,
  IAuthenticateExternalResponse,
} from 'interfaces/externalAuth';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface IExternalServiceLanding {}

const ExternalServiceLanding: FC<IExternalServiceLanding> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleMsgType } = useNotification();

  const { firstName, lastName, action, stageId, projectId, reportId, token } =
    getExternalAuthenticationUrlParams(location.search);

  const [externalAuthenticateMutation] = useMutation<
    IAuthenticateExternalResponse,
    IAuthenticateExternalInput
  >(AUTHENITCATE_EXTERNAL_MUTATION, {
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate('/auth/login');
    },
    onCompleted: data => {
      if (data) {
        setTheme(Theme.BLUE);

        setAccessToken(data.authenticateExternal.accessToken);
        setRefreshToken(data.authenticateExternal.refreshToken);

        if (action === ExternalAuthenticationActions.INVITE) {
          const indirectInvitationUrl = `/inv?stageId=${stageId}&project=${projectId}`;
          navigate(indirectInvitationUrl);
        }

        if (action === ExternalAuthenticationActions.REPORT) {
          const reportUrl = `/candidate-report/${reportId}`;
          navigate(reportUrl);
        }
      }
    },
  });

  useEffect(() => {
    externalAuthenticateMutation({
      variables: {
        firstName: firstName || '',
        lastName: lastName || '',
        token,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader color="Secondary" isOverlay />;
};

export default ExternalServiceLanding;
