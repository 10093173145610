import { StageCardStatus } from 'enums/stageCardStatus.enum';
import { ProjectModuleType } from 'generated/graphql';
import { FC } from 'react';
import StageCardActive from './StageCardActive/StageCardActive';
import StageCardCompleted from './StageCardCompleted/StageCardCompleted';
import StageCardInactive from './StageCardInactive/StageCardInactive';

export type TStageCardProps = {
  projectName: string;
  stageCandidateId: number;
  score?: number;
  scoreString?: string;
  status: StageCardStatus;
  candidateReportId?: string;
  companyName: string;
  companyLogoUrl?: string | undefined;
  stageName: string;
  stageEndTime?: Date;
  renderCandidateReport?: boolean;
  inspectable: boolean;
  doneFor?: string;
  projectModuleType: ProjectModuleType;
  hasAdjustmentQuestionnaire?: boolean;
};

const StageCard: FC<TStageCardProps> = ({
  projectName,
  stageCandidateId,
  score,
  scoreString,
  status,
  companyName,
  companyLogoUrl,
  candidateReportId,
  stageName,
  stageEndTime,
  renderCandidateReport,
  inspectable,
  doneFor,
  projectModuleType,
  hasAdjustmentQuestionnaire,
}) => {
  if (status === StageCardStatus.COMPLETED) {
    if (!inspectable) {
      return (
        <StageCardCompleted
          candidateReportId={candidateReportId}
          score={score}
          scoreString={scoreString}
          projectName={projectName}
          stageName={stageName}
          companyLogoUrl={companyLogoUrl}
          companyName={companyName}
          renderCandidateReport={renderCandidateReport}
        />
      );
    } else {
      <StageCardActive
        stageCandidateId={stageCandidateId}
        projectName={projectName}
        companyLogoUrl={companyLogoUrl}
        companyName={companyName}
        doneFor={doneFor}
        projectModuleType={projectModuleType}
        stageEndTime={stageEndTime}
        stageName={stageName}
        hasAdjustmentQuestionnaire={hasAdjustmentQuestionnaire}
      />;
    }
  }

  if (
    status === StageCardStatus.EXPIRED ||
    status === StageCardStatus.SCHEDULED ||
    status === StageCardStatus.SIGNED_OFF
  ) {
    return (
      <StageCardInactive
        projectName={projectName}
        stageName={stageName}
        companyLogoUrl={companyLogoUrl}
        companyName={companyName}
        status={status}
      />
    );
  }

  return (
    <StageCardActive
      stageCandidateId={stageCandidateId}
      projectName={projectName}
      companyLogoUrl={companyLogoUrl}
      doneFor={doneFor}
      projectModuleType={projectModuleType}
      companyName={companyName}
      stageEndTime={stageEndTime}
      stageName={stageName}
      hasAdjustmentQuestionnaire={hasAdjustmentQuestionnaire}
    />
  );
};

export default StageCard;
