import './MobilePersonalityTestSlider.scss';
import { ReactComponent as TwoSideErrorIcon } from 'assets/icons/TwoSideArrow.svg';
import { PersonalityTestsAnswers } from 'enums/tests';
import { PersonalityOptionModel } from 'generated/graphql';
import useContainerSize from 'hooks/containerSize';
import { FC, useState } from 'react';
import PersonalityTestSlider from '../../../components/PersonalityTestSlider/PersonalityTestSlider';
import {
  personalityTestMarks,
  SLIDER_LEGEND_BEAK_POINT,
} from './MobilePersonalityTestSlider.constants';

interface IMobilePersonalityTestSlider {
  question: {
    options: PersonalityOptionModel[];
    subId: string;
    text: string;
  };
  onAnswerChange: (
    newAnswerValue: PersonalityTestsAnswers,
    questionId: string,
    answerSubId: string
  ) => void;
}

const MobilePersonalityTestSlider: FC<IMobilePersonalityTestSlider> = ({
  question,
  onAnswerChange,
}) => {
  const [sliderContainerSize, sliderContainerRef] = useContainerSize();
  const isFullLegend = sliderContainerSize.width > SLIDER_LEGEND_BEAK_POINT;

  const [answerValue, setAnswerValue] = useState<number>(
    PersonalityTestsAnswers.NEUTRAL
  );

  const handleAnswerChange = (
    newAnswerValue: PersonalityTestsAnswers | undefined
  ) => {
    if (newAnswerValue !== undefined) {
      const optionSubId = question.options[newAnswerValue].subId;
      setAnswerValue(newAnswerValue);
      onAnswerChange(newAnswerValue, question.subId, optionSubId);
    }
  };

  return (
    <div
      ref={sliderContainerRef}
      className={`MobilePersonalityTestSlider${
        isFullLegend ? ' MobilePersonalityTestSlider--FullLegend' : ''
      }`}
    >
      <PersonalityTestSlider
        value={answerValue}
        onChange={handleAnswerChange}
        marks={isFullLegend ? personalityTestMarks : undefined}
        id={`desktop-personality-test-question-slider-${question.subId}`}
        ariaLabel={question.text}
        marksVisible={isFullLegend}
        options={question.options}
      />
      {!isFullLegend ? (
        <div className="MobilePersonalityTestSlider__Legend">
          <p className="MobilePersonalityTestSlider__Legend__Item">
            {question.options[0].text}
          </p>
          <TwoSideErrorIcon />
          <p className="MobilePersonalityTestSlider__Legend__Item">
            {question.options[6].text}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default MobilePersonalityTestSlider;
