import { appSwitchRoutes } from 'constants/routes';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Link, RadioButton } from '@spotted-zebra-uk/ui-components';
import { steps } from '../AccessibilityAdjustments.helpers';
import styles from './AccessibilityIntro.module.scss';

const learnMoreLink =
  'https://communications.spottedzebra.co.uk/knowledge-base/can-you-make-reasonable-adjustments';

const AccessibilityIntro = ({
  companyName,
  stageCandidateId,
  onContinue,
  onCancelFlow,
  loading,
}: {
  companyName: string;
  stageCandidateId: number;
  onContinue: (step: steps) => void;
  onCancelFlow: (redirectUrl: string) => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [requiresAdjustments, setRequiresAdjustments] = useState<string>('');
  const [requiresAdjustmentsError, setRequiresAdjustmentsError] =
    useState<string>('');
  const options = [
    { id: 'yes', label: t('accessibilityAdjustments.intro.yes'), value: 'yes' },
    { id: 'no', label: t('accessibilityAdjustments.intro.no'), value: 'no' },
  ];

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: string | undefined
  ) => {
    if (requiresAdjustmentsError) {
      setRequiresAdjustmentsError('');
    }
    setRequiresAdjustments(e.target.value || '');
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    // Throw error if no option selected before clicking Continue
    if (!requiresAdjustments) {
      setRequiresAdjustmentsError(t('accessibilityAdjustments.intro.error'));
      return;
    }
    // Close Questionnaire flow if "No" answer is selected
    if (requiresAdjustments === 'no') {
      onCancelFlow(appSwitchRoutes.stage.getUrl(stageCandidateId));
    }
    onContinue('intro');
    const nextPage =
      requiresAdjustments === 'yes'
        ? appSwitchRoutes.accessibilityAdjustments.getUrl(
            stageCandidateId,
            'options'
          )
        : appSwitchRoutes.stage.getUrl(stageCandidateId);
    navigate(nextPage);
  };

  return (
    <section className={styles.container} data-testid="accessibility-intro">
      <p className={styles.description}>
        {t('accessibilityAdjustments.intro.description')}
      </p>
      <Link to={learnMoreLink} target="_blank">
        {t('accessibilityAdjustments.intro.learnAboutLink')}
      </Link>
      <form className={styles.formContainer} onSubmit={handleSubmit}>
        <h2 className={styles.title}>
          {t('accessibilityAdjustments.intro.formQuestion')}
        </h2>
        <p className={styles.formDescription}>
          {t('accessibilityAdjustments.intro.formDescription', {
            companyName: companyName,
          })}
        </p>
        {options.map((item, index) => (
          <div
            data-testid={`accessibility-intro__checkbox-${item.value}`}
            key={index}
          >
            <RadioButton
              name={`requiresAdjustments-${item.label}`}
              id={item.id}
              onChange={handleChange}
              value={item.value}
              checked={requiresAdjustments === item.value}
              className={styles.checkbox}
              hasError={Boolean(requiresAdjustmentsError)}
            >
              {item.label}
            </RadioButton>
          </div>
        ))}
        {requiresAdjustmentsError && (
          <p
            className={styles.error}
            data-testid="accessibility-intro__submit-error"
          >
            {requiresAdjustmentsError}
          </p>
        )}
        <Button
          onClick={handleSubmit}
          className={styles.cta}
          type="submit"
          data-testid="accessibility-intro__submit-button"
          loading={loading}
        >
          {t(`common.continue`)}
        </Button>
      </form>
    </section>
  );
};

export default AccessibilityIntro;
