import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useIsElementSticky = (element: HTMLElement | null) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (!element) return;

    let originalOffsetTop = element?.offsetTop;
    const detectStickiness =
      (el: HTMLElement, callback?: (isSticky: boolean) => void) => () =>
        callback?.(el.offsetTop !== originalOffsetTop);

    const onSticky = (isSticky: boolean) => setIsSticky(isSticky);
    const scrollCallback = debounce(detectStickiness(element, onSticky), 20);

    window?.addEventListener('scroll', scrollCallback);

    return () => window?.removeEventListener('scroll', scrollCallback);
  }, [element]);

  return isSticky;
};

export default useIsElementSticky;
