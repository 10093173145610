import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import {
  SoftSkillSelfAssessmentProficiencyLevel,
  TechnicalSkillSelfAssessmentProficiencyLevel,
} from 'generated/graphql';

export const NO_KNOWLEDGE: TBarChartValue = 1;
export const BEGINNER: TBarChartValue = 2;
export const INTERMEDIATE: TBarChartValue = 3;
export const EXPERT: TBarChartValue = 4;

export const SKILL_ANSWER_STRINGS: { [key in TBarChartValue]: string } = {
  1: 'skills.level.noKnowledge',
  2: 'skills.level.beginner',
  3: 'skills.level.intermediate',
  4: 'skills.level.expert',
};

export const softSkillProficiencyLevelToBarChartValuesMap: {
  [key in SoftSkillSelfAssessmentProficiencyLevel]: TBarChartValue;
} = {
  [SoftSkillSelfAssessmentProficiencyLevel.Expert]: 4,
  [SoftSkillSelfAssessmentProficiencyLevel.Intermediate]: 3,
  [SoftSkillSelfAssessmentProficiencyLevel.Beginner]: 2,
};

export const barChartValuesToSoftSkillProficiencyLevelMap: {
  [key in TBarChartValue]: SoftSkillSelfAssessmentProficiencyLevel;
} = {
  4: SoftSkillSelfAssessmentProficiencyLevel.Expert,
  3: SoftSkillSelfAssessmentProficiencyLevel.Intermediate,
  2: SoftSkillSelfAssessmentProficiencyLevel.Beginner,
  1: SoftSkillSelfAssessmentProficiencyLevel.Beginner,
};

export const technicalSkillProficiencyLevelToBarChartValuesMap: {
  [key in TechnicalSkillSelfAssessmentProficiencyLevel]: TBarChartValue;
} = {
  [TechnicalSkillSelfAssessmentProficiencyLevel.Expert]: 4,
  [TechnicalSkillSelfAssessmentProficiencyLevel.Intermediate]: 3,
  [TechnicalSkillSelfAssessmentProficiencyLevel.Beginner]: 2,
  [TechnicalSkillSelfAssessmentProficiencyLevel.NoKnowledge]: 1,
};

export const barChartValuesToTechnicalSkillProficiencyLevelMap: {
  [key in TBarChartValue]: TechnicalSkillSelfAssessmentProficiencyLevel;
} = {
  4: TechnicalSkillSelfAssessmentProficiencyLevel.Expert,
  3: TechnicalSkillSelfAssessmentProficiencyLevel.Intermediate,
  2: TechnicalSkillSelfAssessmentProficiencyLevel.Beginner,
  1: TechnicalSkillSelfAssessmentProficiencyLevel.NoKnowledge,
};
