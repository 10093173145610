import Icon, { IconType } from 'components/atoms/Icon';
import {
  StageActionAvailabilityStatus,
  StageCandidateActionType,
} from 'generated/graphql';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionCard, Tag, TagSize } from '@spotted-zebra-uk/ui-components';
import { getLocaleTagColor, getLocaleTagLabel } from './StageView.helpers';

interface IStageActionCard {
  status: StageActionAvailabilityStatus;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  title: string;
  icon: IconType;
  dataTestType?: string;
  linkTo: string;
  ariaLabel?: string;
  actionType: StageCandidateActionType;
  hasLocaleTag: boolean;
}

const StageActionCard: FC<IStageActionCard> = ({
  status,
  onClick,
  title,
  icon,
  linkTo,
  ariaLabel,
  dataTestType,
  actionType,
  hasLocaleTag,
}) => {
  const { t } = useTranslation();
  return (
    //@ts-ignore
    <ActionCard
      to={linkTo}
      disabled={StageActionAvailabilityStatus.NotAvailable === status}
      onClick={onClick}
      label={title}
      completed={StageActionAvailabilityStatus.Completed === status}
      ariaLabel={ariaLabel}
      data-test-type={dataTestType}
      data-test-id="stage-action-card"
      className="stage-action-card"
      icon={
        <Icon
          className="stage-action-card__icon"
          icon={icon}
          aria-hidden
          focusable="false"
        />
      }
    >
      {hasLocaleTag && (
        <Tag
          isPresentational={true}
          isDismissible={false}
          tagColor={getLocaleTagColor(actionType)}
          size={TagSize.LARGE}
        >
          {t(`stage.localesSupport.${getLocaleTagLabel(actionType)}`)}
        </Tag>
      )}
    </ActionCard>
  );
};

export default StageActionCard;
