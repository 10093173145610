import Container from 'components/molecules/Container/Container';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import {
  useTestCandidateStartSzPracticeTestMutation,
  useTestCandidateStartSzTestMutation,
} from 'generated/graphql';
import { useWindowSize } from 'hooks/containerSize';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import DesktopVerbalTest from './DesktopVerbalTest/DesktopVerbalTest';
import MobileVerbalTest from './MobileVerbalTest/MobileVerbalTest';
import { useDisableCopyAndRightClick } from 'hooks/useDisableCopyAndRightClick';

interface IVerbalTest {
  testCandidateId: number;
  isPracticeTest: boolean;
  onFinishTest: () => void;
}

const MOBILE_BREAKPOINT = 970;

const VerbalTest: FC<IVerbalTest> = ({
  testCandidateId,
  isPracticeTest,
  onFinishTest,
}) => {
  const { t } = useTranslation();
  useDisableCopyAndRightClick();
  const { handleMsgType } = useNotification();

  const [containerSize] = useWindowSize();
  const [testCandidateStartSzTestMutation, testCandidateStartSzTestResponse] =
    useTestCandidateStartSzTestMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
      variables: {
        testCandidateId,
      },
    });

  const [
    testCandidateStartSzPracticeTestMutation,
    testCandidateStartSzPracticeTestMutationResponse,
  ] = useTestCandidateStartSzPracticeTestMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
    variables: {
      id: testCandidateId,
    },
  });

  useEffect(() => {
    if (!isPracticeTest) {
      testCandidateStartSzTestMutation();
    } else {
      testCandidateStartSzPracticeTestMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading =
    testCandidateStartSzTestResponse.loading ||
    testCandidateStartSzPracticeTestMutationResponse.loading;
  const assessmentId = !isPracticeTest
    ? testCandidateStartSzTestResponse.data?.TestCandidateStartSzTest
        ?.assessmentId
    : testCandidateStartSzPracticeTestMutationResponse.data
        ?.TestCandidateStartSzPracticeTest.assessmentId;

  const getVerbalTestComponent = () => {
    if (assessmentId) {
      return containerSize.width < MOBILE_BREAKPOINT ? (
        <MobileVerbalTest
          assessmentId={assessmentId}
          onFinishTest={onFinishTest}
        />
      ) : (
        <DesktopVerbalTest
          assessmentId={assessmentId}
          onFinishTest={onFinishTest}
        />
      );
    }
    return null;
  };

  return (
    <>
      <HelmetAndPageAnnouncer
        pageTitle={
          isPracticeTest
            ? t('test.instructions.verbal.practiceName')
            : t('test.instructions.verbal.name')
        }
      />
      {loading ? (
        <Container className="page-loader-wrapper">
          <Loader variant="bubbles" />
        </Container>
      ) : (
        getVerbalTestComponent()
      )}
    </>
  );
};

export default VerbalTest;
