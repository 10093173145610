import Container from 'components/molecules/Container/Container';
import { appSwitchRoutes, stageSwitchRoutes } from 'constants/routes';
import { ProjectModuleType, useGetStageActionsQuery } from 'generated/graphql';
import { ITestCallbackPath } from 'interfaces/routes';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import HiringThankYouContent from './HiringThankYouContent';
import ThankYouContent from './ThankYouContent/ThankYouContent';

function TestCallback() {
  const { stageCandidateId, testCandidateId } =
    useParams() as ITestCallbackPath;
  const { handleMsgType } = useNotification();
  const isPractice = Boolean(
    useMatch(stageSwitchRoutes.practiceTestCallback.path)
  );

  const navigate = useNavigate();

  const stagesUrl = appSwitchRoutes.stages.getUrl();

  const stageActionsQueryResponse = useGetStageActionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      stageCandidateId: Number(stageCandidateId),
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(stagesUrl);
    },
  });

  if (!stageActionsQueryResponse.data) {
    return (
      <Container className="page-loader-wrapper">
        <Loader variant="bubbles" />
      </Container>
    );
  }

  if (
    stageActionsQueryResponse.data.GetStageActions.project.moduleType ===
    ProjectModuleType.Hiring
  ) {
    return (
      <HiringThankYouContent
        isPractice={isPractice}
        stageActionsData={stageActionsQueryResponse.data}
      />
    );
  }

  return (
    <ThankYouContent
      stageCandidateId={Number(stageCandidateId)}
      testCandidateId={testCandidateId}
      isPractice={isPractice}
      stageActionsQuery={stageActionsQueryResponse.data}
    />
  );
}

export default TestCallback;
