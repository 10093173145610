const marksColor = '#757575';

const markStyle = {
  color: marksColor,
  height: 80,
  marginLeft: 0,
};

export const personalityTestMarks = {
  0: {
    style: {
      ...markStyle,
      width: 100,
      transform: 'translateX(-30%)',
    },
    label: 'Strongly Disagree',
  },
  1: {
    style: markStyle,
    label: 'Disagree',
  },
  2: {
    style: {
      ...markStyle,
      width: 100,
    },
    label: 'Slightely Disagree',
  },
  3: {
    style: markStyle,
    label: 'Neutral',
  },
  4: {
    style: {
      ...markStyle,
      width: 80,
    },
    label: 'Slightely Agree',
  },
  5: {
    style: {
      ...markStyle,
      transform: 'translateX(-60%)',
    },
    label: 'Agree',
  },
  6: {
    style: {
      ...markStyle,
      width: 80,
      marginLeft: -20,
    },
    label: 'Strongly Agree',
  },
};

export const SLIDER_LEGEND_BEAK_POINT = 660;
