import { ModalTypes } from 'enums/modalTypes';
import { TModal } from 'interfaces/modal';
import { gql } from '@apollo/client';
import { modalsVar } from './client';

export const GET_MODALS_QUERY = gql`
  query GetModals {
    modals @client {
      modalType
      modalProps
    }
  }
`;

export const openModal = (options: TModal) => {
  const modals = modalsVar();
  if (!modals.find(modal => modal.modalType === options.modalType)) {
    modalsVar([...modals, options]);
  }
};

export const closeModal = (modalType: ModalTypes) => {
  const modals = modalsVar();
  modalsVar(modals.filter(modal => modal.modalType !== modalType));
};
