/* eslint-disable @typescript-eslint/indent */
import './TPTest.scss';
import Loader from 'components/atoms/Loader/Loader';
import { appSwitchRoutes } from 'constants/routes';
import {
  TestStatus,
  useTestCandidateFindOneLazyQuery,
} from 'generated/graphql';
import { useWindowSize } from 'hooks/containerSize';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface ITPTest {
  tpTestUrl: string;
  id: number;
  onFinishTest: () => void;
}

const TPTest: FC<ITPTest> = ({ tpTestUrl, id, onFinishTest }) => {
  const [{ height }] = useWindowSize();
  const { handleMsgType } = useNotification();
  const navigate = useNavigate();

  const [testCandidateFindOneQuery, testCandidateFindOneResponse] =
    useTestCandidateFindOneLazyQuery({
      fetchPolicy: 'network-only',
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });

        navigate(appSwitchRoutes.stages.getUrl());
      },
      onCompleted: data => {
        if (data?.TestCandidateFindOne?.status === TestStatus.Completed) {
          onFinishTest();
        }
      },
    });

  return (
    <div className="TPTest">
      <div className="TPTest__Cloak" />
      {testCandidateFindOneResponse.loading ? <Loader isOverlay /> : null}
      <iframe
        id="tp-test-iframe"
        width="100%"
        height={height}
        title="test"
        src={tpTestUrl}
        onLoad={() => {
          testCandidateFindOneQuery({
            variables: {
              id,
            },
          });
        }}
      />
    </div>
  );
};

export default TPTest;
