import './AppBar.scss';
import { FC } from 'react';
import LogoutButton from '../LogoutButton/LogoutButton';
import CandidateInfo from './CandidateInfo/CandidateInfo';

interface IAppBar {}

const AppBar: FC<IAppBar> = () => {
  return (
    <header>
      <div className="AppBar">
        <CandidateInfo />
        <div className="AppBar__Actions">
          <LogoutButton />
        </div>
      </div>
    </header>
  );
};

export default AppBar;
