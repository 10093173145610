import { stageSwitchRoutes } from 'constants/routes';
import {
  StageActionAvailabilityStatus,
  StageActionFragment,
  StageCandidateActionType,
} from 'generated/graphql';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StageActionCard from '../StageActionCard';

interface IStageActionDeiCard {
  title: string;
  actionCard: StageActionFragment;
  stageCandidateId: number;
  actionType: StageCandidateActionType;
  hasLocaleTag: boolean;
}

const StageActionDeiCard = ({
  actionCard,
  title,
  stageCandidateId,
  hasLocaleTag,
  actionType,
}: IStageActionDeiCard) => {
  const { t } = useTranslation();
  const isCardAvailable =
    actionCard.availability.status === StageActionAvailabilityStatus.Available;
  const navigate = useNavigate();
  const deiIntroURL = stageSwitchRoutes.deiIntro.getUrl(stageCandidateId);

  const handleCardClick: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    if (isCardAvailable) {
      navigate(deiIntroURL);
    }
    return;
  };

  const ariaLabel =
    StageActionAvailabilityStatus.Completed === actionCard.availability.status
      ? t('assessment.deiCompleted')
      : t('test.instructions.dei.name');

  return (
    <StageActionCard
      status={actionCard.availability.status}
      onClick={handleCardClick}
      title={title}
      icon="dei"
      linkTo={deiIntroURL}
      ariaLabel={ariaLabel}
      actionType={actionType}
      hasLocaleTag={hasLocaleTag}
    />
  );
};

export default StageActionDeiCard;
