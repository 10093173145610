import { ReactComponent as PersonIcon } from 'assets/icons/calibrate/ic_person.svg';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { ReactComponent as ClapsIcon } from 'assets/icons/claps.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CompletedIcon } from 'assets/icons/completed.svg';
import { ReactComponent as DeniedIcon } from 'assets/icons/denied.svg';
import { ReactComponent as EmptyDrawerIcon } from 'assets/icons/empty-drawer.svg';
import { ReactComponent as GreenCheckIcon } from 'assets/icons/green-check.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/ic_arrow.svg';
import { ReactComponent as BrainIcon } from 'assets/icons/ic_brain.svg';
import { ReactComponent as CogIcon } from 'assets/icons/ic_cog.svg';
import { ReactComponent as FlameIcon } from 'assets/icons/ic_flame.svg';
import { ReactComponent as MicrophoneIcon } from 'assets/icons/ic_microphone.svg';
import { ReactComponent as PerformanceIcon } from 'assets/icons/ic_performance.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/ic_time.svg';
import { ReactComponent as TwoSideArrowIcon } from 'assets/icons/id_two_side_arrow.svg';
import { ReactComponent as InfoIIcon } from 'assets/icons/info-i.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list_alt.svg';
import { ReactComponent as ClapsLargeIcon } from 'assets/icons/new-claps.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as GeneralQuestions } from 'assets/icons/questions.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/right_arrow_green.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import { ReactComponent as CognitiveTestIcon } from 'assets/icons/test/cognitive.svg';
import { ReactComponent as DeiTestIcon } from 'assets/icons/test/dei.svg';
import { ReactComponent as InductiveTestIcon } from 'assets/icons/test/inductive.svg';
import { ReactComponent as NumericalTestIcon } from 'assets/icons/test/numerical.svg';
import { ReactComponent as PersonalityTestIcon } from 'assets/icons/test/personality.svg';
import { ReactComponent as SkillsTestIcon } from 'assets/icons/test/skills_self_assessment.svg';
import { ReactComponent as VerbalTestIcon } from 'assets/icons/test/verbal.svg';
import { FC, SVGAttributes } from 'react';

const iconsObj = {
  questions: GeneralQuestions,
  performance: PerformanceIcon,
  time: TimeIcon,
  arrow: ArrowIcon,
  completed: CompletedIcon,
  right_arrow: RightArrowIcon,
  cognitive_test: CognitiveTestIcon,
  inductive_test: InductiveTestIcon,
  numerical_test: NumericalTestIcon,
  personality_test: PersonalityTestIcon,
  verbal_test: VerbalTestIcon,
  dei: DeiTestIcon,
  skills_self_assessment: SkillsTestIcon,
  brain: BrainIcon,
  chevron: ChevronIcon,
  list: ListIcon,
  flame: FlameIcon,
  claps: ClapsIcon,
  plus: PlusIcon,
  cog: CogIcon,
  info_i: InfoIIcon,
  person: PersonIcon,
  empty_drawer: EmptyDrawerIcon,
  sort: SortIcon,
  claps_large: ClapsLargeIcon,
  tag: TagIcon,
  link: LinkIcon,
  green_check: GreenCheckIcon,
  microphone: MicrophoneIcon,
  denied: DeniedIcon,
  two_side_arrow: TwoSideArrowIcon,
  close: CloseIcon,
};

export type IconType = keyof typeof iconsObj;

interface IconProps extends SVGAttributes<SVGSVGElement> {
  icon: IconType;
  className?: string;
  title?: string;
  alt?: string;
}

const Icon: FC<IconProps> = ({ icon, className, ...rest }) => {
  const Component = iconsObj[icon];

  return iconsObj[icon] ? <Component className={className} {...rest} /> : null;
};

export default Icon;
