import styles from './CompanyLogo.module.scss';

const CompanyLogo = ({ companyLogoUrl, companyName }: { companyLogoUrl?: string | null, companyName: string }) => {
    return companyLogoUrl ? (
        <div className={styles.logoContainer}>
            <img
                className={styles.logo}
                src={companyLogoUrl}
                alt=""
            />
        </div>
    ) : (
        <p className={styles.companyName}>{companyName || ''}</p>
    )
}

export default CompanyLogo;