import './TestInfo.scss';
import {
  StageCandidateActionType,
  SupportedLocale,
  TestStatus,
} from 'generated/graphql';
import { FC, useState } from 'react';
import { isMobile as isUsingMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { hasLanguageDisclaimer } from 'views/Stage/StageView/StageView.helpers';
import NeedExtraTimeModal from 'views/Test/NeedExtraTimeModal/NeedExtraTimeModal';
import { ISingleTestInfoConfigNote } from 'views/Test/TestInfo/TestInfo.constants';
import {
  Button,
  InlineNotification,
  Link,
  NotificationType,
  Tag,
  TagColor,
  TagSize,
} from '@spotted-zebra-uk/ui-components';
import TestInfoNote from './TestInfoNote/TestInfoNote';

interface ITestIntro {
  MainIcon: FC<{ alt: string }>;
  title: string;
  notes: ISingleTestInfoConfigNote[];
  testStatus: TestStatus;
  hasPractice: boolean;
  onStart: () => void;
  onStartPracticeTest?: () => void;
  testTimePerQuestion: number;
  isMobileAllowed?: boolean;
  type?: StageCandidateActionType;
  locale: SupportedLocale;
}

const SUPPORT_EMAIL = 'candidate@spottedzebra.co.uk';

const TestIntro: FC<ITestIntro> = ({
  MainIcon,
  title,
  notes,
  testStatus,
  hasPractice,
  onStart,
  onStartPracticeTest,
  testTimePerQuestion,
  type,
  locale,
  isMobileAllowed = true,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const actions = {
    openModal: () => setShowModal(true),
  };

  const isDisabledOnMobile = isUsingMobile && !isMobileAllowed;
  const hasLocaleGuidance = type && hasLanguageDisclaimer(locale, [type]);

  return (
    <main>
      <div className="TestInfo">
        <div className="TestInfo__Content">
          <div className="TestInfo__Content__Top">
            <div className="TestInfo__Content__Top__Main">
              <MainIcon alt="" />
              <h1 className="TestInfo__Content__Top__Main__Title">{title}</h1>
              {hasLocaleGuidance && (
                <Tag
                  isPresentational={true}
                  isDismissible={false}
                  tagColor={TagColor.DISABLED}
                  size={TagSize.LARGE}
                  containerClass="TestInfo__Content__Top__Main__LocaleGuidance"
                >
                  {t(`stage.localesSupport.introGuidance`)}
                </Tag>
              )}
            </div>
            <div className="TestInfo__Content__Top__NotesList">
              {notes.map(note => {
                const AdditionalItem = note.extraTimeConfig ? (
                  <Button
                    variant="tertiary"
                    className="TestInfo__AdditionalItem"
                    onClick={actions[note.extraTimeConfig.action]}
                    aria-label={t('test.testTimeLimitExtraTime', {
                      minutes: testTimePerQuestion,
                    })}
                  >
                    {note.extraTimeConfig.text}
                  </Button>
                ) : null;
                return (
                  <TestInfoNote
                    key={note.id}
                    icon={note.icon}
                    text={note.text}
                    additionalItem={AdditionalItem}
                    className={note.className}
                    alt={note.alt}
                    dataTestid={note.dataTestid}
                    accessibilityAdjustment={
                      note?.accessibilityAdjustment &&
                      t('test.instructions.general.extraTime', {
                        amount: note.accessibilityAdjustment,
                      })
                    }
                  />
                );
              })}
            </div>
          </div>

          <div className="TestInfo__Content__Bottom">
            {isDisabledOnMobile && (
              <div className="TestInfo__Content__MobileDisclaimer">
                <InlineNotification
                  notificationType={NotificationType.ERROR}
                  title={t('test.instructions.coding.mobile_disclaimer_title')}
                  className="TestInfo__Content__MobileDisclaimer__Notification"
                >
                  <p>{t('test.instructions.coding.mobile_disclaimer_body')}</p>
                </InlineNotification>
              </div>
            )}

            <InlineNotification
              title={t('test.accessibilityNotice.title')}
              className="TestInfo__Content__Bottom__Notification"
            >
              <p>
                {t('test.accessibilityNotice.body')}
                <br />
                <br />
                <Trans
                  i18nKey="test.accessibilityNotice.footer"
                  t={t}
                  values={{
                    link: SUPPORT_EMAIL,
                  }}
                  components={[
                    <Link
                      key={SUPPORT_EMAIL}
                      to="mailto:candidate@spottedzebra.co.uk"
                      target="_blank"
                      className="TestInfo__Content__Bottom__Notification__Link"
                      aria-label={t('common.sendEmailTo', {
                        email: SUPPORT_EMAIL,
                      })}
                    />,
                  ]}
                />
              </p>
            </InlineNotification>
          </div>
          <div className="TestInfo__Content__Middle">
            {hasPractice && (
              <Button
                onClick={onStartPracticeTest}
                variant="secondary"
                className="TestInfo__Content__Middle__Button"
                aria-label={t('test.practice', { testTitle: title })}
              >
                {t('common.practice')}
              </Button>
            )}
            <Button
              onClick={onStart}
              disabled={isDisabledOnMobile}
              className="TestInfo__Content__Middle__Button"
              aria-label={
                testStatus === TestStatus.NotStarted
                  ? t('test.startTestName', { testTitle: title })
                  : t('test.continueTestName', { testTitle: title })
              }
            >
              {testStatus === TestStatus.NotStarted
                ? t('test.instructions.general.startNow')
                : t('test.instructions.general.continue')}
            </Button>
          </div>
        </div>
      </div>
      <NeedExtraTimeModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </main>
  );
};

export default TestIntro;
