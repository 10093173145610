import { stageSwitchRoutes } from 'constants/routes';
import {
  FormType,
  StageActionAvailabilityStatus,
  StageActionFragment,
  StageCandidateActionType,
} from 'generated/graphql';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StageActionCard from '../StageActionCard';

interface IGetFormRoute {
  companyId: number;
  stageCandidateId: number;
  formType: FormType;
  stageId: number;
}

const getFormRoute = ({
  companyId,
  stageCandidateId,
  formType,
  stageId,
}: IGetFormRoute) =>
  [
    FormType.ApprenticeForm,
    FormType.RsForm,
    FormType.UndergradForm,
    FormType.GradForm,
  ]
    ? stageSwitchRoutes.stageActionForm.getUrl({
        stageCandidateId,
        stageId,
        companyId,
        formType,
      })
    : stageSwitchRoutes.trform.getUrl({
        stageCandidateId,
        stageId,
        companyId,
      });

interface IFormCard {
  actionCard: StageActionFragment;
  stageCandidateId: number;
  formType: FormType;
  companyId: number;
  stageId: number;
  actionType: StageCandidateActionType;
  hasLocaleTag: boolean;
}

const FormCard: FC<IFormCard> = ({
  actionCard,
  companyId,
  stageCandidateId,
  formType,
  stageId,
  hasLocaleTag,
  actionType,
}) => {
  const { t } = useTranslation();
  const isCardAvailable =
    actionCard.availability.status === StageActionAvailabilityStatus.Available;
  const navigate = useNavigate();
  const formPageRoute = getFormRoute({
    companyId,
    stageId,
    stageCandidateId,
    formType,
  });

  const handleCardClick: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    if (isCardAvailable) {
      navigate(
        getFormRoute({ companyId, stageId, stageCandidateId, formType })
      );
    }
  };

  const ariaLabel =
    StageActionAvailabilityStatus.Completed === actionCard.availability.status
      ? t('stage.questionCompleted', { stageName: actionCard.title })
      : actionCard.title;

  return (
    <StageActionCard
      status={actionCard.availability.status}
      onClick={handleCardClick}
      title={t('common.testTypes.form')}
      icon="questions"
      linkTo={formPageRoute}
      ariaLabel={ariaLabel}
      actionType={actionType}
      hasLocaleTag={hasLocaleTag}
    />
  );
};

export default FormCard;
