import { PersonalityOptionModel } from 'generated/graphql';
import { FC } from 'react';
import { ISliderMarks, Slider } from '@spotted-zebra-uk/ui-components';

interface IPersonalityTestSlider {
  marks?: ISliderMarks;
  value: number;
  onChange: (value: number | undefined) => void;
  id: string;
  ariaLabel: string;
  marksVisible?: boolean;
  options: PersonalityOptionModel[];
}

const PersonalityTestSlider: FC<IPersonalityTestSlider> = ({
  marks,
  value,
  onChange,
  id,
  ariaLabel,
  marksVisible = false,
  options,
}) => {
  const defaultMarks: ISliderMarks = getDefaultMarks(options);
  return (
    <Slider
      value={value}
      onChange={onChange}
      marks={marks || defaultMarks}
      min={0}
      max={6}
      startPoint={3}
      id={`desktop-personality-test-question-slider-${id}`}
      ariaLabel={ariaLabel}
      marksVisible={marksVisible}
      handleSize={32}
    />
  );
};

function getDefaultMarks(options: PersonalityOptionModel[]) {
  return {
    0: {
      label: options[0].text,
    },
    1: {
      label: options[1].text,
    },
    2: {
      label: options[2].text,
    },
    3: {
      label: options[3].text,
    },
    4: {
      label: options[4].text,
    },
    5: {
      label: options[5].text,
    },
    6: {
      label: options[6].text,
    },
  };
}

export default PersonalityTestSlider;
