import './EqualityAndDiversityModal.scss';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal } from 'graphql/modals';
import { FC, useState } from 'react';
import EquailityAndDiversityContent from './EquailityAndDiversityContent/EquailityAndDiversityContent';
import EqualityStatement from './EqualityStatement/EqualityStatement';
import { Modal, ModalSize } from '@spotted-zebra-uk/ui-components';

export type TEqualityAndDiversityModal = {
  stageCandidateId: number;
};

const EqualityAndDiversityModal: FC<TEqualityAndDiversityModal> = ({
  stageCandidateId,
}) => {
  const [isEqualityStatementVisible, setIsEqualityStatementVisible] =
    useState(false);

  const handleShowEqualityStatement = () => {
    setIsEqualityStatementVisible(true);
  };

  const handleHideEqualityStatement = () => {
    setIsEqualityStatementVisible(false);
  };

  const handleCloseModal = () => {
    closeModal(ModalTypes.EQUALITY_AND_DIVERSITY_MODAL);
  };

  return (
    //@ts-ignore
    <Modal
      onClose={handleCloseModal}
      modalSize={
        isEqualityStatementVisible ? ModalSize.MEDIUM : ModalSize.SMALL
      }
      isOpen
      className="EqualityAndDiversityModal"
      showCloseIcon={!isEqualityStatementVisible}
    >
      {isEqualityStatementVisible ? (
        <EqualityStatement
          onBackArrowClick={handleHideEqualityStatement}
          onGotItButtonClick={handleHideEqualityStatement}
        />
      ) : (
        <EquailityAndDiversityContent
          onCloseModal={handleCloseModal}
          stageCandidateId={stageCandidateId}
          onEqualityStatementButtonClick={handleShowEqualityStatement}
        />
      )}
    </Modal>
  );
};

export default EqualityAndDiversityModal;
