import './styles.scss';
import clsx from 'clsx';
import { forwardRef, ReactNode, useImperativeHandle, useRef } from 'react';
import { default as SlickSlider } from 'react-slick';
import NextArrow from './NextArrow';
import PreviousArrow from './PreviousArrow';

// Customized Carousel component with predefined options and style.
// For more information about library check https://react-slick.neostack.com/

const defaultOptions = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export type TSwipeDirection = 'left' | 'right';

export interface ICarouselHandle {
  slideNext?: () => void;
  slidePrevious?: () => void;
  slideTo?: (slideNumber: number, dontAnimate?: boolean) => void;
}

interface ICarousel {
  // CSS class for dots.
  dots?: boolean;
  // CSS class for inner carousel div.
  className?: string;
  // Callback after slide changes by swiping.
  // This will be called only on swipe event.
  onSwipe?: (direction: TSwipeDirection) => void;
  // Edge dragged event in finite case.
  // This will be called only on swipe event.
  onEdge?: (direction: TSwipeDirection) => void;
  // Infinitely wrap around contents default is true.
  infinite?: boolean;
  // Show tiny side arrows.
  arrows?: boolean;
  // Index change callback. `index => ...`.
  afterChange?: (index: number) => void;
  // Index change callback. `(oldIndex, newIndex) => ...`.
  beforeChange?: (oldIndex: number, newIndex: number) => void;
  children: ReactNode;
}

const Carousel = forwardRef<ICarouselHandle, ICarousel>(
  ({ children, className, onEdge, ...restProps }, ref) => {
    const slickRef = useRef<SlickSlider>(null);

    useImperativeHandle(ref, () => ({
      slideNext: () => {
        if (slickRef.current) {
          slickRef.current.slickNext();
        }
      },
      slidePrevious: () => {
        if (slickRef.current) {
          slickRef.current.slickPrev();
        }
      },
      slideTo: (slideNumber: number, dontAnimate?: boolean) => {
        if (slickRef.current) {
          slickRef.current.slickGoTo(slideNumber, dontAnimate);
        }
      },
    }));

    return (
      <SlickSlider
        ref={slickRef}
        {...defaultOptions}
        {...restProps}
        prevArrow={<PreviousArrow />}
        nextArrow={<NextArrow />}
        className={clsx('sz-carousel', className)}
      >
        {children}
      </SlickSlider>
    );
  }
);

export default Carousel;
