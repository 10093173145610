import { IconType } from 'components/atoms/Icon';
import { stageSwitchRoutes } from 'constants/routes';
import { FormType } from 'enums/form';
import { ModalTypes } from 'enums/modalTypes';
import {
  StageActionAvailabilityStatus,
  StageActionFragment,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { useGetStageCompanyRespondantForm } from 'hooks/useRespondantForm';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StageActionCard from '../StageActionCard';

interface IStageActionTestCard {
  title: string;
  actionCard: StageActionFragment;
  testCandidateId: number;
  stageCandidateId: number;
  hasLocaleTag: boolean;
}

const StageActionTestCard: FC<IStageActionTestCard> = ({
  title,
  actionCard,
  testCandidateId,
  stageCandidateId,
  hasLocaleTag,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isCardAvailable =
    actionCard.availability.status === StageActionAvailabilityStatus.Available;
  const getStageCompanyRespondantForm = useGetStageCompanyRespondantForm();
  const stageTestUrl = stageSwitchRoutes.test.getUrl(
    stageCandidateId,
    testCandidateId
  );

  const handleClick: MouseEventHandler<HTMLAnchorElement> = async event => {
    event.preventDefault();
    try {
      const candidateInformationRespondantForm =
        await getStageCompanyRespondantForm(stageCandidateId, FormType.CI_FORM);

      if (
        candidateInformationRespondantForm?.data?.respondantForm &&
        candidateInformationRespondantForm.data.respondantForm
          .respondantFieldWithAnswers.length &&
        !candidateInformationRespondantForm.data.respondantForm.isComplete
      ) {
        openModal({
          modalType: ModalTypes.CANDIDATE_INFORMATION,
          modalProps: {
            stageCandidateId,
          },
        });
      } else if (isCardAvailable) {
        navigate(stageTestUrl);
      }
    } catch (error) {
      if (isCardAvailable) {
        navigate(stageTestUrl);
      }
    }
  };

  const isCompleted =
    StageActionAvailabilityStatus.Completed === actionCard.availability.status;
  const testName = t(stageActionTestCardConfig[actionCard.title]?.name);

  const icon = stageActionTestCardConfig[actionCard.title]?.icon;
  const dataTestType = `${
    stageActionTestCardConfig[actionCard.title]?.testTypeBase
  }-${actionCard.availability.status}`;
  const ariaLabel = isCompleted
    ? t('stage.testCompleted', { testName })
    : testName;

  return (
    <StageActionCard
      status={actionCard.availability.status}
      onClick={handleClick}
      title={title}
      icon={icon || 'cognitive_test'}
      linkTo={stageTestUrl}
      ariaLabel={ariaLabel}
      dataTestType={dataTestType}
      actionType={actionCard.type}
      hasLocaleTag={hasLocaleTag}
    />
  );
};

const stageActionTestCardConfig: {
  [key in string]: { icon: IconType; name: string; testTypeBase: string };
} = {
  Personality: {
    icon: 'personality_test',
    name: 'test.instructions.personality.name',
    testTypeBase: 'stage-action-card-personality',
  },
  Numerical: {
    icon: 'numerical_test',
    name: 'test.instructions.numerical.name',
    testTypeBase: 'stage-action-card-numerical',
  },
  Inductive: {
    icon: 'inductive_test',
    name: 'test.instructions.inductive.name',
    testTypeBase: 'stage-action-card-inductive',
  },
  Verbal: {
    icon: 'verbal_test',
    name: 'test.instructions.verbal.name',
    testTypeBase: 'stage-action-card-verbal',
  },
  Cognitive: {
    icon: 'cognitive_test',
    name: 'test.instructions.cognitive.name',
    testTypeBase: 'stage-action-card-cognitive',
  },
  'Diversity, equity & inclusion': {
    icon: 'dei',
    name: 'test.instructions.dei.name',
    testTypeBase: 'stage-action-card-dei',
  },
};

export default StageActionTestCard;
