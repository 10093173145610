import Container from 'components/molecules/Container/Container';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import { appSwitchRoutes } from 'constants/routes';
import { FormType } from 'enums/form';
import { ModalTypes } from 'enums/modalTypes';
import {
  ProjectModuleType,
  StageCandidateStatus,
  StageStatus,
  useGetStageActionsQuery,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { useGetStageCompanyRespondantFormEffect } from 'hooks/useRespondantForm';
import { IStagePath } from 'interfaces/routes';
import { isNaN } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TalentReview from 'views/TalentReview/TalentReview';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import StageViewPresentational from './StageViewPresentational';

const StageTodosList: FC = () => {
  const { t } = useTranslation();
  const { stageCandidateId } = useParams() as IStagePath;
  const stageCandidateIdParsed = Number(stageCandidateId);
  const location = useLocation();
  const isTestCallback = location.pathname.includes('/tp-callback');
  const navigate = useNavigate();
  const stagesUrl = appSwitchRoutes.stages.getUrl();
  const { handleMsgType } = useNotification();
  const [hasAnticheatingPledge, setHasAnticheatingPledge] = useState(false);

  const [
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    _getStageCompanyRespondantFormResult,
    isRespondantFormLoading,
  ] = useGetStageCompanyRespondantFormEffect(
    +stageCandidateId,
    FormType.CI_FORM,
    getRespondantFormResult => {
      if (
        getRespondantFormResult?.data.respondantForm.respondantFieldWithAnswers
          .length &&
        !getRespondantFormResult.data.respondantForm.isComplete &&
        !isTestCallback
      ) {
        openModal({
          modalType: ModalTypes.CANDIDATE_INFORMATION,
          modalProps: {
            stageCandidateId: +stageCandidateId,
          },
        });
      }
    },
    !hasAnticheatingPledge
  );

  const stageActionsQuery = useGetStageActionsQuery({
    variables: { stageCandidateId: stageCandidateIdParsed },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(stagesUrl);
    },
    onCompleted: data => {
      const stageStatus = data.GetStageActions.stage.status;
      const stageCandidateStatus = data.GetStageActions.stageCandidate.status;
      setHasAnticheatingPledge(
        data.GetStageActions.stageCandidate.hasPledgedAnticheating
      );

      if (
        stageStatus === StageStatus.Expired ||
        stageStatus === StageStatus.Scheduled ||
        stageCandidateStatus === StageCandidateStatus.Completed ||
        stageCandidateStatus === StageCandidateStatus.SignedOff ||
        stageCandidateStatus === StageCandidateStatus.ManagerCompleted
      ) {
        navigate(stagesUrl);
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isNaN(stageCandidateIdParsed)) {
      navigate(stagesUrl);
    }

    return () => closeModal(ModalTypes.CANDIDATE_INFORMATION);
  }, [navigate, stageCandidateIdParsed, stagesUrl]);

  if (stageActionsQuery.loading || isRespondantFormLoading) {
    return (
      <Container className="page-loader-wrapper">
        <Loader variant="bubbles" />
      </Container>
    );
  }

  const handleOnPledgeSubmit = () => {
    setHasAnticheatingPledge(true);
  };

  if (stageActionsQuery.data) {
    const stageActions = stageActionsQuery.data.GetStageActions;
    const actionCards = stageActions.actions;
    const stageEndTime =
      stageActions.stage.endTime && new Date(stageActions.stage.endTime);

    return (
      <>
        <HelmetAndPageAnnouncer
          pageTitle={t('stage.listOfAssessmentActions', {
            projectName: stageActions.project.name,
            companyName: stageActions.company.name,
          })}
        />
        {stageActions.project.moduleType === ProjectModuleType.TalentReview ? (
          <TalentReview />
        ) : (
          <StageViewPresentational
            companyLogoUrl={stageActions.company.logoUrl}
            companyName={stageActions.company.name}
            projectName={stageActions.project.name}
            stageName={stageActions.stage.name}
            stageEndTime={stageEndTime}
            stageCandidateId={stageCandidateIdParsed}
            stageCandidateStatus={stageActions.stageCandidate.status}
            companyId={stageActions.company.id}
            stageId={stageActions.stage.id}
            actionCards={actionCards}
            hasPledge={hasAnticheatingPledge}
            onPledgeSubmit={handleOnPledgeSubmit}
            locale={stageActions.doneByUser.locale}
          />
        )}
      </>
    );
  }

  return null;
};

export default StageTodosList;
