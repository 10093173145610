import Icon from 'components/atoms/Icon';
import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Loader,
  RadioButtonGroup,
  RadioButtonOption,
  BarChartWithBorder,
} from '@spotted-zebra-uk/ui-components';
import SkillsLevelNoSkillsLeftScreen from './SkillsLevelNoSkillsLeftScreen';
import { SKILL_ANSWER_STRINGS } from 'helpers/skillsProficiency';

export type TSkillsLevelSelectionMode = 'completed' | 'active';

interface ISkillsLevelSelection {
  disablePreviousSkillButton: boolean;
  disableNextSkillButton: boolean;
  questionnaireTitle?: string;
  questionnaireDescription?: string;
  selectedAnswerValue?: TBarChartValue;
  gradeBands: { proficiency: TBarChartValue }[];
  mode: TSkillsLevelSelectionMode;
  loading: boolean;
  skillName: string;
  onNextSkillButtonClick: () => void;
  onPreviousSkillButtonClick: () => void;
  selectAnswerValue: (selectedAnswerValue?: TBarChartValue) => void;
  onSubmit: () => void;
}

export const SkillsLevelSelection: FC<ISkillsLevelSelection> = ({
  disablePreviousSkillButton,
  disableNextSkillButton,
  questionnaireTitle,
  selectedAnswerValue,
  questionnaireDescription,
  gradeBands,
  mode,
  loading,
  skillName,
  onNextSkillButtonClick,
  onPreviousSkillButtonClick,
  selectAnswerValue,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [selectedAnswer, setSelectedAnswer] = useState<TBarChartValue | null>();

  useEffect(() => {
    setSelectedAnswer(selectedAnswerValue ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireTitle]);

  const proficiencyOptions: RadioButtonOption[] = useMemo(() => {
    return gradeBands.map(gradeBand => {
      return {
        id: `skill-proficiency-option-${gradeBand.proficiency}`,
        label: t(SKILL_ANSWER_STRINGS[gradeBand.proficiency]),
        value: String(gradeBand.proficiency),
        image: <BarChartWithBorder value={gradeBand.proficiency} />,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradeBands]);

  if (loading) {
    return (
      <div className="skills-level-selection">
        <div
          className="skills-level-selection__loader-wrapper"
          data-testid="skills-level-selection-loader-wrapper"
        >
          <Loader variant="bubbles" />
        </div>
      </div>
    );
  }

  const handleProficiencyChange = (newValue: string) => {
    const newSelectedValue = parseInt(newValue) as TBarChartValue;
    setSelectedAnswer(newSelectedValue);
  };

  const handleNextSkillClick = () => {
    if (selectedAnswer) {
      selectAnswerValue(selectedAnswer);
    } else {
      onNextSkillButtonClick();
    }
  };

  return (
    <div
      key={questionnaireTitle}
      className="skills-level-selection"
      data-test="skills-level-selection-with-content"
    >
      {mode === 'active' && (
        <div className="skills-level-selection__main-content">
          <div className="skills-level-selection__header">
            {t('common.skillLevel')}
          </div>
          <div className="skills-level-selection__questionnaire">
            <div
              className="skills-level-selection__questionnaire-title"
              data-testid="questionnaire-title"
            >
              {questionnaireTitle || ''}
            </div>
            <div
              className="skills-level-selection__questionnaire-description"
              data-testid="questionnaire-description"
            >
              {questionnaireDescription}
            </div>
            <div className="skills-level-selection__questionnaire-note">
              {!selectedAnswerValue && t('common.skillLevelNotSet')}
            </div>
            <div className="skills-level-selection__answer-options">
              <RadioButtonGroup
                id="skill-proficiency-group"
                name="skillProficiency"
                value={String(selectedAnswer)}
                options={proficiencyOptions}
                ariaLabel={t('skills.selectYourSkillLevelFor', {
                  skillName: questionnaireTitle,
                })}
                layout="horizontal"
                radioButtonClassName="skills-level-selection__answer-options-option"
                onChange={handleProficiencyChange}
              />
            </div>
          </div>
        </div>
      )}
      {mode === 'completed' && <SkillsLevelNoSkillsLeftScreen />}
      <div className="skills-level-selection__actions">
        {mode === 'active' && (
          <Button
            variant="text"
            size="small"
            className="skills-level-selection__next-skill-button"
            disabled={disableNextSkillButton}
            onClick={handleNextSkillClick}
            data-testid="next-skill-button"
            rightIcon={<Icon icon="chevron" />}
          >
            {t('common.nextSkill')}
          </Button>
        )}
        {mode === 'completed' && (
          <Button
            size="medium"
            className="skills-level-selection__submit-button"
            onClick={onSubmit}
            data-testid="submit-button"
            aria-label={t(`assessment.submitSkill`, {
              skillName,
            })}
          >
            {t('common.submit')}
            <Icon icon="chevron" />
          </Button>
        )}
        <Button
          variant="text"
          size="small"
          className="skills-level-selection__previous-skill-button"
          disabled={disablePreviousSkillButton}
          onClick={onPreviousSkillButtonClick}
          data-testid="back-to-previous-skill-button"
          leftIcon={<Icon icon="chevron" />}
        >
          {t('common.backToPreviousSkill')}
        </Button>
      </div>
    </div>
  );
};
