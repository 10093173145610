import './SliderTestQuestion.scss';
import SingleLegendSlider from 'components/molecules/SingleLegendSlider/SingleLegendSlider';
import { FC, useEffect, useMemo, useState } from 'react';
import { Slider } from '@spotted-zebra-uk/ui-components';
import ColoredText from '../ColoredText/ColoredText';

export interface ITestQuestion {
  difficulty: number;
  options: {
    key: number;
    subId: string;
    text: string;
  }[];
  subId: string;
  text: string;
}

interface ISliderTestQuestion {
  questionNumberingFormat?: string;
  questions: ITestQuestion;
  questionIndex: number;
  isMobile?: boolean;
  defaultSelectedAnswer?: number;
  onAnswer: (questionIndex: number, value: string, questionId: string) => void;
  updateDefault?: (index: number) => void;
  startPoint?: number;
  sliderMarkStyle?: React.CSSProperties;
}

const SliderTestQuestion: FC<ISliderTestQuestion> = ({
  questionNumberingFormat,
  questions,
  questionIndex,
  onAnswer,
  isMobile = false,
  defaultSelectedAnswer,
  updateDefault,
  startPoint = 0,
  sliderMarkStyle,
}) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number>(0);

  const sliderMarks = useMemo(
    () =>
      questions?.options.reduce((acc, curr, index) => {
        return {
          ...acc,
          [index]: {
            label: curr.text,
            style: sliderMarkStyle,
          },
        };
      }, {}),
    [questions, sliderMarkStyle]
  );

  useEffect(() => {
    setSelectedAnswerIndex(startPoint);
  }, [defaultSelectedAnswer, startPoint]);

  const handleSetAnswerValue = (answerIndex: number | undefined) => {
    if (answerIndex !== undefined) {
      setSelectedAnswerIndex(answerIndex);
      onAnswer(
        questionIndex,
        questions.options[answerIndex].subId,
        questions.subId
      );
      if (updateDefault) updateDefault(answerIndex);
    }
  };

  return (
    <div className="SliderTestQuestion">
      <div className="SliderTestQuestion__Header">
        <div className="SliderTestQuestion__Header__Heading">
          <p className="SliderTestQuestion__Header__Heading__QuestionIndex">
            {questionNumberingFormat}
          </p>
          <p className="SliderTestQuestion__Header__Heading__QuestionText">
            {questions.text}
          </p>
        </div>
      </div>
      <div className="SliderTestQuestion__Content">
        {isMobile ? (
          <>
            <ColoredText
              color="Blue"
              text={
                selectedAnswerIndex >= 0
                  ? questions?.options[selectedAnswerIndex]?.text
                  : ''
              }
            />
            <SingleLegendSlider
              legendItemFromString={questions.options[0].text}
              legendItemToString={
                questions.options[questions.options.length - 1].text
              }
              startPoint={startPoint}
              max={questions.options.length - 1}
              value={selectedAnswerIndex}
              onChange={handleSetAnswerValue}
              id={`test-question-slider-${questionIndex}`}
              ariaLabel={questions.text}
              marks={sliderMarks}
              marksVisible={false}
              min={0}
            />
          </>
        ) : (
          <Slider
            id={`test-question-slider-${questionIndex}`}
            value={selectedAnswerIndex}
            onChange={handleSetAnswerValue}
            startPoint={startPoint}
            min={0}
            max={questions.options.length - 1}
            marks={sliderMarks}
            ariaLabel={questions.text}
          />
        )}
      </div>
    </div>
  );
};

export default SliderTestQuestion;
