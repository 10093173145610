import './TRNavbar.scss';
import { FC } from 'react';
import { PageIndicator } from '@spotted-zebra-uk/ui-components';

interface ITRNavbar {
  pageCount: number;
  goToReviewPage?: (value: boolean) => void;
}

const TRNavbar: FC<ITRNavbar> = ({ pageCount, goToReviewPage }) => {
  const handleSkip = () => {
    if (goToReviewPage) goToReviewPage(false);
  };
  // TODO: Fix localization [EN-1930]
  return (
    <div className="TRNavbar">
      <PageIndicator
        existingPages={3}
        currentPage={pageCount}
        prefixWord="Page"
        connectorWord="of"
      />
      <p className="TRNavbar__skip" onClick={handleSkip}>
        skip
      </p>
    </div>
  );
};

export default TRNavbar;
