import { ThankYouPageContentType } from 'components/organisms/ThankYouTemplate/ThankYouTemplate.enums';
import { stageSwitchRoutes } from 'constants/routes';
import { ProjectModuleType } from 'generated/graphql';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ThankYouContentDefaultTemplate from '../ThankYouDefaultTemplate/ThankYouDefaultTemplate';

export interface INextTestContent {
  numberOfNotCompletedTests: number;
  renderCandidateReport: boolean;
  stageCandidateId: number;
  projectModuleType?: ProjectModuleType;
}

const NextTestContent: FC<INextTestContent> = ({
  numberOfNotCompletedTests,
  renderCandidateReport,
  stageCandidateId,
  projectModuleType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNextTestClick = () => {
    navigate(stageSwitchRoutes.stage.getUrl(stageCandidateId), {
      replace: true,
    });
  };

  const mainText =
    projectModuleType === ProjectModuleType.TalentReview
      ? t('stage.testCallback.goodProgress')
      : renderCandidateReport
      ? t('stage.testCallback.youHaveNumberOfTestsRemainingUntilReport', {
          testCount: numberOfNotCompletedTests,
        })
      : t('stage.testCallback.goodProgressYouHaveNumberOfTestRemaining', {
          testCount: numberOfNotCompletedTests,
        });

  const buttonText =
    projectModuleType === ProjectModuleType.TalentReview
      ? t('test.instructions.general.continue')
      : t('stage.testCallback.nextTest');

  return (
    <ThankYouContentDefaultTemplate
      mainText={mainText}
      onClick={handleNextTestClick}
      buttonText={buttonText}
      projectModuleType={projectModuleType}
      contentType={ThankYouPageContentType.NEXT_TEST_CONTENT}
    />
  );
};

export default NextTestContent;
