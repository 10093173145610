import './CandidateInformationTitle.scss';
import BubblesBackground from 'components/atoms/BubblesBackground/BubblesBackground';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ICandidateInformationTitle {}

const CandidateInformationTitle: FC<ICandidateInformationTitle> = () => {
  const { t } = useTranslation();
  return (
    <BubblesBackground index={4} className="CandidateInformationTitle">
      <p className="CandidateInformationTitle__Text">
        {t('candidateInformation.title')}
      </p>
    </BubblesBackground>
  );
};

export default CandidateInformationTitle;
