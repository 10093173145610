import {
  setTheme,
  Theme,
} from 'components/organisms/ThemeProvider/ThemeProvider';
import { FC, ReactNode, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';

interface IBaseCompanyThemeProvider {
  children: ReactNode;
}

const BaseCompanyThemeProvider: FC<IBaseCompanyThemeProvider> = ({
  children,
}) => {
  const client = useApolloClient();

  useEffect(() => {
    setTheme(Theme.GREEN);
  }, [client]);

  return <>{children}</>;
};

export default BaseCompanyThemeProvider;
