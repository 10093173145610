import Icon from 'components/atoms/Icon';
import {
  ResultAccessStatus,
  StageActionAvailabilityStatus,
  useResultAccessFindOneQuery,
} from 'generated/graphql';
import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionCard } from '@spotted-zebra-uk/ui-components';

interface ICalibrateCard {
  stageCandidateId: number;
  doneFor: number;
  doneBy: number;
  projectId: number;
  availabilityStatus?: StageActionAvailabilityStatus;
}

const CalibrateCard: FC<ICalibrateCard> = ({
  stageCandidateId,
  doneBy,
  doneFor,
  projectId,
  availabilityStatus,
}) => {
  const navigate = useNavigate();

  const { data } = useResultAccessFindOneQuery({
    variables: {
      stageCandidateId: +stageCandidateId,
    },
    fetchPolicy: 'no-cache',
  });

  const calibrationResult = data?.ResultAccessFindOne;
  const status = getStatus(calibrationResult?.status, availabilityStatus);

  const onClickCard = (e: MouseEvent<HTMLElement>) => {
    if (status === 'active') {
      e.preventDefault();
      navigate(`/stages/${stageCandidateId}/calibrate`, {
        state: {
          projectId,
          doneFor,
          doneBy,
        },
      });
    }
  };

  // TODO: Fix localization [EN-1930]
  return (
    <ActionCard
      ariaLabel={'Calibrate card'}
      onClick={onClickCard}
      id="calibration"
      completed={status === 'completed'}
      disabled={status === 'disabled'}
      label="Calibrate"
      aria-disabled={status !== 'active'}
      icon={<Icon icon="person" aria-hidden focusable="false" />}
    />
  );
};

function getStatus(
  calibrationResultStatus?: ResultAccessStatus,
  availabilityStatus?: StageActionAvailabilityStatus
) {
  if (
    calibrationResultStatus === ResultAccessStatus.LockedForHigherLevel ||
    calibrationResultStatus === ResultAccessStatus.SignedOff
  ) {
    return 'completed';
  }

  return calibrationResultStatus === ResultAccessStatus.Editable &&
    availabilityStatus === StageActionAvailabilityStatus.Available
    ? 'active'
    : 'disabled';
}

export default CalibrateCard;
