import './StageCardInactive.scss';
import { StageCardStatus } from 'enums/stageCardStatus.enum';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IStageCardInactive {
  projectName: string;
  stageName: string;
  companyLogoUrl?: string | undefined;
  companyName: string;
  status: StageCardStatus;
}

const StageCardInactive: FC<IStageCardInactive> = ({
  projectName,
  stageName,
  companyLogoUrl,
  companyName,
  status,
}) => {
  const { t } = useTranslation();
  // TODO: Fix localization [EN-1930]
  const statusString = getStatusString(status);
  const linkAriaLabel = `${statusString} assessment stage at ${companyName} for ${projectName}`;

  return (
    <Link
      to="#"
      aria-disabled
      className="StageCardInactive"
      aria-label={
        status === StageCardStatus.EXPIRED
          ? t('stages.expiredAssessmentStage', { projectName, companyName })
          : linkAriaLabel
      }
    >
      <div className="StageCardInactive__Heading">
        {companyLogoUrl ? (
          <img
            className="StageCardInactive__Heading__CompanyLogo"
            src={companyLogoUrl}
            alt={t('alt.companyLogo', { companyName })}
          />
        ) : (
          <span className="StageCardInactive__Heading__CompanyName">
            {companyName}
          </span>
        )}
      </div>
      <p className="StageCardInactive__StatusText">{statusString}</p>
      <p className="StageCardInactive__Name">{projectName}</p>
      <p className="StageCardInactive__Stage">{stageName}</p>
    </Link>
  );
};

function getStatusString(status: StageCardStatus) {
  if (status === StageCardStatus.EXPIRED) {
    return 'Expired';
  }

  return StageCardStatus.SCHEDULED === status ? 'Scheduled' : 'Signed off';
}

export default StageCardInactive;
