import { Application } from 'interfaces/applications';

export const candidateUrls = {
  localhost: 'http://localhost:3000',
  dev2: 'https://dev2-candidate.spottedzebra.co.uk',
  dev: 'https://dev-candidate.spottedzebra.co.uk',
  stage: 'https://staging-candidate.spottedzebra.co.uk',
  production: 'https://candidate.spottedzebra.co.uk',
};
export const companyUrls = {
  localhost: 'http://localhost:3001',
  dev2: 'https://dev2-company.spottedzebra.co.uk/',
  dev: 'https://dev2-company.spottedzebra.co.uk/',
  stage: 'https://staging-company.spottedzebra.co.uk/',
  production: 'https://company.spottedzebra.co.uk/',
};

const getApplication: { [key in Application]: typeof candidateUrls } = {
  [Application.CANDIDATE]: candidateUrls,
  [Application.COMPANY]: companyUrls,
};

export const getTargetUrl = (application: Application): string => {
  const currentUrl = window.location.href;

  if (currentUrl.includes('localhost')) {
    return getApplication[application].localhost;
  }
  if (currentUrl.includes('dev')) {
    if (currentUrl.includes('dev2')) {
      return getApplication[application].dev2;
    } else return getApplication[application].dev;
  }
  if (currentUrl.includes('staging')) {
    return getApplication[application].stage;
  }
  return getApplication[application].production;
};
