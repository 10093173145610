import { ThankYouPageContentType } from 'components/organisms/ThankYouTemplate/ThankYouTemplate.enums';
import { appSwitchRoutes } from 'constants/routes';
import { ProjectModuleType } from 'generated/graphql';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ThankYouContentDefaultTemplate from '../ThankYouDefaultTemplate/ThankYouDefaultTemplate';

interface IFinishedTalentReviewStage {
  projectModuleType: ProjectModuleType;
  companyLogoUrl: string;
  doneFor: string;
  stageCandidateId: number;
}

const FinishedTalentReviewStage: FC<IFinishedTalentReviewStage> = ({
  projectModuleType,
  companyLogoUrl,
  doneFor,
  stageCandidateId,
}) => {
  const redirectFrom = localStorage.getItem('redirectFrom');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    if (redirectFrom) {
      window.open(redirectFrom, '_self');
      localStorage.removeItem('redirectFrom');
    } else {
      navigate(appSwitchRoutes.stage.getUrl(stageCandidateId));
    }
  };

  const title = t('stage.testCallback.talentReview.title');
  const mainText = t('stage.testCallback.talentReview.mainText');
  const subText = t('stage.testCallback.talentReview.subText');

  return (
    <ThankYouContentDefaultTemplate
      isCalibrationComplete={true}
      mainText={mainText}
      title={title}
      subText={subText}
      onClick={handleClick}
      companyLogoUrl={companyLogoUrl}
      doneFor={doneFor}
      projectModuleType={projectModuleType}
      contentType={ThankYouPageContentType.FINISHED_STAGE_WITHOUT_REPORT}
      buttonText={t('common.gotIt')}
    />
  );
};

export default FinishedTalentReviewStage;
