import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import { RssAssessmentCandidateOpenOneQuery } from 'generated/graphql';
import {
  softSkillProficiencyLevelToBarChartValuesMap,
  technicalSkillProficiencyLevelToBarChartValuesMap,
} from 'helpers/skillsProficiency';

export const parseAssessmentCandidateGradeBands = (
  rssAssessmentCandidateOpenOneQueryData?: RssAssessmentCandidateOpenOneQuery
): {
  proficiency: TBarChartValue;
}[] =>
  rssAssessmentCandidateOpenOneQueryData?.RssAssessmentCandidateOpenOne.gradeBands.map(
    gradeBand => {
      if (gradeBand.__typename === 'SoftSkillSelfAssessmentGradeBandModel') {
        return {
          proficiency:
            softSkillProficiencyLevelToBarChartValuesMap[
              gradeBand.softSkillProficiency
            ],
        };
      }

      if (
        gradeBand.__typename === 'TechnicalSkillSelfAssessmentGradeBandModel'
      ) {
        return {
          proficiency:
            technicalSkillProficiencyLevelToBarChartValuesMap[
              gradeBand.technicalProficiency
            ],
        };
      }

      return { proficiency: 1 };
    }
  ) || [];

export const parseAssessmentCandidateSkillsWithResult = (
  rssAssessmentCandidateOpenOneQueryData?: RssAssessmentCandidateOpenOneQuery
) => {
  if (!rssAssessmentCandidateOpenOneQueryData) {
    return [];
  }

  const parsedSoftSkillsWithResults =
    rssAssessmentCandidateOpenOneQueryData.RssAssessmentCandidateOpenOne.skillsWithResults.map(
      skillWithResult => {
        let answerValue: TBarChartValue | undefined;
        let description: string | undefined;

        if (
          skillWithResult.result?.score.__typename ===
          'SoftSkillSelfAssessmentScoreModel'
        ) {
          answerValue =
            softSkillProficiencyLevelToBarChartValuesMap[
              skillWithResult.result.score.proficiency
            ];
        }

        if (
          skillWithResult.result?.score.__typename ===
          'TechnicalSkillSelfAssessmentScoreModel'
        ) {
          answerValue =
            technicalSkillProficiencyLevelToBarChartValuesMap[
              skillWithResult.result.score.technicalProficiency
            ];
        }

        if (skillWithResult.skill.__typename === 'SoftSkill') {
          description = skillWithResult.skill.description || undefined;
        }

        return {
          id: skillWithResult.skill.id,
          name: skillWithResult.skill.name,
          answerValue,
          previouslyAnswered: Boolean(answerValue),
          description,
        };
      }
    );

  // Previously answered skills should be ordered first by proficiency level
  // (highest grading (e.g. “expert”) at the top) and then alphabetically
  const previouslyAnsweredSkills = parsedSoftSkillsWithResults
    .filter(skillWithAnswer => skillWithAnswer.previouslyAnswered)
    .sort((skill1, skill2) => {
      if (!skill1.answerValue) {
        return -1;
      }

      if (!skill2.answerValue) {
        return 1;
      }

      if (skill1.answerValue === skill2.answerValue) {
        return skill1.name > skill2.name ? 1 : -1;
      }

      return skill1.answerValue > skill2.answerValue ? -1 : 1;
    });
  const activeSkills = parsedSoftSkillsWithResults
    .filter(skillWithAnswer => !skillWithAnswer.previouslyAnswered)
    .sort((skill1, skill2) => (skill1.name > skill2.name ? 1 : -1));

  return [...previouslyAnsweredSkills, ...activeSkills];
};
