import { IconType } from 'components/atoms/Icon';
import {
  AdjustmentQuestionnaireResponse,
  GetStageActionsQuery,
  StageActionCalibrationModel,
  StageActionCognitiveTestModel,
  StageActionDeiFormModel,
  StageActionExternalAssessmentModel,
  StageActionFormModel,
  StageActionPersonalityTestModel,
  StageActionRssAssessmentModel,
  StageCandidateActionType,
} from 'generated/graphql';
import { getCoderbyteExtraTimeValue } from '../Stage/StageView/StageView.helpers';
import { convertExtraTimeValue } from '../Test/Tests/helpers/extraTime';

const untimedTests = [
  StageCandidateActionType.PersonalityTest,
  StageCandidateActionType.RssAssessment,
  StageCandidateActionType.DeiForm,
  StageCandidateActionType.Form,
];

export type steps = 'intro' | 'options' | 'summary' | 'details' | '';

export type adjustmentsResponsesType = AdjustmentQuestionnaireResponse[];

export const hasAuditoryAdjustment = (responses: adjustmentsResponsesType) =>
  responses.includes(AdjustmentQuestionnaireResponse.AuditoryProcessing);

export const isUntimedTest = (test: StageCandidateActionType) =>
  untimedTests.includes(test);

export const hasOnlyUntimedAssessments = (tests: StageCandidateActionType[]) =>
  tests.every(test => isUntimedTest(test));

const hasOnlyAuditoryAdjustment = (responses: adjustmentsResponsesType) =>
  responses.length === 1 && hasAuditoryAdjustment(responses);

export const requiresAdjustments = (
  responses: adjustmentsResponsesType,
  tests: StageCandidateActionType[]
) => {
  // If user has only un-timed tests or only auditory requirements, we cannot offer adjustments
  return !(
    hasOnlyUntimedAssessments(tests) || hasOnlyAuditoryAdjustment(responses)
  );
};

export type LabelsType = Exclude<
  StageCandidateActionType,
  | StageCandidateActionType.Calibration
  | StageCandidateActionType.TalentReviewTest
>;

export const mapTestTypeToLabel: Record<LabelsType, string> = {
  [StageCandidateActionType.PersonalityTest]: 'personality',
  [StageCandidateActionType.CognitiveInductiveTest]: 'inductive',
  [StageCandidateActionType.CognitiveNumericalTest]: 'numerical',
  [StageCandidateActionType.CognitiveVerablTest]: 'verbal',
  [StageCandidateActionType.CodingExternalAssessment]: 'coderbyte',
  [StageCandidateActionType.RssAssessment]: 'selfAssessment',
  [StageCandidateActionType.DeiForm]: 'dei',
  [StageCandidateActionType.Form]: 'form',
};

export const mapTestToIcon: Record<LabelsType, IconType> = {
  [StageCandidateActionType.PersonalityTest]: 'personality_test',
  [StageCandidateActionType.CognitiveInductiveTest]: 'inductive_test',
  [StageCandidateActionType.CognitiveNumericalTest]: 'numerical_test',
  [StageCandidateActionType.CognitiveVerablTest]: 'verbal_test',
  [StageCandidateActionType.CodingExternalAssessment]: 'cog',
  [StageCandidateActionType.RssAssessment]: 'skills_self_assessment',
  [StageCandidateActionType.DeiForm]: 'dei',
  [StageCandidateActionType.Form]: 'questions',
};

export const getTimeAmountAdded = (responses: adjustmentsResponsesType) => {
  // Extra time will be the most amount possible based on the adjustments needed.
  // For example, if 3 responses are selected and one of them is Assistive Technology
  // then the user will receive the most amount of extra time possible (50%) regardless
  // what the other responses are.
  let extraTime = 25;

  if (responses.includes(AdjustmentQuestionnaireResponse.PhysicalChallenges)) {
    extraTime = 35;
  }

  if (responses.includes(AdjustmentQuestionnaireResponse.AssistiveTechnology)) {
    extraTime = 50;
  }

  return `+${extraTime}%`;
};

export const stepsOrder = ['intro', 'options', 'summary', 'details'];

export const shouldRedirectToIntro = (completedStep: steps) => {
  return completedStep === '';
};

export type StageActionsType =
  | StageActionCognitiveTestModel
  | StageActionExternalAssessmentModel
  | StageActionDeiFormModel
  | StageActionFormModel
  | StageActionPersonalityTestModel
  | StageActionRssAssessmentModel
  | StageActionCalibrationModel;

/** 
* The purpose of this function is to check if extra time was added through
* the mitigating circumstance flow in admin app. If so, that value will be the 
* one displayed, if not, then the value selected based on the user's answers will be displayed.
@returns {string} the percentage of added time, e.g "+20%"
**/
export const getExtraTimeValue = (
  stageAction: StageActionsType,
  extraTime: string
) => {
  if (stageAction.__typename === 'StageActionCognitiveTestModel') {
    return !!stageAction?.testCandidate?.timeModifierPercentage
      ? `+${convertExtraTimeValue(
          stageAction.testCandidate.timeModifierPercentage
        )}%`
      : extraTime;
  } else if (stageAction?.__typename === 'StageActionExternalAssessmentModel') {
    return !!stageAction?.eaStageActionCandidate.modifier
      ? `+${getCoderbyteExtraTimeValue(stageAction.eaStageActionCandidate.modifier)}%`
      : extraTime;
  }
  return extraTime;
};

export const getStageAction = (
  stageData: GetStageActionsQuery | undefined,
  test: StageCandidateActionType
) =>
  stageData?.GetStageActions?.actions.find(
    action => action.type === test
  ) as StageActionsType;
