import './EqualityAndDiversityFormContainerLogos.scss';
import { Maybe } from 'interfaces/maybe';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IEqualityAndDiversityFormContainerLogos {
  logoUrl?: Maybe<string>;
}

const EqualityAndDiversityFormContainerLogos: FC<IEqualityAndDiversityFormContainerLogos> =
  ({ logoUrl }) => {
    const { t } = useTranslation();
    return (
      <div className="EqualityAndDiversityFormContainerLogos">
        {
          <img
            alt={t('alt.companyLogo', { companyName: 'Spotted Zebra' })}
            src="/sz-fav.svg"
            className="EqualityAndDiversityFormContainerLogos__LogoImage"
          />
        }
        {logoUrl && (
          <img
            alt={t('alt.formOwnerCompanyLogo')}
            src={logoUrl}
            className="EqualityAndDiversityFormContainerLogos__LogoImage"
          />
        )}
      </div>
    );
  };

export default EqualityAndDiversityFormContainerLogos;
