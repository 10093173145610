import LogoutButton from 'components/organisms/LogoutButton/LogoutButton';
import ExpirationNotification from 'components/organisms/StageNavbar/ExpirationNotification';
import { appSwitchRoutes } from 'constants/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NavLink } from '@spotted-zebra-uk/ui-components';

interface INavbar {
  stageEndTime?: Date | null;
}

const Navbar: FC<INavbar> = ({ stageEndTime }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <nav className="navbar">
      <section className="navbar__redirect">
        {stageEndTime && <ExpirationNotification endTime={stageEndTime} />}
        <NavLink
          to={appSwitchRoutes.stages.getUrl()}
          onClick={e => {
            e.preventDefault();
            navigate(appSwitchRoutes.stages.getUrl());
          }}
          aria-label={t('stage.backToListOfAssessments')}
          direction="backward"
          className="navbar__back-button"
        >
          {t('stage.assessmentList')}
        </NavLink>
      </section>

      <LogoutButton />
    </nav>
  );
};

export default Navbar;
