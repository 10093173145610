import { useEffect, useRef } from 'react';

export function useTimeout(callback: () => void, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    timeoutRef.current = setTimeout(savedCallback.current, delay);
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [delay]);

  return timeoutRef.current;
}
