import { ThankYouPageContentType } from 'components/organisms/ThankYouTemplate/ThankYouTemplate.enums';
import { stageSwitchRoutes } from 'constants/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ThankYouContentDefaultTemplate from '../ThankYouDefaultTemplate/ThankYouDefaultTemplate';

interface IFinishedPracticeStageContent {
  stageCandidatId: number;
  testCandidateId: number;
}

const FinishedPracticeStageContent: FC<IFinishedPracticeStageContent> = ({
  stageCandidatId,
  testCandidateId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(stageSwitchRoutes.test.getUrl(stageCandidatId, testCandidateId));
  };

  const mainText = t('stage.testCallback.youHaveCompletedYourPracticeTest');

  return (
    <ThankYouContentDefaultTemplate
      onClick={handleClick}
      mainText={mainText}
      contentType={ThankYouPageContentType.FINISHED_PRACTICE_STAGE}
    />
  );
};

export default FinishedPracticeStageContent;
