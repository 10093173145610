import { FC } from 'react';
import clsx from 'clsx';
import { BarChart, TBarChartValue } from '@spotted-zebra-uk/ui-components';

interface ISkillsListItem {
  title: string;
  chartValue?: TBarChartValue;
  highlighted: boolean;
  onClick: () => void;
}

export const SkillsListItem: FC<ISkillsListItem> = ({
  title,
  chartValue,
  highlighted,
  onClick,
}) => (
  <div className="skills-list-item" onClick={onClick}>
    <div
      className={clsx('skills-list-item__title', {
        'skills-list-item__title--highlighted': highlighted,
      })}
    >
      {title}
    </div>
    {chartValue && (
      <BarChart value={chartValue} className="skills-list-item__bar-chart" />
    )}
  </div>
);
