import './CandidateInformationMobile.scss';
import Container from 'components/molecules/Container/Container';
import { FC } from 'react';
import CandidateInformationContainer from 'views/CandidateInformation/CandidateInformationContainer/CandidateInformationContainer';
import { Modal, ModalSize } from '@spotted-zebra-uk/ui-components';

interface ICandidateInformationMobile {
  stageCandidateId: number;
  onCloseModal: () => void;
}

const CandidateInformationMobile: FC<ICandidateInformationMobile> = ({
  stageCandidateId,
  onCloseModal,
}) => {
  return (
    //@ts-ignore
    <Modal
      className="CandidateInformationDesktop"
      onClose={onCloseModal}
      isOpen
      modalSize={ModalSize.SMALL}
    >
      <Container className="CandidateInformationMobile">
        <CandidateInformationContainer
          stageCandidateId={stageCandidateId}
          onCandidateInformationProvided={onCloseModal}
          onCandidateInformationFormPreviouslyCompleted={onCloseModal}
        />
      </Container>
    </Modal>
  );
};

export default CandidateInformationMobile;
