import './DesktopPersonalityTestSliderLegend.scss';
import { PersonalityOptionModel } from 'generated/graphql';
import { FC } from 'react';

interface IDesktopPersonalityTestSliderLegend {
  options: PersonalityOptionModel[];
}

const DesktopPersonalityTestSliderLegend: FC<IDesktopPersonalityTestSliderLegend> =
  ({ options }) => {
    return (
      <div className="DesktopPersonalityTestSliderLegend">
        <div className="DesktopPersonalityTestSliderLegend__Content">
          <p className="DesktopPersonalityTestSliderLegend__Item">
            {options[0]?.text}
          </p>
          <p className="DesktopPersonalityTestSliderLegend__Item">
            {options[(options.length - 1) / 2]?.text}
          </p>
          <p className="DesktopPersonalityTestSliderLegend__Item">
            {options[options.length - 1]?.text}
          </p>
        </div>
      </div>
    );
  };

export default DesktopPersonalityTestSliderLegend;
