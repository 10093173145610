export enum Tests {
  PERSONALITY = 'PERSONALITY',
  MOTIVATION = 'MOTIVATION',
  NUMERICAL = 'NUMERICAL',
  INDUCTIVE = 'INDUCTIVE',
  VERBAL = 'VERBAL',
  COGNITIVE_COMBINED = 'COGNITIVE_COMBINED',
}

export enum TestStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED',
}

export enum PersonalityTestsAnswers {
  STRONGLY_DISAGREE,
  DISAGREE,
  SLIGHTELY_DISAGREE,
  NEUTRAL,
  SLIGHTELY_AGREE,
  AGREE,
  STRONGLY_AGREE,
}

export enum VerbalTestAnswers {
  DEFINITELY_FALSE,
  PROBABLY_FALSE,
  NEUTRAL,
  PROBABLY_TRUE,
  DEFINITELY_TRUE,
}
