import './TestHeader.scss';
import ProgressBar, {
  IProgressBar,
} from 'components/molecules/ProgressBar/ProgressBar';
import { useTrackWindowChange } from 'hooks/useTrackWindowChange';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Countdown,
  IPageIndicatorProps,
  PageIndicator,
} from '@spotted-zebra-uk/ui-components';

interface ITestHeader {
  assessmentId: number;
  className?: string;
  hasTimer?: boolean;
  hasProgressBar?: boolean;
  timerInterval?: number;
  timeLimit?: number;
  onTimeout?: () => void;
  progressBarProps?: IProgressBar;
  pageIndicatorProps?: IPageIndicatorProps;
}

const TestHeader: FC<ITestHeader> = ({
  assessmentId,
  className,
  hasTimer = false,
  timerInterval = 1,
  onTimeout,
  hasProgressBar = true,
  timeLimit,
  progressBarProps,
  pageIndicatorProps,
}) => {
  const { t } = useTranslation();
  useTrackWindowChange(assessmentId, hasTimer);

  return (
    <div className={`TestHeader${className ? ` ${className}` : ''}`}>
      {hasProgressBar && progressBarProps && (
        <ProgressBar {...progressBarProps} />
      )}
      <div className="TestHeader__Main">
        {pageIndicatorProps && <PageIndicator {...pageIndicatorProps} />}
        {hasTimer && (
          <Countdown
            key={`timer-${progressBarProps?.value}`}
            timeInterval={timerInterval}
            timeLimit={timeLimit}
            onTimeout={onTimeout}
            getCountdownText={(count: number) =>
              `${t('test.countdown', {
                count,
              })}`
            }
          />
        )}
      </div>
    </div>
  );
};

export default TestHeader;
