import './InductiveTestQuestionItem.scss';
import SVGRenderer from 'components/molecules/SVGRenderer/SVGRenderer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@spotted-zebra-uk/ui-components';

interface IInductiveTestQuestionItem {
  image: string;
  index: number;
  width?: number;
  height?: number;
}

const InductiveTestQuestionItem: FC<IInductiveTestQuestionItem> = ({
  image,
  index,
  width = 100,
  height = 100,
}) => {
  const { t } = useTranslation();
  return (
    <div className="InductiveTestQuestionItem" key={image}>
      <SVGRenderer
        src={image}
        alt={t('alt.inductiveTestQuestionPart', { questionPart: index })}
        size={{ width, height }}
        loading={() => (
          <div
            style={{
              width: 120,
              height: 120,
              border: '1px solid rgba(0, 0, 0, 0.16)',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Loader variant="bubbles" />
          </div>
        )}
      />
      <p className="InductiveTestQuestionItem__Index">{index + 1}</p>
    </div>
  );
};

export default InductiveTestQuestionItem;
