import './ProgressBar.scss';
import useContainerSize from 'hooks/containerSize';
import { FC } from 'react';

export interface IProgressBar {
  value: number;
  maxValue?: number;
}

const ProgressBar: FC<IProgressBar> = ({ value, maxValue = 100 }) => {
  const [progressBarSize, progressBarRef] = useContainerSize();

  const progressWidth = (progressBarSize.width * value) / maxValue;

  return (
    <div ref={progressBarRef} className="ProgressBar ProgressBar--Green">
      <div style={{ width: progressWidth }} className="ProgressBar__Progress" />
    </div>
  );
};

export default ProgressBar;
