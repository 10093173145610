import './CandidateInformationFormContainer.scss';
import Loader from 'components/atoms/Loader/Loader';
import { FormType } from 'enums/form';
import { Maybe } from 'generated/graphql';
import useRespondantForm from 'hooks/useRespondantForm';
import { FC, useMemo } from 'react';
import CandidateInformationForm from './CandidateInformationForm/CandidateInformationForm';
import CandidateInformationMobileTitle from './CandidateInformationTitle/CandidateInformationTitle';

interface ICandidateInformationFormContainer {
  stageProjectCompanyId: number;
  stageProjectCompanyLogoUrl?: Maybe<string>;
  projectId: number;
  onCandidateInformationProvided?: () => void;
  onCandidateInformationFormPreviouslyCompleted?: () => void;
}

const CandidateInformationFormContainer: FC<ICandidateInformationFormContainer> =
  ({
    stageProjectCompanyId,
    stageProjectCompanyLogoUrl,
    projectId,
    onCandidateInformationProvided,
    onCandidateInformationFormPreviouslyCompleted,
  }) => {
    const [
      getCompanyCandidateRequestQueryResponse,
      formFields,
      handleSaveCandidateInformation,
      handleSkip,
    ] = useRespondantForm({
      associatedId: stageProjectCompanyId,
      formOwnerId: stageProjectCompanyId,
      formType: FormType.CI_FORM,
      projectId: projectId,
      onGetRespondantFormPreviouslyCompleted:
        onCandidateInformationFormPreviouslyCompleted,
      onSaveRespondantFormCompleted: onCandidateInformationProvided,
    });

    const isSkipButtonVisible = useMemo(() => {
      if (getCompanyCandidateRequestQueryResponse.data?.respondantForm) {
        return !getCompanyCandidateRequestQueryResponse.data.respondantForm.respondantFieldWithAnswers.some(
          fieldWithAnswer => !fieldWithAnswer.isOptional
        );
      }

      return false;
    }, [getCompanyCandidateRequestQueryResponse.data]);

    if (getCompanyCandidateRequestQueryResponse.loading) {
      return (
        <div className="CandidateInformationFormContainer__LoaderWrapper">
          <Loader />
        </div>
      );
    }

    if (getCompanyCandidateRequestQueryResponse.data && formFields) {
      return (
        <div className="CandidateInformationFormContainer">
          <div className="CandidateInformationFormContainer__Logos">
            {
              <img
                alt=""
                src="/sz-fav.svg"
                className="CandidateInformationFormContainer__Logos__LogoImage"
              />
            }
            {stageProjectCompanyLogoUrl && (
              <img
                alt=""
                src={stageProjectCompanyLogoUrl}
                className="CandidateInformationFormContainer__Logos__LogoImage"
              />
            )}
          </div>
          <div className="CandidateInformationFormContainer__TitleWrapper">
            <CandidateInformationMobileTitle />
          </div>
          <CandidateInformationForm
            onSubmit={handleSaveCandidateInformation}
            fields={formFields}
            isSkipButtonVisible={isSkipButtonVisible}
            onSkipButtonClick={handleSkip}
          />
        </div>
      );
    }

    return null;
  };

export default CandidateInformationFormContainer;
