import { ReactComponent as CognitiveTestIcon } from 'assets/icons/Cognitive.svg';
import { ReactComponent as CogIcon } from 'assets/icons/ic_cog.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg';
import { ReactComponent as StudyIcon } from 'assets/icons/Study.svg';
import { ReactComponent as DeiTestIcon } from 'assets/icons/test/dei.svg';
import { ReactComponent as InductiveTestIcon } from 'assets/icons/test/inductive.svg';
import { ReactComponent as NumericalTestIcon } from 'assets/icons/test/numerical.svg';
import { ReactComponent as PersonalityTestIcon } from 'assets/icons/test/personality.svg';
import { ReactComponent as SkillTestIcon } from 'assets/icons/test/skills_self_assessment.svg';
import { ReactComponent as VerbalTestIcon } from 'assets/icons/test/verbal.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/Time.svg';
import { ReactComponent as TimerIcon } from 'assets/icons/Timer.svg';
import {
  StageCandidateActionType,
  TestSubType,
  TestType,
} from 'generated/graphql';
import i18n from 'helpers/i18n';
import { Maybe } from 'interfaces/maybe';
import { FC } from 'react';

export interface ISingleTestInfoConfigNote {
  id: number;
  icon: FC;
  text: string;
  extraTimeConfig?: {
    text: string;
    action: 'openModal';
  };
  alt: string;
  className?: string;
  dataTestid?: string;
  accessibilityAdjustment?: number | null;
}

interface ISingleTestInfoConfig {
  icon: FC;
  title: string;
  key?: StageCandidateActionType;
  notes: ISingleTestInfoConfigNote[];
}

const getTimeTranslation = (time?: Maybe<number>, defaultTime = 15) => {
  if (time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    let timeTranslation = '';

    if (minutes > 0) {
      timeTranslation += i18n.t('common.time.minute', { count: minutes });
    }

    if (seconds > 0) {
      timeTranslation += ` ${i18n.t('common.time.second', { count: seconds })}`;
    }

    return timeTranslation;
  }

  return i18n.t('common.time.minute', {
    count: defaultTime,
  });
};

const config: {
  [key in TestType | TestSubType | string]: (
    timeRequirement?: Maybe<number>,
    timePerQuestion?: Maybe<number>,
    extraTimeAdded?: Maybe<number>
  ) => ISingleTestInfoConfig;
} = {
  [TestSubType.Inductive]: (
    timeRequirement,
    timePerQuestion,
    extraTimeAdded
  ) => ({
    icon: InductiveTestIcon,
    key: StageCandidateActionType.CognitiveInductiveTest,
    title: i18n.t('test.instructions.inductive.name'),
    notes: [
      {
        id: 1,
        icon: StudyIcon,
        text: i18n.t('test.instructions.inductive.description'),
        alt: '',
      },
      {
        id: 2,
        icon: TimeIcon,
        text: i18n.t('test.instructions.inductive.time', {
          time: getTimeTranslation(timeRequirement),
        }),
        accessibilityAdjustment: extraTimeAdded,
        alt: '',
        dataTestid: 'inductive-test--time-required',
      },
      {
        id: 3,
        icon: TimerIcon,
        text: i18n.t('test.instructions.inductive.timePerQuestion', {
          time: getTimeTranslation(timePerQuestion, 1),
        }),
        extraTimeConfig: !timePerQuestion
          ? {
              text: i18n.t('common.extraTime'),
              action: 'openModal',
            }
          : undefined,
        accessibilityAdjustment: extraTimeAdded,
        className: 'TestInfoNote--Important',
        alt: '',
        dataTestid: 'inductive-test--time-required',
      },
    ],
  }),
  [TestSubType.Numerical]: (
    timeRequirement,
    timePerQuestion,
    extraTimeAdded
  ) => ({
    icon: NumericalTestIcon,
    key: StageCandidateActionType.CognitiveNumericalTest,
    title: i18n.t('test.instructions.numerical.name'),
    notes: [
      {
        id: 4,
        icon: StudyIcon,
        text: i18n.t('test.instructions.numerical.description1'),
        alt: '',
      },
      {
        id: 16,
        icon: PencilIcon,
        text: i18n.t('test.instructions.numerical.description2'),
        alt: '',
      },
      {
        id: 5,
        icon: TimeIcon,
        text: i18n.t('test.instructions.numerical.time', {
          time: getTimeTranslation(timeRequirement),
        }),
        alt: '',
        accessibilityAdjustment: extraTimeAdded,
        dataTestid: 'numerical-test--time-required',
      },
      {
        id: 6,
        icon: TimerIcon,
        text: i18n.t('test.instructions.numerical.timePerQuestion', {
          time: getTimeTranslation(timePerQuestion, 3),
        }),
        extraTimeConfig: !timePerQuestion
          ? { text: i18n.t('common.extraTime'), action: 'openModal' }
          : undefined,
        className: 'TestInfoNote--Important',
        accessibilityAdjustment: extraTimeAdded,
        alt: '',
        dataTestid: 'numerical-test--time-per-question',
      },
    ],
  }),
  [TestType.Personality]: timeRequirement => ({
    icon: PersonalityTestIcon,
    key: StageCandidateActionType.PersonalityTest,
    title: i18n.t('test.instructions.personality.name'),
    notes: [
      {
        id: 10,
        icon: StudyIcon,
        text: i18n.t('test.instructions.personality.description'),
        alt: '',
      },
      {
        id: 11,
        icon: TimeIcon,
        text: i18n.t('test.instructions.personality.time', {
          time: getTimeTranslation(timeRequirement, 10),
        }),
        alt: '',
        dataTestid: 'personality-test--required-time',
      },
    ],
  }),
  [TestSubType.Verbal]: (timeRequirement, timePerQuestion, extraTimeAdded) => ({
    icon: VerbalTestIcon,
    key: StageCandidateActionType.CognitiveVerablTest,
    title: i18n.t('test.instructions.verbal.name'),
    notes: [
      {
        id: 13,
        icon: StudyIcon,
        text: i18n.t('test.instructions.verbal.description'),
        alt: '',
      },
      {
        id: 14,
        icon: TimeIcon,
        text: i18n.t('test.instructions.verbal.time', {
          time: getTimeTranslation(timeRequirement),
        }),
        alt: '',
        accessibilityAdjustment: extraTimeAdded,
        dataTestid: 'verbal-test--time-required',
      },
      {
        id: 15,
        icon: TimerIcon,
        text: i18n.t('test.instructions.verbal.timePerQuestion', {
          time: getTimeTranslation(timePerQuestion, 3),
        }),
        extraTimeConfig: !timePerQuestion
          ? { text: i18n.t('common.extraTime'), action: 'openModal' }
          : undefined,
        className: 'TestInfoNote--Important',
        alt: '',
        accessibilityAdjustment: extraTimeAdded,
        dataTestid: 'verbal-test--time-per-question',
      },
    ],
  }),
  [TestType.Cognitive]: () => ({
    icon: CognitiveTestIcon,
    title: i18n.t('test.instructions.cognitive.name'),
    notes: [
      {
        id: 16,
        icon: StudyIcon,
        text: i18n.t('test.instructions.cognitive.description'),
        alt: 'Test Information Icon',
      },
    ],
  }),
  [TestType.TalentReview]: () => ({
    icon: PersonalityTestIcon,
    key: StageCandidateActionType.TalentReviewTest,
    title: i18n.t('test.instructions.talentReview.name'),
    notes: [
      {
        id: 17,
        icon: StudyIcon,
        text: i18n.t('test.instructions.talentReview.description'),
        alt: 'Test Information Icon',
      },
    ],
  }),
  selfSkillAsessment: () => ({
    icon: SkillTestIcon,
    key: StageCandidateActionType.RssAssessment,
    title: i18n.t('assessment.skillsSelfAssessment'),
    notes: [
      {
        id: 18,
        icon: StudyIcon,
        text: i18n.t('assessment.thisRecords'),
        alt: '',
      },
      {
        id: 19,
        icon: TimeIcon,
        text: i18n.t('assessment.noTimeLimit'),
        alt: '',
      },
    ],
  }),
  dei: () => ({
    icon: DeiTestIcon,
    key: StageCandidateActionType.DeiForm,
    title: i18n.t('test.instructions.dei.name'),
    notes: [
      {
        id: 10,
        icon: StudyIcon,
        text: i18n.t('test.instructions.dei.description'),
        alt: '',
      },
      {
        id: 11,
        icon: TimeIcon,
        text: i18n.t('test.instructions.dei.time'),
        alt: '',
        dataTestid: 'dei-test--required-time',
      },
    ],
  }),
};

const configCodingTest = (
  timeEstimate?: Maybe<number>,
  timeRequirement?: Maybe<number>,
  extraTimeAdded?: Maybe<number>
): ISingleTestInfoConfig => {
  const timeNote = timeRequirement
    ? {
        id: 21,
        icon: TimerIcon,
        text: i18n.t('test.instructions.coding.time_limit_minutes_only', {
          time: getTimeTranslation(timeRequirement),
        }),
        className: 'TestInfoNote--Important',
        accessibilityAdjustment: extraTimeAdded,
        dataTestid: 'coding-test--time-required',
      }
    : {
        id: 21,
        icon: TimeIcon,
        text: i18n.t('test.instructions.coding.time_estimate_minutes_only', {
          time: getTimeTranslation(timeEstimate),
        }),
        accessibilityAdjustment: extraTimeAdded,
        dataTestid: 'coding-test--time-estimated',
      };
  return {
    icon: CogIcon,
    key: StageCandidateActionType.CodingExternalAssessment,
    title: i18n.t('test.instructions.coding.name'),
    notes: [
      {
        id: 20,
        icon: StudyIcon,
        text: i18n.t('test.instructions.coding.description'),
        alt: '',
      },
      {
        ...timeNote,
        alt: '',
      },
    ],
  };
};

export { configCodingTest };
export default config;
