import clsx from 'clsx';
import { appSwitchRoutes } from 'constants/routes';
import { StageCandidateActionType } from 'generated/graphql';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Link } from '@spotted-zebra-uk/ui-components';
import {
  adjustmentsResponsesType,
  hasAuditoryAdjustment,
  hasOnlyUntimedAssessments,
  requiresAdjustments,
  shouldRedirectToIntro,
  steps,
} from '../AccessibilityAdjustments.helpers';
import style from './AccessibilitySummary.module.scss';

const AccessibilitySummary = ({
  stageCandidateId,
  responses,
  completedStep,
  tests,
  onSubmit,
  loading,
}: {
  stageCandidateId: number;
  completedStep: steps;
  responses: adjustmentsResponsesType;
  tests: StageCandidateActionType[];
  onSubmit: (redirectUrl: string, step?: steps) => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to flow intro page on page refresh
    shouldRedirectToIntro(completedStep) &&
      navigate(
        appSwitchRoutes.accessibilityAdjustments.getUrl(
          stageCandidateId,
          'intro'
        )
      );
  }, [completedStep, stageCandidateId, navigate]);

  const handleGoBack = () => {
    navigate(
      appSwitchRoutes.accessibilityAdjustments.getUrl(
        stageCandidateId,
        'options'
      )
    );
  };

  const hasAdjustments = requiresAdjustments(responses, tests);

  const handleSubmit = () => {
    const link = hasAdjustments
      ? appSwitchRoutes.accessibilityAdjustments.getUrl(
          stageCandidateId,
          'details'
        )
      : appSwitchRoutes.stage.getUrl(stageCandidateId);
    onSubmit(link, 'summary');
  };

  return (
    <section className={style.container} data-testid="accessibility-summary">
      {hasAdjustments ? (
        <RequiresAdjustments />
      ) : (
        <>
          <p className={style.description}>
            {t('accessibilityAdjustments.summary.noAdjustmentsNecessary')}
          </p>
          {hasOnlyUntimedAssessments(tests) && (
            <p className={style.boldDescription}>
              {t('accessibilityAdjustments.summary.hasUntimedTests')}
            </p>
          )}
          {hasAuditoryAdjustment(responses) && (
            <p className={style.boldDescription}>
              {t('accessibilityAdjustments.summary.hasAuditoryAdjustment')}
            </p>
          )}
          <p className={style.disclaimer}>
            {t('accessibilityAdjustments.summary.disclaimer')}
          </p>
          <Link
            to="mailto:candidate@spottedzebra.co.uk"
            target="_blank"
            className="need-more-time-modal__link"
            aria-label={t('common.sendEmailTo', {
              email: 'candidate@spottedzebra.co.uk.',
            })}
          >
            candidate@spottedzebra.co.uk
          </Link>
        </>
      )}
      <div
        className={clsx(style.actions, hasAdjustments && style.largeButtons)}
      >
        {hasAdjustments && (
          <Button
            variant="secondary"
            onClick={handleGoBack}
            aria-label={t('accessibilityAdjustments.goBack')}
            data-testid="accessibility-summary__go-back-button"
          >
            {t('accessibilityAdjustments.goBack')}
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          type="submit"
          aria-label={t('common.continue')}
          loading={loading}
          data-testid="accessibility-summary__submit-button"
        >
          {t('common.continue')}
        </Button>
      </div>
    </section>
  );
};

export default AccessibilitySummary;

const RequiresAdjustments = () => {
  const { t } = useTranslation();
  return (
    <>
      <p className={style.description}>
        {t('accessibilityAdjustments.summary.adjustmentsNecessary')}
      </p>
      <p className={style.description}>
        {t('accessibilityAdjustments.summary.confirm')}
      </p>
    </>
  );
};
