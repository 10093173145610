import { appSwitchRoutes } from 'constants/routes';
import { ModalTypes } from 'enums/modalTypes';
import { useGetStageActionsQuery } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import EqualityAndDiversityFormContainer from './EqualityAndDiversityFormContainer/EqualityAndDiversityFormContainer';

interface IEquailityAndDiversityContainer {
  stageCandidateId: number;
  onEqualityStatementButtonClick: () => void;
}

const EquailityAndDiversityContainer: FC<IEquailityAndDiversityContainer> = ({
  stageCandidateId,
  onEqualityStatementButtonClick,
}) => {
  const navigate = useNavigate();
  const { handleMsgType } = useNotification();

  const stageActions = useGetStageActionsQuery({
    variables: {
      stageCandidateId,
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      closeModal(ModalTypes.CANDIDATE_INFORMATION);
      navigate(appSwitchRoutes.stages.path);
    },
  });

  const companyInfo = stageActions.data?.GetStageActions.company;
  const projectId = stageActions.data?.GetStageActions.project.id;

  if (companyInfo && projectId) {
    return (
      <EqualityAndDiversityFormContainer
        stageProjectCompanyId={companyInfo.id}
        projectId={projectId}
        stageProjectCompanyLogoUrl={companyInfo.logoUrl}
        onEqualityStatementButtonClick={onEqualityStatementButtonClick}
      />
    );
  }

  return null;
};

export default EquailityAndDiversityContainer;
