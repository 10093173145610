import { ThankYouTemplate } from 'components/organisms/ThankYouTemplate/ThankYouTemplate';
import { ThankYouPageContentType } from 'components/organisms/ThankYouTemplate/ThankYouTemplate.enums';
import { stageSwitchRoutes } from 'constants/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IFinishedPracticeTestContent {
  stageCandidateId: number;
  testCandidateId: number;
}

const FinishedPracticeTestContent: FC<IFinishedPracticeTestContent> = ({
  stageCandidateId,
  testCandidateId,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const MAIN_TEXT = t('stage.testCallback.youHaveCompletedYourPracticeTest');

  const handleClick = () => {
    navigate(stageSwitchRoutes.test.getUrl(stageCandidateId, testCandidateId));
  };

  return (
    <ThankYouTemplate
      onClick={handleClick}
      mainText={MAIN_TEXT}
      contentType={ThankYouPageContentType.FINISHED_PRACTICE_STAGE}
      title={t('stage.testCallback.testPracticeCompleted')}
      buttonText={t('test.instructions.general.closePracticeTest')}
    />
  );
};

export default FinishedPracticeTestContent;
