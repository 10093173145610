import SimpleBarChart from 'components/atoms/SimpleBarChart/SimpleBarChart';
import Container from 'components/molecules/Container/Container';
import { useWindowSize } from 'hooks/containerSize';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@spotted-zebra-uk/ui-components';
import { TSkillWithAnswer } from '../types';
import { SkillsListItem } from './SkillsListItem';

export interface ISkillsListModal {
  onClose: () => void;
  isOpen: boolean;
  softSkillsWithAnswers: TSkillWithAnswer[];
  focusedSoftSkillId?: number;
  isSubmitVisible: boolean;
  isSubmitButtonDisabled: boolean;
  hasMarkAllButton?: boolean;
  skillName: string;
  onListItemClick: (skillWithAnswerId: number) => void;
  onMarkAllAsNoKnowledgeClick: () => void;
  onSubmit: () => void;
}

const SkillsListModal: FC<ISkillsListModal> = ({
  onClose,
  isOpen,
  softSkillsWithAnswers,
  focusedSoftSkillId,
  isSubmitVisible,
  isSubmitButtonDisabled,
  hasMarkAllButton,
  skillName,
  onMarkAllAsNoKnowledgeClick,
  onListItemClick,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [size] = useWindowSize();
  const previouslyAnsweredSkills = softSkillsWithAnswers.filter(
    skillWithAnswer => skillWithAnswer.previouslyAnswered
  );
  const activeSkills = softSkillsWithAnswers.filter(
    skillWithAnswer => !skillWithAnswer.previouslyAnswered
  );
  const showDivider = Boolean(
    previouslyAnsweredSkills.length && activeSkills.length
  );

  return (
  //@ts-ignore
    <Modal className="skills-list-modal" isOpen={isOpen} onClose={onClose}>
      <Container
        className="skills-list-modal__content"
        //To show submit button properly, TODO - refactor when mobile design is updated
        style={{ height: size.height - 130, minHeight: size.height - 130 }}
      >
        <div className="skills-list-modal__title">Skills</div>
        <div className="skills-list-modal__list-wrapper">
          {previouslyAnsweredSkills.length ? (
            <div className="skills-list-modal__previously-answered-skills">
              {previouslyAnsweredSkills.map(softSkill => (
                <SkillsListItem
                  key={softSkill.id}
                  title={softSkill.name}
                  highlighted={softSkill.id === focusedSoftSkillId}
                  chartValue={softSkill.answerValue}
                  onClick={() => onListItemClick(softSkill.id)}
                />
              ))}
            </div>
          ) : null}
          {showDivider && <div className="skills-list-modal__divider" />}
          {activeSkills.length ? (
            <div className="skills-list-modal__active-skills">
              {activeSkills.map(softSkill => (
                <SkillsListItem
                  key={softSkill.id}
                  title={softSkill.name}
                  highlighted={softSkill.id === focusedSoftSkillId}
                  chartValue={softSkill.answerValue}
                  onClick={() => onListItemClick(softSkill.id)}
                />
              ))}
            </div>
          ) : null}
        </div>
        <div className="skills-list-modal__actions">
          {hasMarkAllButton && (
            <Button
              className="skills-list-modal__actions-button"
              variant="secondary"
              onClick={onMarkAllAsNoKnowledgeClick}
              aria-label={t('assessment.markAllSkillsAsNoKnowledge')}
            >
              {t('common.markAll')}
              <SimpleBarChart
                value={1}
                className="skills-list-modal__mark-all-chart-icon"
              />
            </Button>
          )}
          {isSubmitVisible ? (
            <Button
              onClick={onSubmit}
              className="skills-list-modal__actions-button"
              disabled={isSubmitButtonDisabled}
              data-testid="submit-button"
              aria-label={t(`assessment.submitSkill`, {
                skillName,
              })}
            >
              {t('common.submit')}
            </Button>
          ) : (
            <Button
              onClick={onClose}
              className="skills-list-modal__actions-button"
            >
              {t('common.goBack')}
            </Button>
          )}
        </div>
      </Container>
    </Modal>
  );
};

export default SkillsListModal;
