import { gql } from '@apollo/client';

// TODO: Refactor, use generated query instead of this.
export const AUTHENITCATE_EXTERNAL_MUTATION = gql`
  mutation AuthenticateExternal(
    $token: String!
    $firstName: String!
    $lastName: String!
  ) {
    authenticateExternal(
      token: $token
      firstName: $firstName
      lastName: $lastName
      site: $site
    ) {
      accessToken
      refreshToken
    }
  }
`;
