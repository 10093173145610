import Icon from 'components/atoms/Icon';
import Loader from 'components/atoms/Loader/Loader';
import { appSwitchRoutes } from 'constants/routes';
import { FormType } from 'generated/graphql';
import useRespondantForm from 'hooks/useDeprecatedRespondantForm';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ReskillingFormContainer from '../ReskillingFormContainer';

interface IStageActionFormView {
  ownerId: number;
  stageCandidateId: number;
  formType: FormType;
  respondantId?: number;
}

const StageActionFormView: FC<IStageActionFormView> = ({
  ownerId,
  stageCandidateId,
  respondantId,
  formType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [
    getCompanyCandidateRequestQueryResponse,
    formFields,
    handleSaveCandidateInformation,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _handleSkipResponse,
    saveRespondantFormResponse,
  ] = useRespondantForm({
    onGetRespondantFormPreviouslyCompleted: () => {},
    onSaveRespondantFormCompleted: () => {
      navigate(appSwitchRoutes.stage.getUrl(stageCandidateId));
    },
    associatedId: stageCandidateId,
    formOwnerId: ownerId,
    formType,
    respondantId,
  });
  return (
    <div className="rs-form">
      {getCompanyCandidateRequestQueryResponse.loading && (
        <div className="rs-form__LoaderWrapper">
          <Loader />
        </div>
      )}
      {formFields && formFields.length > 0 && (
        <>
          <div className="rs-form__header">
            <Icon
              icon="questions"
              className="rs-form__header__icon"
              aria-hidden
            />
            <div className="rs-form__header__heading">
              {t('assessment.generalQuestions')}
            </div>
          </div>
          <ReskillingFormContainer
            fields={formFields || []}
            onSubmit={handleSaveCandidateInformation}
            saveRespondantFormResponse={saveRespondantFormResponse}
            formType={formType}
          />
        </>
      )}
    </div>
  );
};

export default StageActionFormView;
