import clsx from 'clsx';
import Icon from 'components/atoms/Icon';
import { stageSwitchRoutes } from 'constants/routes';
import {
  GetStageActionsDocument,
  TestStatus,
  TestSubType,
  useTestCandidateResetOneMutation,
} from 'generated/graphql';
import { toTitleCase } from 'helpers/titleCase';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './TRCard.module.scss';

interface ITRCard {
  testStatus?: TestStatus;
  testSubType?: TestSubType | null;
  timeRequirement?: number;
  testCandidateId: number;
  stageCandidateId: number;
  doneFor: number;
  doneBy: number;
  projectId: number;
}

const TRCard: FC<ITRCard> = ({
  testStatus,
  testSubType,
  testCandidateId,
  stageCandidateId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleMsgType } = useNotification();
  const [showReset, setShowReset] = useState<boolean>(false);

  const [testCandidateResetOneMutation] = useTestCandidateResetOneMutation({
    onCompleted: data => {
      if (data) {
        setShowReset(!showReset);
      }
    },
    refetchQueries: [
      {
        query: GetStageActionsDocument,
        variables: {
          stageCandidateId,
        },
      },
    ],
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
    },
  });

  const toggleReset = () => {
    setShowReset(!showReset);
  };

  const handleReset = () => {
    testCandidateResetOneMutation({
      variables: {
        id: testCandidateId,
      },
    });
  };

  const handleFormTest = () => {
    if (testStatus === TestStatus.NotStarted) {
      navigate(
        stageSwitchRoutes.test.getUrl(stageCandidateId, testCandidateId)
      );
    }
  };

  // TODO: Fix localization [EN-1930]
  const label = testSubType
    ? toTitleCase(testSubType?.replaceAll('_', ' ') as string)
    : 'Manager Review';

  const completed = testStatus === TestStatus.Completed;

  return (
    <Link
      to="#"
      className={clsx(
        styles['tr-card'],
        styles['tr-card--default'],
        completed && styles['tr-card--completed']
      )}
      onClick={handleFormTest}
      aria-label={label}
      tabIndex={0}
      aria-disabled={completed}
    >
      <div className={styles['tr-card__left']}>
        <Icon icon="performance" className={styles['tr-card__type-icon']} />
        <p className={styles['tr-card__label']}>{label}</p>
      </div>
      <div className={styles['tr-card__right']}>
        {testStatus === TestStatus.Completed ? (
          <>
            {!showReset ? (
              <div className={styles['tr-card__status-icon-container']}>
                <Icon
                  icon="green_check"
                  className={styles['tr-card__status-icon']}
                  aria-hidden
                  focusable="false"
                />
              </div>
            ) : (
              <div className={styles['tr-card__actions']}>
                <Button
                  onClick={toggleReset}
                  data-testid="cancel-button"
                  size="small"
                  variant="text"
                >
                  {t('common.cancel')}
                </Button>
                <Button onClick={handleReset} size="small" variant="text">
                  {t('common.reset')}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className={styles['tr-card__status-icon-container']}>
            <Icon
              icon="right_arrow"
              className={styles['tr-card__status-icon']}
            />
          </div>
        )}
      </div>
    </Link>
  );
};

export default TRCard;
