import { TBarChartValue } from 'components/atoms/SimpleBarChart/SimpleBarChart';
import Carousel, { ICarouselHandle } from 'components/molecules/Carousel';
import { ModalTypes } from 'enums/modalTypes';
import { closeModal, openModal } from 'graphql/modals';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, NotificationModalType } from '@spotted-zebra-uk/ui-components';
import { TSkillWithAnswer } from '../types';
import AssessmentNavigation from './AssessmentNavigation';
import SkillsLevelQuestionnaire from './SkillsLevelQuestionnaire';
import SkillsListModal from './SkillsListModal';

interface IAssessment {
  initialSkillsWithAnswers: TSkillWithAnswer[];
  gradeBands: {
    proficiency: TBarChartValue;
  }[];
  loading: boolean;
  hasMarkAllButton?: boolean;
  skillName: string;
  onSubmit: (skillsWithAnswers: TSkillWithAnswer[]) => void;
}

const Assessment: FC<IAssessment> = ({
  initialSkillsWithAnswers,
  gradeBands,
  loading,
  hasMarkAllButton,
  skillName,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [focusedSkillIndex, setFocusedSkillIndex] = useState(0);
  const [skillsWithAnswers, setSkillsWithAnswers] = useState<
    TSkillWithAnswer[]
  >(initialSkillsWithAnswers);
  const sliderRef = useRef<ICarouselHandle>(null);
  const [isSkillsListModalOpen, setIsSkillsListModalOpen] = useState(false);

  const handleChangeSlide = (newFocusedSkillIndex: number) => {
    setFocusedSkillIndex(newFocusedSkillIndex);
  };

  const selectAnswer = (selectedAnswerValue?: TBarChartValue) => {
    setSkillsWithAnswers(prevValues =>
      prevValues.map((softSkillWithAnswer, index) =>
        index === focusedSkillIndex
          ? {
              ...softSkillWithAnswer,
              answerValue: selectedAnswerValue,
            }
          : softSkillWithAnswer
      )
    );
    sliderRef.current?.slideNext?.();
  };

  const handleSkillsListItemClick = (skillWithAnswerId: number) => {
    const newFocusedSoftSkillWithAnswerIndex = skillsWithAnswers.findIndex(
      skill => skill.id === skillWithAnswerId
    );

    if (newFocusedSoftSkillWithAnswerIndex !== -1) {
      sliderRef.current?.slideTo?.(newFocusedSoftSkillWithAnswerIndex);
      setIsSkillsListModalOpen(false);
    }
  };

  const handleOpenSkillsListModal = () => {
    setIsSkillsListModalOpen(true);
  };

  const handleMarkAllAsNoKnowledge = () => {
    openModal({
      modalType: ModalTypes.NOTIFICATION_MODAL,
      modalProps: {
        type: NotificationModalType.WARNING,
        title: t('common.areYouSure'),
        message: t('dialogue.markAllAsNoKnowledge'),
        isOpen: true,
        onOkClick: () => {
          const newSkillsWithAnswers = skillsWithAnswers.map(
            skillWithAnswer => ({
              ...skillWithAnswer,
              answerValue: 1 as TBarChartValue,
            })
          );

          setSkillsWithAnswers(newSkillsWithAnswers);
          closeModal(ModalTypes.NOTIFICATION_MODAL);
        },
        onClose: () => {
          closeModal(ModalTypes.NOTIFICATION_MODAL);
        },
      },
    });
  };

  const handleSubmit = () => {
    onSubmit(skillsWithAnswers);
  };

  const focusedSkill = skillsWithAnswers[focusedSkillIndex];
  const allSkillsAnswered = !skillsWithAnswers.some(
    skill => !skill.answerValue
  );
  const focusedLastSkill = focusedSkillIndex + 1 === skillsWithAnswers.length;
  return (
    <div className="assessment">
      <AssessmentNavigation
        loading={loading}
        focusedSkillOrderNumber={focusedSkillIndex + 1}
        numberOfSkillsInAssessment={skillsWithAnswers.length}
        onSeeAllSkillsButtonClick={handleOpenSkillsListModal}
      />
      {!loading ? (
        <Carousel
          className="assessment__slider"
          infinite={false}
          ref={sliderRef}
          afterChange={handleChangeSlide}
        >
          {skillsWithAnswers.map(({ id, name, answerValue, description }) => {
            return (
              <SkillsLevelQuestionnaire
                key={id}
                id={id}
                title={name}
                gradeBands={gradeBands}
                description={description}
                selectedAnswerValue={answerValue}
                summaryButtonVisible={focusedLastSkill}
                summaryButtonDisabled={!allSkillsAnswered}
                onSelectAnswer={selectAnswer}
                onSummaryButtonClick={handleOpenSkillsListModal}
              />
            );
          })}
        </Carousel>
      ) : (
        <div className="assessment__slider-loader-wrapper">
          <Loader variant="bubbles" />
        </div>
      )}
      <SkillsListModal
        onClose={() => setIsSkillsListModalOpen(false)}
        isOpen={isSkillsListModalOpen}
        softSkillsWithAnswers={skillsWithAnswers}
        focusedSoftSkillId={focusedSkill?.id}
        onListItemClick={handleSkillsListItemClick}
        isSubmitVisible={allSkillsAnswered}
        skillName={skillName}
        onSubmit={handleSubmit}
        isSubmitButtonDisabled={loading}
        hasMarkAllButton={hasMarkAllButton && !allSkillsAnswered}
        onMarkAllAsNoKnowledgeClick={handleMarkAllAsNoKnowledge}
      />
    </div>
  );
};

export default Assessment;
